import { Component } from "react";
import { Modal } from "react-bootstrap";

const Icon = () => (
  <svg
    width="55"
    height="48"
    viewBox="0 0 55 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <rect
        x="6.5"
        y="2.5"
        width="35"
        height="35"
        rx="7.5"
        stroke="#0096AC"
        stroke-width="5"
      />
    </g>
    <g filter="url(#filter1_d)">
      <rect
        x="9"
        y="15.4194"
        width="6.24996"
        height="23.4486"
        rx="3.12498"
        transform="rotate(-45 9 15.4194)"
        fill="#132B44"
      />
    </g>
    <g filter="url(#filter2_d)">
      <rect
        x="47.8242"
        y="1"
        width="6.24996"
        height="37.2289"
        rx="3.12498"
        transform="rotate(45 47.8242 1)"
        fill="#132B44"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="48"
        height="48"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d"
        x="6.29395"
        y="12.2944"
        width="26.4112"
        height="26.4112"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d"
        x="18.7939"
        y="2.29443"
        width="36.1554"
        height="36.1554"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

class SuccessModalDialog extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal {...this.props} centered>
        <Modal.Body className="text-center" style={{ padding: "36px 0" }}>
          <Icon />
          <div
            style={{
              fontSize: "32px",
              fontWeight: 900,
              fontFamily: "Lato",
              marginTop: "10px",
            }}
          >
            Validation de la réservation
          </div>
          <div
            style={{
              fontSize: "14px",
              fontFamily: "Lato",
            }}
          >
            Retrouver votre réservation dans vos transports en cours.
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default SuccessModalDialog;
