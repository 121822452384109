import { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import _ from "lodash";

import logo from "assets/img/resaam-logo.svg";

class LogoutConfirmationDialog extends Component {
  constructor(props) {
    super(props);

    this.onConfirmAction = this.onConfirmAction.bind(this);
    this.onCancelAction = this.onCancelAction.bind(this);
  }

  onCancelAction() {
    const { onCancel } = this.props;
    if (_.isFunction(onCancel)) onCancel();
  }

  onConfirmAction() {
    const { onConfirm } = this.props;
    if (_.isFunction(onConfirm)) onConfirm();
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <Modal {...this.props} centered>
        <Modal.Body className="text-center">
          <img
            src={logo}
            alt="resaam-logo"
            style={{
              width: "177px",
            }}
          />
          <div
            style={{
              fontSize: "36px",
              fontFamily: "Baloo",
              fontWeight: 400,
              lineHeight: 1,
            }}
          >
            {t("Common:Dialog.LogoutConfirmation.header_label", {
              lng: i18n.language,
            })}
          </div>
          <div
            style={{ fontSize: "14px", fontFamily: "Lato", marginTop: "15px" }}
          >
            {t("Common:Dialog.LogoutConfirmation.confirmation_message", {
              lng: i18n.language,
            })}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-around mb-4">
          <Button variant="secondary" onClick={this.onCancelAction}>
            {t("Common:Dialog.LogoutConfirmation.cancel_button", {
              lng: i18n.language,
            })}
          </Button>
          <Button variant="primary" onClick={this.onConfirmAction}>
            {t("Common:Dialog.LogoutConfirmation.confirm_button", {
              lng: i18n.language,
            })}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default LogoutConfirmationDialog;
