// Use fetch to upload the file to S3.
function uploadFile({ url, file }) {
  fetch(url, {
    method: "PUT",
    headers: { "Content-Type": file.type },
    body: file,
  })
    .then((res) => {
      // console.log(res)
    })
    .catch((e) => {
      // console.log(e)
    });
}

export default uploadFile;
