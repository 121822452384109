import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Toast,
} from "react-bootstrap";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
// import { List, AutoSizer, InfiniteLoader } from "react-virtualized";
// import RideHistoryRow from "components/Ride-History-Item";
// import Calendar from "react-multi-date-picker";
// import InputIcon from "react-multi-date-picker/components/input_icon";
// import datepickerLocal from "globals/local-dates";
// import searchInputIcon from "assets/img/search-input-icon.svg";
// import noDataIcon from "assets/img/no_data.svg";
import SettingsActions from "reducers/settings";
import AccountActions from "reducers/account";
import ServiceActions from "reducers/company/get-service";

import {
  authUrlProSanteConnect,
  getParamsOpenIdFromUrlAndLocalStorage,
} from "functions";
import pscImage from "assets/img/openid/psc.png";
import { OPEN_ID_PLATFORMS, USER_ROLE } from "globals/constants";
import _ from "lodash";

const buttonStyle = {
  border: "1px solid #0296AE",
  color: "#0296AE",
  backgroundColor: "white",
  fontWeight: 600,
  borderRadius: "5px",
};
const buttonStyleDisabled = {
  backgroundColor: "#999C9F",
  color: "white",
  border: "1px solid #999C9F",
};

const subfieldStyle = { fontWeight: 600 };
const contactStyle = {
  fontWeight: 600,
  color: "#666",
  fontSize: "1.2rem",
  textDecoration: "none",
};

class Settings extends Component {
  constructor() {
    super();
    this.state = {
      error: "",
      onSignoutOpenId: false,
      showToast: false,
      showInputAddSecretary: false,
      secretaryEmail: "",
      showDeleteSecretaryModal: false,
    };

    this.onClickOpenId = this.onClickOpenId.bind(this);
    this.onHideModal = this.onHideModal.bind(this);
    this.onShowInputAddSecretary = this.onShowInputAddSecretary.bind(this);
    this.onSubmitAddSecretary = this.onSubmitAddSecretary.bind(this);
  }

  componentDidMount() {
    const { location, signinOpenIdRequest } = this.props;

    const params = getParamsOpenIdFromUrlAndLocalStorage(location?.search);
    if (params) {
      const { code, nonce } = params;
      signinOpenIdRequest(OPEN_ID_PLATFORMS.PSC, code, nonce, (msg) => {
        this.setState({ error: msg, showToast: true });
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  onClickOpenId = (plateform, active = true) => {
    if (!active) this.setState({ onSignoutOpenId: plateform });
    else {
      if (plateform === OPEN_ID_PLATFORMS.PSC)
        authUrlProSanteConnect("/private/settings");
    }
  };

  onHideModal = () => {
    this.setState({ onSignoutOpenId: false });
  };

  onShowInputAddSecretary = () => {
    this.setState({ showInputAddSecretary: true });
  };

  onSubmitAddSecretary = () => {
    const { secretaryEmail } = this.state;
    if (secretaryEmail) {
      console.log("add secretary", secretaryEmail);
      this.props.doctorAddSecretaryRequest(secretaryEmail, (err = false) => {
        console.log("err", err);
        if (!err)
          this.setState({ showInputAddSecretary: false, secretaryEmail: "" });
      });
    }
  };

  onShowDeleteSecretaryModal = (id) => {
    this.setState({ showDeleteSecretaryModal: id });
  };

  render() {
    const { t, i18n, settings, changePickupRequest, service, profile } =
      this.props;
    const validButtonIsDisabled = new RegExp(
      "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"
    ).test(this.state.secretaryEmail)
      ? false
      : true;

    return (
      <Container fluid className="h-100 d-flex flex-column">
        <Toast
          className="bg-danger"
          style={{ position: "fixed", top: 55, right: 25, zIndex: 9999 }}
          show={this.state.showToast}
          delay={8000}
          autohide
          onClose={() => this.setState({ showToast: false })}
        >
          <Toast.Header>
            <strong className="me-auto">Pro santé connect</strong>
          </Toast.Header>
          <Toast.Body className="text-white">
            {t(`SettingsPage.errors.${this.state.error}`, {
              ng: i18n.language,
            })}
          </Toast.Body>
        </Toast>

        <Row className="mt-4 px-2">
          <h1>{t(`SettingsPage.title`, { ng: i18n.language })}</h1>
        </Row>

        <Row className="mt-4 px-2 w-auto">
          {/* Profile utilisateur, firstname, lastname */}
          <h5 className="mb-2">Profile Utilisateur</h5>
          <div className="d-flex gap-3">
            <div style={subfieldStyle}>Nom :</div>
            <div className="text-capitalize">{profile?.lastname}</div>
          </div>
          <div className="d-flex gap-3">
            <div style={subfieldStyle}>Prenom :</div>
            <div className="text-capitalize">{profile?.firstname}</div>
          </div>
        </Row>
        {profile.userType === USER_ROLE.SERVICE && (
          <>
            <Row className="mt-4 px-2">
              {/* Service info, name, address, contact => firstname, lastname, email */}
              <h5 className="mb-2">Service</h5>
              <div className="d-flex gap-3">
                <div style={subfieldStyle}>Nom :</div>
                <div className="text-uppercase">{service?.service?.name}</div>
              </div>
              <div className="d-flex gap-3">
                <div style={subfieldStyle}>Adresse :</div>
                <div>{service?.service?.address?.address}</div>
              </div>
              <div className="mt-2" style={contactStyle}>
                Contact
              </div>
              <div className="d-flex gap-3">
                <div style={subfieldStyle}>Nom :</div>
                <div className="text-capitalize">
                  {service?.service?.contact?.lastname}
                </div>
              </div>
              <div className="d-flex gap-3">
                <div style={subfieldStyle}>Prenom :</div>
                <div className="text-capitalize">
                  {service?.service?.contact?.firstname}
                </div>
              </div>
              <div className="d-flex gap-3">
                <div style={subfieldStyle}>Email :</div>
                <a href={`mailto:${service?.service?.contact?.email}`}>
                  {service?.service?.contact?.email}
                </a>
              </div>
            </Row>
            <Row className="mt-4 px-2">
              {/* Company info, name, contact mail, address */}
              <h5 className="mb-2">Etablissement</h5>
              <div className="d-flex gap-3">
                <div style={subfieldStyle}>Nom :</div>
                <div className="text-uppercase">{profile?.company?.name}</div>
              </div>

              <div className="d-flex gap-3">
                <div style={subfieldStyle}>Adresse :</div>
                <div>{profile?.company?.address?.address}</div>
              </div>
              <div className="mt-2" style={contactStyle}>
                Contact
              </div>
              <div className="d-flex gap-3">
                <div style={subfieldStyle}>Email :</div>
                <a href={`mailto:${profile?.company?.email}`}>
                  {profile?.company?.email}
                </a>
              </div>
            </Row>
          </>
        )}

        {profile.userType === USER_ROLE.MEDECIN && (
          <Row className="mt-4 px-2 w-auto">
            <h5 className="mb-2">Comptes associés</h5>
            <Row className="gap-2 mb-2">
              <Col>
                <Button
                  style={
                    this.state.showInputAddSecretary
                      ? buttonStyleDisabled
                      : buttonStyle
                  }
                  disabled={this.state.showInputAddSecretary}
                  onClick={this.onShowInputAddSecretary}
                >
                  AJOUTER UN COLLABORATEUR
                </Button>
              </Col>
              <Col>
                {this.state.showInputAddSecretary && (
                  <Form.Control
                    type="email"
                    placeholder="Mail du collaborateur"
                    value={this.state.secretaryEmail}
                    onChange={(e) =>
                      this.setState({ secretaryEmail: e.target.value })
                    }
                  />
                )}
              </Col>
              <Col>
                {this.state.showInputAddSecretary && (
                  <Button
                    style={
                      validButtonIsDisabled ? buttonStyleDisabled : buttonStyle
                    }
                    disabled={validButtonIsDisabled}
                    onClick={this.onSubmitAddSecretary}
                  >
                    VALIDER
                  </Button>
                )}
              </Col>
            </Row>

            {service.service?.secretaries &&
              service.service.secretaries.map((secretary, index) => (
                <div className="mt-2" key={index}>
                  <strong>
                    {_.capitalize(secretary.lastname)}{" "}
                    {_.capitalize(secretary.firstname)}
                  </strong>
                  <div className="d-flex gap-2">
                    <div>email:</div>
                    <div className="flex-grow-1">{secretary.email}</div>
                    <Button
                      variant="danger"
                      onClick={() => this.onShowDeleteSecretaryModal(secretary)}
                    >
                      Supprimer
                    </Button>
                  </div>
                </div>
              ))}
          </Row>
        )}

        {profile.userType === USER_ROLE.SECRETARY && (
          <Row className="mt-4 px-2 w-auto">
            <h5 className="mb-2">Comptes associés</h5>

            {service.service?.doctors &&
              service.service.doctors.map((doctor, index) => (
                <div className="mt-2" key={index}>
                  <div>
                    {_.capitalize(doctor.lastname)}{" "}
                    {_.capitalize(doctor.firstname)}
                  </div>
                  {/* <Row>
                  <Col>email:</Col>
                  <Col className="f-grow-1">{doctor?.email}</Col>
                  <Col>Supprimer</Col>
                </Row> */}
                </div>
              ))}
          </Row>
        )}

        <div className="mt-4">
          <div style={contactStyle}>Applications</div>
          <div>Pour se connecter en 1 clic</div>
          <div className="d-flex">
            <img
              className={`psc-image-setting ${
                profile.openId?.psc ? "" : "psc-image-setting-disabled"
              }`}
              src={pscImage}
              alt="pro sante connect"
              onClick={() => this.onClickOpenId("psc", !profile.openId?.psc)}
            />
          </div>
        </div>

        {profile.userType === USER_ROLE.SERVICE && (
          <div className="mt-4">
            <div style={contactStyle}>Création d'une réservation</div>
            <Form.Group className="d-flex gap-2">
              <input
                type="checkbox"
                data-toggle="toggle"
                data-size="sm"
                id="pickupFromService"
                checked={settings.pickupFromService}
                onChange={changePickupRequest}
              />

              <label className="text-truncate" htmlFor="pickupFromService">
                Utiliser l'adresse du service comme adresse par défaut pour les
                réservations à venir.{" "}
              </label>
            </Form.Group>
          </div>
        )}

        {profile.userType === USER_ROLE.SERVICE && (
          <div className="mt-4">
            <div style={contactStyle}>Écran d'urgence</div>
            <div>
              Si vous êtes un établissement de santé, sachez que vous avez accès
              à notre{" "}
              <NavLink to={"/private/ongoing-bilans"} target="_blank">
                écran des urgences{" "}
              </NavLink>{" "}
              dédié.
            </div>
          </div>
        )}

        <Modal show={this.state.onSignoutOpenId} onHide={this.onHideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Déconnexion de Pro Santé Connect</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Vous êtes sur le point de vous déconnecter de Pro Santé Connect.
            </p>
            <p>
              Êtes-vous sûr de vouloir vous déconnecter de Pro Santé Connect ?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.onHideModal}>
              Annuler
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.props.signoutOpenIdRequest(
                  this.state.onSignoutOpenId,
                  (err) => {
                    console.log(err);
                    this.setState({ error: err, showToast: true });
                  }
                );
                this.onHideModal();
              }}
            >
              Se déconnecter
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showDeleteSecretaryModal}
          onHide={() => this.setState({ showDeleteSecretaryModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Supprimer un collaborateur</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Vous êtes sur le point de supprimer un collaborateur.</p>
            <p>
              Êtes-vous sûr de vouloir supprimer le collaborateur{" "}
              {this.state.showDeleteSecretaryModal?.firstname}{" "}
              {this.state.showDeleteSecretaryModal?.lastname} ?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ showDeleteSecretaryModal: false })}
            >
              Annuler
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.props.doctorDeleteSecretaryRequest(
                  this.state.showDeleteSecretaryModal?.id,
                  (err) => {
                    console.log(err);
                    this.setState({
                      /* error: err, showToast: true, */ showDeleteSecretaryModal: false,
                    });
                  }
                );
              }}
            >
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.asMutable({ deep: true }),
    service: state.service.asMutable({ deep: true }),
    profile: state.account.user.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getProfileAndSettingsRequest: (...args) =>
    //   dispatch(SettingsActions.getProfileAndSettingsRequest(...args)),
    doctorAddSecretaryRequest: (...args) =>
      dispatch(ServiceActions.doctorAddSecretaryRequest(...args)),
    doctorDeleteSecretaryRequest: (...args) =>
      dispatch(ServiceActions.doctorDeleteSecretaryRequest(...args)),
    changePickupRequest: (...args) =>
      dispatch(SettingsActions.changePickupRequest(...args)),
    signinOpenIdRequest: (...args) =>
      dispatch(AccountActions.signinOpenIdRequest(...args)),
    signoutOpenIdRequest: (...args) =>
      dispatch(AccountActions.signoutOpenIdRequest(...args)),
  };
};

const settingRedux = connect(mapStateToProps, mapDispatchToProps)(Settings);
export default withTranslation(["Private", "Common"], { wait: true })(
  settingRedux
);
