import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  putPresignedUrl: ["path", "callback"],
  getPresignedUrl: ["path", "callback"],
});

export const MinioTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({});

/* ------------- Reducers ------------- */

export const getPresignedUrl = (state, action) => state;

export const putPresignedUrl = (state, action) => state;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PRESIGNED_URL]: getPresignedUrl,

  [Types.PUT_PRESIGNED_URL]: putPresignedUrl,
});
