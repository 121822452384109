import { saveAs } from "file-saver";
import { LOCAL_STORAGE_KEYS } from "globals/constants";

export const getQueryVariable = (variable, query = false) => {
  if (query == false) {
    query = window.location.search.substring(1);
  }
  var vars = query.split("&");
  // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};

export function getBackendUrl() {
  let BACK_END = "";

  if (process.env.REACT_APP_STAGE === "dev")
    BACK_END = process.env.REACT_APP_BACK_END_URL_DEV;
  else if (process.env.REACT_APP_STAGE === "prod")
    BACK_END = process.env.REACT_APP_BACK_END_URL_PROD;
  else if (process.env.REACT_APP_STAGE === "demo")
    BACK_END = process.env.REACT_APP_BACK_END_URL_DEMO;
  else BACK_END = process.env.REACT_APP_BACK_END_URL;
  return BACK_END;
}

export function downloadFilesAsBlob(urls, paths, patient) {
  for (const urls3 of urls) {
    const file = paths.find((i) => decodeURI(urls3).includes(i.name));
    fetch(urls3, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        saveAs(blob, patient.lastname + "_" + file.name);
      })
      .catch((err) => {
        console.error("err: ", err);
      });
  }
}

// format size file octet
export function formatSizeFile(size = 0) {
  if (size < 1024) {
    return size + " octets";
  } else if (size < 1024 * 1024) {
    return Math.round(size / 1024) + " Ko";
  } else if (size < 1024 * 1024 * 1024) {
    return Math.round(size / (1024 * 1024)) + " Mo";
  } else {
    return Math.round(size / (1024 * 1024 * 1024)) + " Go";
  }
}

export function generateRandomStringToPreventCrossSiteScripting(max) {
  const randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < max; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
}

export function authUrlProSanteConnect(redirectPath = "/pro-sante-connect") {
  const clientId =
    process.env.REACT_APP_STAGE === "prod"
      ? process.env.REACT_APP_PRO_SANTE_CONNECT_CLIENT_ID
      : process.env.REACT_APP_PRO_SANTE_CONNECT_CLIENT_ID_DEMO;
  const redirectUri = window.location.origin + redirectPath;
  const scope = "openid scope_all";
  const state = generateRandomStringToPreventCrossSiteScripting(10);
  const nonce = generateRandomStringToPreventCrossSiteScripting(10);
  const prePath =
    process.env.REACT_APP_STAGE === "prod"
      ? process.env.REACT_APP_PRO_SANTE_CONNECT_AUTH_URL
      : process.env.REACT_APP_PRO_SANTE_CONNECT_AUTH_URL_DEMO;
  const authUrl = `${prePath}?client_id=${clientId}&response_type=code&scope=${scope}&state=${state}&nonce=${nonce}&redirect_uri=${redirectUri}`;

  // save in local storage
  localStorage.setItem(LOCAL_STORAGE_KEYS.PSC_STATE, state);
  localStorage.setItem(LOCAL_STORAGE_KEYS.PSC_NONCE, nonce);

  // redirect to auth url
  window.location.href = authUrl;
}

export function getParamsOpenIdFromUrlAndLocalStorage(search) {
  if (!search) return null;
  const params = new URLSearchParams(search);
  const code = params.get("code");
  const state = params.get("state");
  const localState = localStorage.getItem(LOCAL_STORAGE_KEYS.PSC_STATE);
  const localNonce = localStorage.getItem(LOCAL_STORAGE_KEYS.PSC_NONCE);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.PSC_STATE);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.PSC_NONCE);

  if (code && state && state === localState)
    return {
      code,
      nonce: localNonce,
    };
  return null;
}
