const gregorian_fr_lowercase = {
  name: "gregorian_fr_lowercase",
  months: [
    ["janvier", "jan"],
    ["février", "feb"],
    ["mars", "mar"],
    ["avril", "apr"],
    ["mai", "may"],
    ["juin", "jun"],
    ["juillet", "jul"],
    ["août", "aoû"],
    ["septembre", "sep"],
    ["octobre", "oct"],
    ["novembre", "nov"],
    ["décembre", "déc"],
  ],
  weekDays: [
    ["samedi", "sam"],
    ["dimanche", "dim"],
    ["lundi", "lun"],
    ["mardi", "mar"],
    ["mercredi", "mer"],
    ["jeudi", "jeu"],
    ["vendredi", "ven"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"],
  ],
};

export default gregorian_fr_lowercase;
