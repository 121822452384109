import { put, takeLatest } from "redux-saga/effects";
import patientListActions, { patientTypes } from "reducers/patient/patient";
import errorHandler from "sagas/error-handler";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* patientListAsyncRequest({ page, limit, filtered }) {
  let _filter = _.reduce(
    filtered,
    (acc, { id, value }) => {
      return [...acc, `{id : "${id}", value : "${value}"}`];
    },
    []
  );

  const query = `
      {
        ServiceGetPatients(page:${page},limit:${limit},filtered:[${_filter.join(
    ","
  )}]) {
    
            page limit totalPages totalDocs
          patients{ 
            firstname  lastname phone email  nss
            
          }
          
          }

         }          
      
  `;

  try {
    const data = yield graphQLClient().request(query);
    const list = data.ServiceGetPatients;
    yield put(patientListActions.patientGetListRequestSuccess(list));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      patientListActions.patientGetListRequestFailure
    );
  }
}

function* deletePatientAsyncRequest({ patient }) {
  const { firstname, lastname, email, nss } = patient;

  const query = `
  mutation { 

    ServiceDeletePatient( 
    
    firstname:"${firstname}",
    lastname:"${lastname}",
    email:"${email}",
    nss:"${nss}"  
    
    )
    }
  `;

  try {
    yield graphQLClient().request(query);
    yield put(patientListActions.deletePatientRequestSuccess());
    yield put(patientListActions.patientGetListRequest(1, 10));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, patientListActions.deletePatientRequestFailure);
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(patientTypes.PATIENT_GET_LIST_REQUEST, patientListAsyncRequest),
  takeLatest(patientTypes.DELETE_PATIENT_REQUEST, deletePatientAsyncRequest),
];
