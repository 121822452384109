import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import OngoingBilanRow from "components/Cards/BilanCard";
import { connect } from "react-redux";
import OngoingBilansActions from "reducers/bilan";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import noDataIcon from "assets/img/no_data.svg";
import ramionIcon from "assets/img/ramion.svg";
import moment from "moment";
import BilanUpdatedNotification from "layouts/private/BilanUpdatedNotification";

const FETCH_RIDES_TIME = 10_000; // 10s

function getRidesId(bilans) {
  let ridesId = [];
  for (const bilan of bilans) {
    ridesId.push(bilan.ride.id);
  }
  return ridesId;
}

class OngoingBilan extends Component {
  constructor() {
    super();
    this.loadMore = this.loadMore.bind(this);

    this.state = {
      width: 0,
      startIndexListItemRendred: 0,
      stopIndexListItemRendred: 0,
      showDriverLocation: false,
      selectedBilanToShowDriverLocation: 0,
      timer: new Date(),
    };
  }

  loadMore() {
    const { onGoingBilansRequest } = this.props;
    const { page, limit, totalDocs, bilans } = this.props.bilans;
    if (bilans.length < totalDocs) onGoingBilansRequest(page + 1, limit);
  }

  isRowLoaded = ({ index }) => {
    const { bilans } = this.props.bilans;
    return !!bilans[index];
  };

  rowRenderer = ({ index, key, style }) => {
    const { t, i18n } = this.props;

    const { top, position, height, left } = style;
    const { bilans } = this.props.bilans;
    const bilan = bilans[index];
    // if (!this.isRowLoaded({ index })) {
    //   return null;
    //   /*(<div key={key}
    //             style={{
    //                 top: top,
    //                 position, left,
    //                 height: height, width:
    //                     this.state.width - 25
    //             }} className="d-flex">
    //             chargement ....
    //         </div>); */
    // }
    // if(bilans[index]?.ride.id == null) return null;

    return (
      <div key={key} style={{ marginBottom: "8px" }} className="d-flex">
        <OngoingBilanRow bilan={bilans[index]} {...{ t, i18n }} />
      </div>
    );
  };

  onRowsRendered(params) {
    const { startIndex, stopIndex } = params;
    const { startIndexListItemRendred, stopIndexListItemRendred } = this.state;
    if (
      startIndex != startIndexListItemRendred ||
      stopIndex != stopIndexListItemRendred
    ) {
      this.setState(
        Object.assign({}, this.state, {
          startIndexListItemRendred: startIndex,
          stopIndexListItemRendred: stopIndex,
        }),
        () => {}
      );
    }
  }

  componentDidMount() {
    const { onGoingBilansRequest } = this.props;

    onGoingBilansRequest(1, 100);

    this.bilanIntervalId = setInterval(() => {
      onGoingBilansRequest(1, 100);
    }, [FETCH_RIDES_TIME]);

    this.timer = setInterval(() => {
      this.setState({ timer: new Date() });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.bilanIntervalId);
    clearInterval(this.timer);
  }

  render() {
    const { t, i18n } = this.props;
    const { limit, totalDocs = 0, bilans } = this.props.bilans;

    return (
      <Container fluid className="h-100 d-flex flex-column pt-1">
        <BilanUpdatedNotification
          onGoingBilanUpdated={this.props.onGoingBilanUpdated}
        />

        <Row className="mt-0">
          <Col>
            <h1 style={{ color: "#032A47", fontSize: "87px" }}>
              {/* {t("onGoingBilansPage.on_going_bilans_headline_title", {
                lng: i18n.language,
              })} */}
              RESAAM <img src={ramionIcon} alt="" />
            </h1>
          </Col>
          <Col>
            <p
              style={{
                fontSize: "60px",
                color: "black",
                fontFamily: "Lato",
                fontWeight: 900,
                textAlign: "end",
              }}
            >
              {moment(this.state.timer).format("DD/MM/YYYY à HH:mm:ss")}
            </p>
          </Col>
        </Row>

        <Row className="flex-grow-1 mt-3">
          <Col>
            {totalDocs == 0 && bilans.length == 0 && (
              <div className="no-data">
                <img src={noDataIcon} />
                <p className="text-no-data">
                  {/* {t("onGoingBilansPage.message_when_no_data", {
                    lng: i18n.language,
                  })} */}
                  Vous n'avez pas d'urgence en cours
                </p>
              </div>
            )}
            {bilans.map((bilan, key) => {
              return (
                <div key={key}>
                  <OngoingBilanRow bilan={bilan} {...{ t, i18n }} />
                </div>
              );
            })}
            {/* <InfiniteLoader
              isRowLoaded={({ index }) => !!bilans[index]}
              loadMoreRows={this.loadMore}
              rowCount={totalDocs}
              threshold={limit / 2}
            >
              {({ onRowsRendered, registerChild }) => (
                <AutoSizer>
                  {({ width, height }) => {
                    if (width != this.state.width)
                      this.setState(Object.assign({}, this.state, { width }));
                    return (
                      <List
                        height={totalDocs == 0 ? 0 : height}
                        rowHeight={200}
                        width={this.state.width}
                        ref={registerChild}
                        rowCount={totalDocs}
                        rowRenderer={this.rowRenderer}
                        onRowsRendered={(param) => {
                          onRowsRendered(param);
                          this.onRowsRendered(param);
                        }}
                        data={bilans}
                      />
                    );
                  }}
                </AutoSizer>
              )}
            </InfiniteLoader> */}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bilans: state.ongoingBilans.bilans.asMutable({ deep: true }),
    action: state.ongoingBilans.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGoingBilansRequest: (...args) =>
      dispatch(OngoingBilansActions.onGoingBilansRequest(...args)),
    onGoingBilanUpdated: (...args) =>
      dispatch(OngoingBilansActions.onGoingBilanStatusChanged(...args)),
  };
};

const onGoingBilansRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(OngoingBilan);
export default withTranslation(["Private", "Common"], { wait: true })(
  onGoingBilansRedux
);
