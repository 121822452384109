import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  setPatientStatusRequest: ["rideId", "status", "callback"],
  setPatientStatusRequestSuccess: ["ride"],
  setPatientStatusRequestFailure: ["error"],

  onGoingRidesResetState: null,
  onGoingRidesRequest: ["page", "limit", "filter"],
  onGoingRidesRequestSuccess: ["rides"],
  onGoingRidesRequestFailure: ["error"],
  onGoingRideStatusChanged: ["ride"],
  onGoingSerieStatusChanged: ["serie"],

  cancelRideRequest: ["rideId", "callback"],
  cancelRideRequestSuccess: ["ride"],
  cancelRideRequestFailure: ["error"],

  onRefreshDispatchRideRequest: ["rideId", "callback"],
  onRefreshDispatchRideSuccess: ["ride"],
  onRefreshDispatchRideFailure: ["error"],

  onAddFilesToRideOngoingRequest: ["rideId", "files", "callback"],
  onAddFilesToRideOngoingSuccess: ["ride"],
  onAddFilesToRideOngoingFailure: ["error"],

  onDropFilesToRideOngoingRequest: ["rideId", "files", "callback"],
  onDropFilesToRideOngoingSuccess: ["ride"],
  onDropFilesToRideOngoingFailure: ["error"],
});

export const OngoingRideTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  rides: {
    page: 1,
    limit: 10,
    totalPages: 0,
    rides: [],
  },
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

/// GENERAL
export const onGoingRidesResetState = (state, action) => {
  return INITIAL_STATE;
};

export const onGoingRideStatusChanged = (state, action) => {
  const { ride } = action;
  let rides = state.rides.rides.asMutable({ deep: true });

  let _rides = _.map(rides, (r) => {
    if (ride._id === r._id) {
      return { ...r, ...ride };
    }
    return r;
  });

  return Immutable.setIn(state, ["rides", "rides"], _rides);
};

export const onGoingRidesRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const onGoingRidesRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const onGoingRidesRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  if (action.rides.page == 1) {
    //first page
    //avoid rerender the page
    const prevRidesState = state.rides.asMutable();
    const nextRidesState = action.rides;
    if (_.isEqual(nextRidesState, prevRidesState)) return state;
    return Immutable.setIn(nextSate, ["rides"], action.rides);
  } else {
    //apend next pages
    let prevList = state.rides.rides.asMutable({ deep: true });

    const { rides, ...rest } = action.rides;

    return Immutable.setIn(nextSate, ["rides"], {
      ...rest,
      rides: [...prevList, ...rides],
    });
  }
};

export const cancelRideRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const cancelRideRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const cancelRideRequestSuccess = (state, action) => {
  const { ride } = action;
  let rides = state.rides.rides.asMutable({ deep: true });
  const _rides = rides.filter((r) => r._id !== ride._id);
  return Immutable.setIn(state, ["rides", "rides"], _rides);
};

export const setPatientStatusRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const setPatientStatusRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  const { ride } = action;
  let rides = state.rides.rides.asMutable({ deep: true });
  let _rides = _.map(rides, (r) => {
    if (ride._id === r._id) {
      r.patient.status = ride.patient.status;
      return r;
    } else {
      return r;
    }
  });

  return Immutable.setIn(nextSate, ["rides", "rides"], _rides);
};

export const setPatientStatusRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const onRefreshDispatchRideRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const onRefreshDispatchRideFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const onRefreshDispatchRideSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  const { ride } = action;
  let rides = state.rides.rides.asMutable({ deep: true });
  let _rides = _.map(rides, (r) => {
    if (ride._id === r._id) return ride;
    else return r;
  });

  return Immutable.setIn(nextSate, ["rides", "rides"], _rides);
};

export const onAddFilesToRideOngoingRequest = onGoingRidesRequest;
export const onAddFilesToRideOngoingSuccess = onGoingRideStatusChanged;
export const onAddFilesToRideOngoingFailure = onGoingRidesRequestFailure;

export const onDropFilesToRideOngoingRequest = onGoingRidesRequest;
export const onDropFilesToRideOngoingSuccess = onGoingRideStatusChanged;
export const onDropFilesToRideOngoingFailure = onGoingRidesRequestFailure;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_PATIENT_STATUS_REQUEST]: setPatientStatusRequest,
  [Types.SET_PATIENT_STATUS_REQUEST_SUCCESS]: setPatientStatusRequestSuccess,
  [Types.SET_PATIENT_STATUS_REQUEST_FAILURE]: setPatientStatusRequestFailure,

  [Types.ON_GOING_RIDES_RESET_STATE]: onGoingRidesResetState,
  [Types.ON_GOING_RIDES_REQUEST]: onGoingRidesRequest,
  [Types.ON_GOING_RIDES_REQUEST_SUCCESS]: onGoingRidesRequestSuccess,
  [Types.ON_GOING_RIDES_REQUEST_FAILURE]: onGoingRidesRequestFailure,
  [Types.ON_GOING_RIDE_STATUS_CHANGED]: onGoingRideStatusChanged,

  [Types.CANCEL_RIDE_REQUEST]: cancelRideRequest,
  [Types.CANCEL_RIDE_REQUEST_SUCCESS]: cancelRideRequestSuccess,
  [Types.CANCEL_RIDE_REQUEST_FAILURE]: cancelRideRequestFailure,

  [Types.ON_REFRESH_DISPATCH_RIDE_REQUEST]: onRefreshDispatchRideRequest,
  [Types.ON_REFRESH_DISPATCH_RIDE_SUCCESS]: onRefreshDispatchRideSuccess,
  [Types.ON_REFRESH_DISPATCH_RIDE_FAILURE]: onRefreshDispatchRideFailure,

  [Types.ON_ADD_FILES_TO_RIDE_ONGOING_REQUEST]: onAddFilesToRideOngoingRequest,
  [Types.ON_ADD_FILES_TO_RIDE_ONGOING_SUCCESS]: onAddFilesToRideOngoingSuccess,
  [Types.ON_ADD_FILES_TO_RIDE_ONGOING_FAILURE]: onAddFilesToRideOngoingFailure,

  [Types.ON_DROP_FILES_TO_RIDE_ONGOING_REQUEST]:
    onDropFilesToRideOngoingRequest,
  [Types.ON_DROP_FILES_TO_RIDE_ONGOING_SUCCESS]:
    onDropFilesToRideOngoingSuccess,
  [Types.ON_DROP_FILES_TO_RIDE_ONGOING_FAILURE]:
    onDropFilesToRideOngoingFailure,
});
