import { put, takeLatest } from "redux-saga/effects";
import BlackListActions, {
  BlackListTypes,
} from "reducers/transporter/black-list";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* getBlackListAsyncRequest() {
  const query = `
    query{
        ServiceTransportersBlackList
          {_id siret name web email}
        }
    `;

  try {
    const data = yield graphQLClient().request(query);
    const transporters = data.ServiceTransportersBlackList;

    yield put(BlackListActions.getBlackListRequestSuccess(transporters));
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    yield put(BlackListActions.getBlackListRequestFailure(errors[0].message));
  }
}

function* removeTransporterFromBlackListAsyncRequest({ transporterId }) {
  const query = `
    mutation{
        ServiceRemoveTransporterFromTheBlackList(tansporterId: "${transporterId}")
      }
    `;

  try {
    yield graphQLClient().request(query);
    yield put(
      BlackListActions.removeTransporterFromBlackListRequestSuccess(
        transporterId
      )
    );
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    yield put(
      BlackListActions.removeTransporterFromBlackListRequestFailure(
        errors[0].message
      )
    );
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(BlackListTypes.GET_BLACK_LIST_REQUEST, getBlackListAsyncRequest),
  takeLatest(
    BlackListTypes.REMOVE_TRANSPORTER_FROM_BLACK_LIST_REQUEST,
    removeTransporterFromBlackListAsyncRequest
  ),
];
