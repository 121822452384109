import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const en = require("./en.json");
const fr = require("./fr.json");
const ar = require("./ar.json");

const TranslationSources = { en, ar, fr };

const fallbackLng = "fr";

i18n.use(initReactI18next).init({
  fallbackLng: fallbackLng,
  resources: TranslationSources,

  ns: ["Common"],
  defaultNS: "Common",

  //lng: 'fr',

  debug: false,

  //keySeparator: false,
  interpolation: {
    escapeValue: false,
    // formatSeparator: ','
  },
});

export default i18n;
