import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getServiceCompanyResetState: null,
  getServiceCompanyRequest: null,
  getServiceCompanyRequestSuccess: ["service"],
  getServiceCompanyRequestFailure: ["error"],

  doctorAddSecretaryRequest: ["email", "callback"],
  doctorAddSecretarySuccess: ["secretary"],
  doctorAddSecretaryFailure: ["error"],

  doctorDeleteSecretaryRequest: ["secretaryId", "callback"],
  doctorDeleteSecretarySuccess: ["secretaryId"],
  doctorDeleteSecretaryFailure: ["error"],
});

export const getServiceTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  service: {
    secretaries: [],
    doctors: [],
  },
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const getServiceCompanyResetState = (state, action) => {
  return INITIAL_STATE;
};
export const getServiceCompanyRequest = (state, action) => {
  console.log("REDUCER", state.service.secretaries);
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  nextSate = Immutable.setIn(nextSate, ["service", "details"], null);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const getServiceCompanyRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["service"], action.service);
};

export const getServiceCompanyRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const doctorAddSecretaryRequest = (state, action) => {
  return Immutable.setIn(state, ["action"], { loading: true, error: "" });
};

export const doctorAddSecretarySuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(
    nextSate,
    ["service", "secretaries"],
    [...state.service.secretaries, action.secretary]
  );
};

export const doctorAddSecretaryFailure = (state, action) => {
  return Immutable.setIn(state, ["action"], {
    loading: false,
    error: action.error,
  });
};

export const doctorDeleteSecretaryRequest = doctorAddSecretaryRequest;

export const doctorDeleteSecretarySuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(
    nextSate,
    ["service", "secretaries"],
    state.service.secretaries.filter(
      (secretary) => secretary.id !== action.secretaryId
    )
  );
};

export const doctorDeleteSecretaryFailure = doctorAddSecretaryFailure;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SERVICE_COMPANY_RESET_STATE]: getServiceCompanyResetState,
  [Types.GET_SERVICE_COMPANY_REQUEST]: getServiceCompanyRequest,
  [Types.GET_SERVICE_COMPANY_REQUEST_SUCCESS]: getServiceCompanyRequestSuccess,
  [Types.GET_SERVICE_COMPANY_REQUEST_FAILURE]: getServiceCompanyRequestFailure,

  [Types.DOCTOR_ADD_SECRETARY_REQUEST]: doctorAddSecretaryRequest,
  [Types.DOCTOR_ADD_SECRETARY_SUCCESS]: doctorAddSecretarySuccess,
  [Types.DOCTOR_ADD_SECRETARY_FAILURE]: doctorAddSecretaryFailure,

  [Types.DOCTOR_DELETE_SECRETARY_REQUEST]: doctorDeleteSecretaryRequest,
  [Types.DOCTOR_DELETE_SECRETARY_SUCCESS]: doctorDeleteSecretarySuccess,
  [Types.DOCTOR_DELETE_SECRETARY_FAILURE]: doctorDeleteSecretaryFailure,
});
