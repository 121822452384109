import { put, takeLatest } from "redux-saga/effects";
import SerieAction, { SerieTypes } from "reducers/serie/new-serie";
import _ from "lodash";
import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

//______________________ Company Service __________________
function* createNewSerieAsyncRequest({
  patient,
  course,
  serie,
  documents,
  doctorId,
  callback,
}) {
  //
  // const query = `
  //     mutation{
  //       ServiceCreateNewSerie(patient: {
  //           nss: "${patient.nss}",
  //           firstname: "${patient.firstname}",
  //           lastname: "${patient.lastname}",
  //           email: "${patient.email}",
  //           phone: "${patient.phone}",
  //           saveIsChecked:${patient.saveIsChecked}
  //         },
  //         course:{
  //           datetime: "${course.datetime}",
  //           partnerFirst: ${course.partnerFirst},
  //           pickup: {
  //             address: "${course.pickup.address}",
  //             longitude: ${course.pickup.longitude},
  //             latitude: ${course.pickup.latitude}
  //           }
  //           dropoff: {
  //             address: "${course.dropoff.address}",
  //             longitude: ${course.dropoff.longitude},
  //             latitude: ${course.dropoff.latitude}
  //           },
  //           personel: "${course.personel}",
  //           vehicleCategory: ${course.vehicleType},
  //           vehicleOptions:[ ${course.vehicleOptions}],
  //         },
  //         serie: {
  //           start: ${serie.start},
  //           end: ${serie.end},
  //           dates: [ ${serie.dates} ],
  //           returnDatetime: ${serie.returnDatetime},
  //         })
  //         {
  //           _id
  //         }
  //       }
  // `;

  let docs = "";
  documents.map((e) => {
    docs += `{
      size: "${e.size}",
      name: "${e.name}",
      extension: "${e.extension}",
      path: "${e.path}"
    },`;
  });

  const pickup =
    course.pickup.address == ""
      ? null
      : `
      {
        address: "${course.pickup.address}",
        longitude: ${course.pickup.longitude},
        latitude: ${course.pickup.latitude}
      }
    `;

  const query = `
    mutation{
      ServiceCreateNewSerieShort(
        patient: {
          lastname: "${patient.lastname}",
          phone: "${patient.phone}",
        },
        course:{
          datetime: "${course.datetime}",
          startNow: ${course.startNow},
          dropoff: {
            address: "${course.dropoff.address}",
            longitude: ${course.dropoff.longitude},
            latitude: ${course.dropoff.latitude}
          },
          pickup: ${pickup},
          vehicleCategory: ${course.vehicleType},
          note: "${course.note}",
        },
        nbSerieDates: ${serie.nbDates},
        documents: [${docs}],
        doctorId: "${doctorId}",
      ) { files { path name } }
    }
  `;

  try {
    const data = yield graphQLClient().request(query);
    const { files } = data.ServiceCreateNewSerieShort;
    yield put(SerieAction.createNewSerieRequestSuccess());

    if (_.isFunction(callback)) callback(false, files);
  } catch (error) {
    const { message = "" } = error.response.errors[0];

    yield errorHandler(message, SerieAction.createNewSerieRequestFailure);
  }
}

export default [
  takeLatest(SerieTypes.CREATE_NEW_SERIE_REQUEST, createNewSerieAsyncRequest),
];
