import { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

import routes from "routes";
import logo from "assets/img/resaam-logo.svg";
import avatar from "assets/img/avatar.svg";
import { version } from "../../../package.json";

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpened: false,
    };

    this.openMenu = this.openMenu.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  createLinks(isHover = false, hideTitle = false) {
    return routes.map((prop, key) => {
      if (prop.layout !== "/private") return null;

      if (prop.redirect || prop.hidden) {
        return null;
      }

      const icon = isHover ? (prop.hoverIcon || prop.icon) : prop.icon;
      return (
        <li
          className={`align-self-center ${this.activeRoute(
            prop.layout + prop.path
          )} ${hideTitle ? "pt-0" : ""}`}
          key={key}
        >
          <NavLink
            to={prop.layout + prop.path}
            className="nav-link"
            activeClassName="active"
          >
            {prop.icon ? <img src={icon} alt={prop.name} /> : null}
            {!hideTitle ? ` ${prop.name} ` : null}
          </NavLink>
        </li>
      );
    });
  }

  openMenu() {
    const { menuOpened } = this.state;
    this.setState(Object.assign({}, this.state, { menuOpened: !menuOpened }));
  }

  render() {
    const { t, i18n } = this.props;
    const { menuOpened } = this.state;

    return (
      <>
        <div className="d-flex side-bar flex-column">
          {/* <div className="d-flex d-lg-none flex-column align-items-end">
            {!menuOpened && (
              <FontAwesomeIcon
                icon="bars"
                color="#0096ac"
                size="lg"
                onClick={this.openMenu}
              />
            )}

            {menuOpened && (
              <FontAwesomeIcon
                icon="times"
                color="#0096ac"
                size="lg"
                onClick={this.openMenu}
              />
            )}
          </div> */}

          <div className="side-bar-wrapper flex-fill">
            <div className="navigation">
              <div className="logo d-none d-lg-flex justify-content-center">
                <img src={logo} alt="resaam-logo" style={{ width: 150, marginTop: 10 }} />
              </div>

              <div className="avatar d-none d-lg-flex flex-column align-items-center text-center">
                <img src={avatar} alt="avatar" style={{ width: 100 }} />
                {/* <h2>Bonjour <br/> {lastname} {firstname}</h2> */}
              </div>

              <ul className="nav d-none d-lg-block flex-column __hover">
                {this.createLinks(true)}
              </ul>

              {/* {menuOpened && (
                
              )} */}
              <ul className="nav d-bloc d-lg-none mr-2 flex-row justify-content-center align-items-center">
                {this.createLinks(true, true)}
              </ul>
            </div>
          </div>
          <div className="setings d-none d-lg-block">
            <p>{version}</p>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation(["Private", "Common"], { wait: true })(SideBar);
