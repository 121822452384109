import { put, takeLatest } from "redux-saga/effects";
import TestAction, { TestTypes } from "reducers/ride/test";
import RideAction from "reducers/ride/ongoing-rides";
import _ from "lodash";
import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

//______________________ Company Service __________________
function* changeRideStatusAsyncRequest({ rideId }) {
  const query = `
    mutation {
      ServiceRideNextStatusTest(rideId: "${rideId}") {
        _id
        status
        transporter {
          company { name phones }
          vehicle { plate make model color phone }
        }
      }
    }
  `;

  try {
    const { ServiceRideNextStatusTest } = yield graphQLClient().request(query);
    yield put(TestAction.changeRideStatusSuccess());
    yield put(RideAction.onGoingRideStatusChanged(ServiceRideNextStatusTest));
  } catch (error) {
    //console.log(error)
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, TestAction.changeRideStatusFailure);
  }
}

export default [
  takeLatest(
    TestTypes.CHANGE_RIDE_STATUS_REQUEST,
    changeRideStatusAsyncRequest
  ),
];
