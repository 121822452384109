import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  patientGetListResetState: null,
  patientGetListRequest: ["page", "limit", "filtered"],
  patientGetListRequestSuccess: ["list"],
  patientGetListRequestFailure: ["error"],

  deletePatientRequest: ["patient"],
  deletePatientRequestSuccess: null,
  deletePatientRequestFailure: ["error"],
});

export const patientTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  list: {
    page: 1,
    limit: 5,
    totalPages: 0,
    patients: [],
  },
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

/// GENERAL
export const patientGetListResetState = (state, action) => {
  return INITIAL_STATE;
};

export const patientGetListRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const patientGetListRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const patientGetListRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["list"], action.list);
};

export const deletePatientRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const deletePatientRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const deletePatientRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.PATIENT_GET_LIST_RESET_STATE]: patientGetListResetState,
  [Types.PATIENT_GET_LIST_REQUEST]: patientGetListRequest,
  [Types.PATIENT_GET_LIST_REQUEST_SUCCESS]: patientGetListRequestSuccess,
  [Types.PATIENT_GET_LIST_REQUEST_FAILURE]: patientGetListRequestFailure,

  [Types.DELETE_PATIENT_REQUEST]: deletePatientRequest,
  [Types.DELETE_PATIENT_REQUEST_SUCCESS]: deletePatientRequestSuccess,
  [Types.DELETE_PATIENT_REQUEST_FAILURE]: deletePatientRequestFailure,
});
