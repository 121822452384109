import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  rideHistoryResetState: null,
  rideHistoryRequest: ["page", "limit", "filter"],
  rideHistoryRequestSuccess: ["rides"],
  rideHistoryRequestFailure: ["error"],

  rideDetailsRequest: ["rideId"],
  rideDetailsRequestSuccess: ["ride"],
  rideDetailsRequestFailure: ["error"],

  addToBlackListRequest: ["transporterId", "callback"],
  addToBlackListRequestSuccess: null,
  addToBlackListRequestFailure: ["error"],

  addToWhiteListRequest: ["transporterId", "callback"],
  addToWhiteListRequestSuccess: null,
  addToWhiteListRequestFailure: ["error"],

  TransporterBlackListedRequest: ["transporterId", "callback"],
  TransporterBlackListedRequestSuccess: ["isBlackListed"],
  TransporterBlackListedRequestFailure: ["error"],

  TransporterWhiteListedRequest: ["transporterId", "callback"],
  TransporterWhiteListedRequestSuccess: ["isWhiteListed"],
  TransporterWhiteListedRequestFailure: ["error"],
});

export const RideHistoryTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  rides: {
    page: 1,
    limit: 10,
    totalPages: 0,
    rides: [],
  },
  ride: {
    details: null,
  },
  blackListed: {
    isBlackListed: null,
    isWhiteListed: null,
  },
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

/// GENERAL
export const rideHistoryResetState = (state, action) => {
  return INITIAL_STATE;
};

export const rideDetailsRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  nextSate = Immutable.setIn(nextSate, ["ride", "details"], null);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const rideDetailsRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["ride", "details"], action.ride);
};

export const rideDetailsRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const rideHistoryRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const rideHistoryRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const rideHistoryRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  if (action.rides.page == 1)
    //first page
    return Immutable.setIn(nextSate, ["rides"], action.rides);
  else {
    //apend next pages
    let prevList = state.rides.rides.asMutable({ deep: true });

    const { rides, ...rest } = action.rides;

    return Immutable.setIn(nextSate, ["rides"], {
      ...rest,
      rides: [...prevList, ...rides],
    });
  }
};

export const addToBlackListRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const addToBlackListRequestRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const addToBlackListRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const addToWhiteListRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const addToWhiteListRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const addToWhiteListRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const TransporterBlackListedRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const TransporterBlackListedRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(
    nextSate,
    ["blackListed", "isBlackListed"],
    action.isBlackListed
  );
};

export const TransporterBlackListedRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const TransporterWhiteListedRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const TransporterWhiteListedRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(
    nextSate,
    ["blackListed", "isWhiteListed"],
    action.isWhiteListed
  );
};

export const TransporterWhiteListedRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.isWhiteListed);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.RIDE_HISTORY_RESET_STATE]: rideHistoryResetState,
  [Types.RIDE_HISTORY_REQUEST]: rideHistoryRequest,
  [Types.RIDE_HISTORY_REQUEST_SUCCESS]: rideHistoryRequestSuccess,
  [Types.RIDE_HISTORY_REQUEST_FAILURE]: rideHistoryRequestFailure,

  [Types.RIDE_DETAILS_REQUEST]: rideDetailsRequest,
  [Types.RIDE_DETAILS_REQUEST_SUCCESS]: rideDetailsRequestSuccess,
  [Types.RIDE_DETAILS_REQUEST_FAILURE]: rideDetailsRequestFailure,

  [Types.ADD_TO_BLACK_LIST_REQUEST]: addToBlackListRequest,
  [Types.ADD_TO_BLACK_LIST_REQUEST_SUCCESS]:
    addToBlackListRequestRequestSuccess,
  [Types.ADD_TO_BLACK_LIST_REQUEST_FAILURE]: addToBlackListRequestFailure,

  [Types.TRANSPORTER_BLACK_LISTED_REQUEST]: TransporterBlackListedRequest,
  [Types.TRANSPORTER_BLACK_LISTED_REQUEST_SUCCESS]:
    TransporterBlackListedRequestSuccess,
  [Types.TRANSPORTER_BLACK_LISTED_REQUEST_FAILURE]:
    TransporterBlackListedRequestFailure,

  [Types.TRANSPORTER_WHITE_LISTED_REQUEST]: TransporterWhiteListedRequest,
  [Types.TRANSPORTER_WHITE_LISTED_REQUEST_SUCCESS]:
    TransporterWhiteListedRequestSuccess,
  [Types.TRANSPORTER_WHITE_LISTED_REQUEST_FAILURE]:
    TransporterWhiteListedRequestFailure,
});
