import { Subscription } from "@apollo/client/react/components";
import gql from "graphql-tag";

const SERVICE_RIDE_STATUS_SUBSCRIPTION = gql`
  subscription {
    serviceRideStatusSubscription {
      _id
      status
      transporter {
        company {
          name
        }
        vehicle {
          plate
          make
          model
          color
        }
      }
      taxi {
        driver {
          firstname
          lastname
        }
        vehicle {
          plate
          make
          model
          color
        }
      }
    }
  }
`;

const RideStatusNotification = ({ onGoingRideStatusChanged }) => (
  <Subscription
    subscription={SERVICE_RIDE_STATUS_SUBSCRIPTION}
    onSubscriptionData={({ subscriptionData }) => {
      const serviceRideStatusSubscription =
        subscriptionData.data.serviceRideStatusSubscription;
      onGoingRideStatusChanged(serviceRideStatusSubscription);
    }}
  >
    {({ data = {}, loading, error }) => null}
  </Subscription>
);

export default RideStatusNotification;
