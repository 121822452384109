import { Component } from "react";
import _ from "lodash";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { formatSizeFile } from "functions";
import CloseIcon from "assets/img/close";

class ManageFilesModal extends Component {
  constructor(props) {
    super(props);

    this.onDeleteFile = this.onDeleteFile.bind(this);
    this.onToggleSelectFile = this.onToggleSelectFile.bind(this);
    this.onChangeInputFile = this.onChangeInputFile.bind(this);

    this.state = {
      selectedFiles: [],
      isDeleteModalOpen: false,
    };
  }

  onDeleteFile() {
    const { selectedFiles } = this.state;
    const files = this.state.files.filter(
      (file) => !selectedFiles.includes(file.name)
    );
    this.props.onChangeFiles(files);
    this.setState({ isDeleteModalOpen: false, selectedFiles: [] });
  }

  onChangeInputFile(e) {
    const { files } = e.target;
    this.props.onChangeFiles([...this.props.files, ...files]);
  }

  onToggleSelectFile(path) {
    const { selectedFiles } = this.state;

    const index = selectedFiles.findIndex((p) => p === path);
    if (index === -1) {
      this.setState({ selectedFiles: [...selectedFiles, path] });
    } else {
      this.setState({ selectedFiles: selectedFiles.filter((p) => p !== path) });
    }
    this.setState({ isDeleteModalOpen: false });
  }

  render() {
    const { t, i18n, onCancel, files } = this.props;
    const { selectedFiles } = this.state;

    return (
      <Modal
        {...this.props}
        contentClassName="w-100"
        onHide={onCancel}
        centered
      >
        <Modal.Body className="text-center p-2">
          <div className="d-flex justify-content-end">
            <OverlayTrigger
              placement="bottom"
              overlay={(propsOverlay) => (
                <Tooltip {...propsOverlay}>Fermer</Tooltip>
              )}
            >
              <div onClick={onCancel} style={{ cursor: "pointer" }}>
                <CloseIcon />
              </div>
            </OverlayTrigger>
          </div>
          <h2 className="pb-3">Gérer les fichiers du transport</h2>
          <div className="d-flex justify-content-between">
            <div
              className="d-flex align-items-center"
              style={{ opacity: this.state.isDeleteModalOpen ? 100 : 0 }}
            >
              <div>Valider la suppression ?</div>
              <div
                style={{ marginLeft: 5, color: "red", cursor: "pointer" }}
                onClick={this.onDeleteFile}
              >
                Oui
              </div>
            </div>
            <div className="d-flex gap-2">
              <OverlayTrigger
                placement="bottom"
                overlay={(propsOverlay) => (
                  <Tooltip {...propsOverlay}>
                    Supprimer les fichiers sélectionnés
                  </Tooltip>
                )}
              >
                <button
                  type="button"
                  className={`btn btn-danger ${
                    selectedFiles.length > 0 ? "" : "disabled"
                  }`}
                  onClick={() => this.setState({ isDeleteModalOpen: true })}
                >
                  <FontAwesomeIcon icon={faTrash} color={"white"} />
                </button>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                overlay={(propsOverlay) => (
                  <Tooltip {...propsOverlay}>Ajouter des fichiers</Tooltip>
                )}
              >
                <label className="custom-file-upload">
                  <input
                    type="file"
                    multiple
                    onChange={this.onChangeInputFile}
                  />
                  <div className="btn btn-primary">
                    <FontAwesomeIcon icon={faPlus} color={"white"} />
                  </div>
                </label>
              </OverlayTrigger>
            </div>
          </div>
          <div
            className="table-responsive"
            style={{ overflowY: "auto", maxHeight: "500px", marginTop: 5 }}
          >
            <table className="table table-hover table-striped">
              <thead>
                <tr>
                  <th scope="col col-span-1">#</th>
                  <th scope="col col-span-7" className="text-start">
                    Nom
                  </th>
                  <th scope="col col-span-3" className="text-end">
                    Taille
                  </th>
                </tr>
              </thead>
              <tbody>
                {files.map((file, key) => (
                  <tr
                    key={key}
                    onClick={() => this.onToggleSelectFile(file.name)}
                  >
                    <td>
                      <FontAwesomeIcon
                        icon={faCheck}
                        color={
                          selectedFiles.includes(file.name)
                            ? "#0096ac"
                            : "#C5C5C5"
                        }
                      />
                    </td>
                    <th className={`text-start text-dark`} scope="row">
                      {file.name}
                    </th>
                    <td className={`text-end text-dark`}>
                      {formatSizeFile(file.size)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            type="button"
            variant="primary"
            onClick={onCancel}
            style={{ fontSize: 16, fontWeight: 600, padding: "10px 0" }}
          >
            ANNULER
          </Button>
          <Button
            type="button"
            variant="reservation"
            style={{ fontSize: 16, fontWeight: 600 }}
            onClick={onCancel}
            disabled={files.length == 0}
          >
            VALIDER
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ManageFilesModal;
