import { put, takeLatest } from "redux-saga/effects";
import RideAction, { RideTypes } from "reducers/ride/new-ride";
import _ from "lodash";
import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

//______________________ Company Service __________________
function* createNewRideAsyncRequest({
  patient,
  course,
  documents,
  doctorId,
  callback,
}) {
  //
  // const query = `
  //     mutation{
  //       ServiceCreateNewRide(patient: {
  //           nss: "${patient.nss}",
  //           firstname: "${patient.firstname}",
  //           lastname: "${patient.lastname}",
  //           email: "${patient.email}",
  //           phone: "${patient.phone}",
  //           saveIsChecked:${patient.saveIsChecked}
  //         },
  //         course:{
  //           datetime: "${course.datetime}",
  //           partnerFirst: ${course.partnerFirst},
  //           pickup: {
  //             address: "${course.pickup.address}",
  //             longitude: ${course.pickup.longitude},
  //             latitude: ${course.pickup.latitude}
  //           }
  //           dropoff: {
  //             address: "${course.dropoff.address}",
  //             longitude: ${course.dropoff.longitude},
  //             latitude: ${course.dropoff.latitude}
  //           },
  //           personel: "${course.personel}",
  //           vehicleCategory: ${course.vehicleType},
  //           vehicleOptions:[ ${course.vehicleOptions}],
  //           subject: ${course.subject},
  //           destinationInfos: {
  //             etablissement : "${course.destinationInfos.etablissement}",
  //             service: "${course.destinationInfos.service}",
  //             etage : "${course.destinationInfos.etage}",
  //             chambre: "${course.destinationInfos.chambre}"
  //           }
  //         }) {rideNumber}
  //       }
  // `;
  let docs = "";
  documents.map((e) => {
    docs += `{
      size: "${e.size}",
      name: "${e.name}",
      extension: "${e.extension}",
      path: "${e.path}"
    },`;
  });
  const pickup =
    course.pickup.address == ""
      ? null
      : `
      {
        address: "${course.pickup.address}",
        longitude: ${course.pickup.longitude},
        latitude: ${course.pickup.latitude}
      }
    `;
  const query = `
    mutation {
      ServiceCreateNewRideShort(
        patient: {
          lastname: "${patient.lastname}",
          phone: "${patient.phone}",
        },
        course:{
          datetime: "${course.datetime}",
          dropoff: {
            address: "${course.dropoff.address}",
            longitude: ${course.dropoff.longitude},
            latitude: ${course.dropoff.latitude}
          },
          pickup: ${pickup},
          vehicleCategory: ${course.vehicleType},
          note: """${course.note}""",
          isRoundTrip: ${course.isRoundTrip}
        },
        documents: [${docs}],
        doctorId: "${doctorId}",
      ) { files { path name } }
    }
  `;

  try {
    const { ServiceCreateNewRideShort } = yield graphQLClient().request(query);
    yield put(RideAction.createNewRideRequestSuccess());

    if (_.isFunction(callback))
      callback(false, ServiceCreateNewRideShort.files);
  } catch (error) {
    //console.log(error)
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, RideAction.createNewRideRequestFailure);
  }
}

export default [
  takeLatest(RideTypes.CREATE_NEW_RIDE_REQUEST, createNewRideAsyncRequest),
];
