import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  onGoingBilansResetState: null,

  onGoingBilansRequest: ["page", "limit", "filter"],
  onGoingBilansRequestSuccess: ["bilans"],
  onGoingBilansRequestFailure: ["error"],

  onGoingBilanStatusChanged: ["bilan"],

  serviceGetBilanWaitingTimeRequest: ["ridesId"],
  serviceGetBilanWaitingTimeSuccess: ["waitingTime"],
  serviceGetBilanWaitingTimeFailure: ["error"],

  historyBilansRequest: ["page", "limit", "filter"],
  historyBilansRequestSuccess: ["bilans"],
  historyBilansRequestFailure: ["error"],
});

export const OngoingBilanTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  bilans: {
    page: 1,
    limit: 10,
    totalPages: 0,
    bilans: [],
  },
  history: {
    page: 1,
    limit: 10,
    totalPages: 0,
    bilans: [],
  },
  waitingTime: [],
  action: {
    loading: false,
    error: "",
  },
});

/// GENERAL
export const onGoingBilansResetState = (state, action) => {
  return INITIAL_STATE;
};

export const onGoingBilanStatusChanged = (state, action) => {
  let bilans = state.bilans.bilans.asMutable({ deep: true });
  const haveBilan = bilans.filter((b) => b._id === action.bilan._id);
  let _bilans = [];
  /*console.log(action.bilan.status);
  if(action.bilan.status == "completed") {
    _bilans = bilans.filter(b => b._id !== action.bilan._id);
  }
  else */ if (haveBilan.length === 0) {
    _bilans = [...bilans];
    _bilans.push(action.bilan);
  } else {
    _bilans = _.map(bilans, (b) => {
      if (action.bilan._id === b._id) {
        return action.bilan;
        return { ...b, ...action.bilan };
      }
      return b;
    });
  }
  return Immutable.setIn(state, ["bilans", "bilans"], _bilans);
};

export const onGoingBilansRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const onGoingBilansRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const onGoingBilansRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  if (action.bilans.page == 1)
    //first page
    return Immutable.setIn(nextSate, ["bilans"], action.bilans);
  else {
    //apend next pages
    let prevList = state.bilans.bilans.asMutable({ deep: true });

    const { bilans, ...rest } = action.bilans;

    return Immutable.setIn(nextSate, ["bilans"], {
      ...rest,
      bilans: [...prevList, ...bilans],
    });
  }
};

export const serviceGetBilanWaitingTimeRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const serviceGetBilanWaitingTimeSuccess = (state, action) => {
  // const { waitingTime } = state.asMutable({ deep: true });
  // const s = _.reduce(
  //   waitingTime,
  //   (acc, el) => {
  //     const oldExist = _.filter(
  //       action.waitingTime,
  //       (e) => el.rideId == e.rideId
  //     );
  //     return _.isEmpty(oldExist) ? [...acc, el] : acc;
  //   },
  //   []
  // );

  // let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  // return Immutable.setIn(
  //   nextSate,
  //   ["waitingTime"],
  //   [...s, ...action.waitingTime]
  // );
  return Immutable.setIn(state, ["waitingTime"], action.waitingTime);
};

export const serviceGetBilanWaitingTimeFailure = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const historyBilansRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const historyBilansRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const historyBilansRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  if (action.bilans.page == 1)
    //first page
    return Immutable.setIn(nextSate, ["history"], action.bilans);
  else {
    //apend next pages
    let prevList = state.history.bilans.asMutable({ deep: true });

    const { bilans, ...rest } = action.bilans;

    return Immutable.setIn(nextSate, ["history"], {
      ...rest,
      history: [...prevList, ...bilans],
    });
  }
};
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.ON_GOING_BILANS_RESET_STATE]: onGoingBilansResetState,

  [Types.ON_GOING_BILANS_REQUEST]: onGoingBilansRequest,
  [Types.ON_GOING_BILANS_REQUEST_SUCCESS]: onGoingBilansRequestSuccess,
  [Types.ON_GOING_BILANS_REQUEST_FAILURE]: onGoingBilansRequestFailure,

  [Types.ON_GOING_BILAN_STATUS_CHANGED]: onGoingBilanStatusChanged,

  [Types.SERVICE_GET_BILAN_WAITING_TIME_REQUEST]:
    serviceGetBilanWaitingTimeRequest,
  [Types.SERVICE_GET_BILAN_WAITING_TIME_SUCCESS]:
    serviceGetBilanWaitingTimeSuccess,
  [Types.SERVICE_GET_BILAN_WAITING_TIME_FAILURE]:
    serviceGetBilanWaitingTimeFailure,

  [Types.HISTORY_BILANS_REQUEST]: historyBilansRequest,
  [Types.HISTORY_BILANS_REQUEST_SUCCESS]: historyBilansRequestSuccess,
  [Types.HISTORY_BILANS_REQUEST_FAILURE]: historyBilansRequestFailure,
});
