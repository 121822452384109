import LoginPage from "views/public/Login/LoginPage";
import CallbackProSante from "views/public/Login/LoginOpenIdPage";
import RegisterUrpsPage from "views/public/Register/RegisterUrpsPage";
import SuccessRegister from "views/public/Register/SuccessRegister";
import ForgotPassword from "views/public/ForgotPassword";

import NewRide from "views/private/new-ride";
import RideHistory from "views/private/RideHistory";
import OngoingRide from "views/private/OngoingRide";
// import TestPage from "views/private/test-page";

//Menu Items icons
import newRideMenuItem from "assets/img/new-ride-menu-item.svg";
import newRideMenuItemHover from "assets/img/new-ride-menu-item-hover.svg";
import ongoingRideMenuItem from "assets/img/ongoing-ride-menu-item.svg";
import rideHistoryMenuItem from "assets/img/ride-history-menu-item.svg";
import OngoingSerie from "views/private/OngoingSerie";
import OngoingBilan from "views/private/OngoingBilan";
import OngoingBilanHistory from "views/private/OngoingBilan/OngoingBilanHistory";
import mediacalRecordIcon from "assets/img/emergency-menu.svg";
import serieIcon from "assets/img/serie-menu.svg";
import Settings from "views/private/Settings";

const routes = [
  {
    path: "/new-ride",
    layout: "/private",
    name: "Réserver un transport",
    icon: newRideMenuItem,
    hoverIcon: newRideMenuItemHover,
    style: "primary",
    component: NewRide,
  },
  /*{
        path: "/test",
        layout: "/private",
        name: "Test",
        icon: newRideMenuItem,
        hoverIcon: newRideMenuItemHover,
        component: TestPage
    },*/
  {
    path: "/ongoing-rides",
    layout: "/private",
    name: "Réservations en cours",
    icon: ongoingRideMenuItem,
    component: OngoingRide,
  },
  {
    path: "/ongoing-series",
    layout: "/private",
    name: "Série en cours",
    icon: serieIcon,
    component: OngoingSerie,
  },
  {
    path: "/ongoing-bilans",
    layout: "/private",
    name: "Urgence en cours",
    icon: ongoingRideMenuItem,
    component: OngoingBilan,
    hidden: true,
  },
  {
    path: "/settings",
    layout: "/private",
    name: "Paramètres",
    icon: ongoingRideMenuItem,
    component: Settings,
    hidden: true,
  },
  // {
  //   path: "/bilans-history",
  //   layout: "/private",
  //   name: "Urgence",
  //   icon: mediacalRecordIcon,
  //   component: OngoingBilanHistory,
  // },
  {
    path: "/historique",
    layout: "/private",
    name: "historique",
    icon: rideHistoryMenuItem,
    component: RideHistory,
  },
  {
    redirect: true,
    layout: "/private",
    path: `/`,
    pathTo: "/new-ride",
  },
  {
    path: "/login",
    layout: "/public",
    name: "Page login",
    component: LoginPage,
  },
  {
    path: "/pro-sante-connect",
    layout: "/public",
    name: "Page Pro Santé Connect",
    component: CallbackProSante,
  },
  {
    path: "/register",
    layout: "/public",
    name: "Register",
    component: RegisterUrpsPage,
  },
  {
    path: "/register-urps",
    layout: "/public",
    name: "Register",
    component: RegisterUrpsPage,
  },
  {
    path: "/register-urps-success",
    layout: "/public",
    name: "Register Success",
    component: SuccessRegister,
  },
  {
    path: "/forgot",
    layout: "/public",
    name: "Forgot password",
    component: ForgotPassword,
  },
  {
    redirect: true,
    layout: "/public",
    path: `/`,
    pathTo: "/login",
  },
];

export default routes;
