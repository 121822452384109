import React, { Component } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { List, AutoSizer, InfiniteLoader } from "react-virtualized";
import OngoingBilanRow from "components/Cards/BilanCard";
import { connect } from "react-redux";
import OngoingBilansActions from "reducers/bilan";
import MinioActions from "reducers/minio";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import noDataIcon from "assets/img/no_data.svg";
import ramionIcon from "assets/img/ramion.svg";
import moment from "moment";

import searchInputIcon from "assets/img/search-input-icon.svg";
import Calendar from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import datepickerLocal from "globals/local-dates";
import pdfIcon from "assets/img/pdf-svgrepo-com.svg";
import chromatographieIcon from "assets/img/chromatographie.png";

import { Link } from "react-router-dom";
import { getBackendUrl } from "functions";

import downloadIcon from "assets/img/download.svg";
import { downloadFilesAsBlob } from "functions";

function getRidesId(bilans) {
  let ridesId = [];
  for (const bilan of bilans) {
    ridesId.push(bilan.ride.id);
  }
  return ridesId;
}

class OngoingBilan extends Component {
  constructor() {
    super();
    this.loadMore = this.loadMore.bind(this);
    this.rowRenderer = this.rowRenderer.bind(this);

    this.state = {
      width: 0,
      startIndexListItemRendred: 0,
      stopIndexListItemRendred: 0,
      showDriverLocation: false,
      selectedBilanToShowDriverLocation: 0,
    };
  }

  loadMore() {
    const { historyBilansRequest } = this.props;
    const { page, limit, totalDocs, bilans } = this.props.bilans;
    if (bilans.length < totalDocs) historyBilansRequest(page + 1, limit);
  }

  isRowLoaded = ({ index }) => {
    const { bilans } = this.props.bilans;
    return !!bilans[index];
  };

  rowRenderer = ({ index, key, style }) => {
    const { t, i18n } = this.props;

    const { top, position, height, left } = style;
    const { bilans } = this.props.bilans;

    const bilan = bilans[index];
    const {
      firstname = "",
      lastname = "",
      socialSecurity = {},
    } = bilan.administrative;
    const { getPresignedUrl } = this.props;
    function downloadFilesWithBilan() {
      const urlBilan = `${getBackendUrl()}/downloads/bilan/${bilan._id}`;
      const filesAvailable = bilan.documents
        ? Object.values(bilan.documents).filter((e) => e !== null)
        : [];
      window.open(urlBilan, "_blank");
      getPresignedUrl(
        filesAvailable.map((e) => e.path),
        async (urls) => {
          downloadFilesAsBlob(urls, filesAvailable, bilan.administrative);
        }
      );
    }

    // if (!this.isRowLoaded({ index })) {
    //   return null;
    //   /*(<div key={key}
    //             style={{
    //                 top: top,
    //                 position, left,
    //                 height: height, width:
    //                     this.state.width - 25
    //             }} className="d-flex">
    //             chargement ....
    //         </div>); */
    // }
    // if(bilans[index]?.ride.id == null) return null;
    function getFormatedWaitingTime(seconds = 0) {
      if (seconds > 3600) {
        const hours = parseInt(seconds / 60);
        return parseInt(hours / 60) + "h" + (hours % 60);
      }
      const minutes = parseInt(seconds / 60);
      return minutes + " min";
    }

    function ButtonDownLoadBilan() {
      return (
        <div
          onClick={downloadFilesWithBilan}
          style={{
            padding: "3px 5px",
            border: "1px solid",
            borderColor: "#0296AE",
            display: "flex",
            alignItems: "center",
            gap: "3px",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <img
            src={downloadIcon}
            style={{ width: "15px" }}
            alt="download bilan"
          />
          <div style={{ fontSize: "12px", fontWeight: 700, color: "#0296AE" }}>
            FICHE BILAN
          </div>
        </div>
      );
    }

    return (
      <div
        key={key}
        style={{ marginBottom: "8px", width: "100%" }}
        className="history-bilan-row"
      >
        <div className="d-flex flex-row justify-content-around pt-3">
          <div className="cell">
            <h2 className={`name`}>{`${lastname}`}</h2>
            <h2 className={`name`}>{`${firstname}`}</h2>

            <span className="ssn">{socialSecurity.nss}</span>
          </div>

          <div className="d-flex flex-column">
            {true ? (
              <>
                <h4 className="name">
                  {bilan.administrative.gender == "male" ? "Homme" : "Femme"}
                </h4>
                <h4 className="name">{bilan.administrative.age + " ans"}</h4>
              </>
            ) : (
              <p className="name">
                {t(`Private:onGoingSeriesPage.not_completed`, {
                  lng: i18n.language,
                })}
              </p>
            )}
          </div>

          {/* <div className="x-small">
            <span className="datetime">{`${moment(true ? new Date() : "createdAt").format(
              "ll"
            )}`}</span>
            <span className="datetime">{`${moment(true ? new Date() : "createdAt").format(
              "LT"
            )}`}</span>
          </div> */}

          {/* <div className="cell d-flex flex-row">
            <img src={pickupDropoff} style={{ marginRight: 5 }} />
            <div className="d-flex flex-column py-1 justify-content-between">
              <span className="company-address text-truncate">
                {"pickup.address" || ""}
              </span>
              <span className="company-address text-truncate">
                {"dropoff.address" || ""}
              </span>
            </div>
          </div> */}

          <div className="small d-flex flex-column justify-content-between align-item-center text-center">
            <span className="datetime">
              {bilan.ride.waitingTime
                ? getFormatedWaitingTime(
                    bilan.ride.waitingTime.duration_in_traffic
                  )
                : bilan.status == "completed"
                ? "Patient arrivé"
                : ""}
            </span>
          </div>
          {/* Action Button */}
          <div
            className="d-none d-lg-flex justify-content-center align-items-start"
            style={{ marginRight: 4 }}
          >
            {/* <a href={`${getBackendUrl()}/downloads/bilan/${bilan.ride.id}`} target="_blank" className="link-primary text-decoration-none"> */}
            <ButtonDownLoadBilan />
            {/* <img src={pdfIcon} style={{width: 25}} /> */}
            {/* </a> */}
          </div>
          {/* <div
            className="x-small d-flex flex-column justify-content-end align-items-end"
            style={{ height: "100%", paddingTop: 20, paddingBottom: 20 }}
          > */}
          {/* Edit Button */}
          {/* <ImageButton size="sm" round src={editRideIcon} /> */}
          {/* Cancel Button */}
          {/* <ImageButton
              size="sm"
              round
              src={cancelRideIcon}
              onClick={this.onCancelSerieAction}
            />
          </div> */}
        </div>

        <div
          className="d-flex d-lg-none justify-content-end pb-2"
          style={{ marginRight: 4 }}
        >
          {/* <a href={`${getBackendUrl()}/downloads/bilan/${bilan.ride.id}`} target="_blank" className="link-primary text-decoration-none"> */}
          <ButtonDownLoadBilan />
          {/* <img src={pdfIcon} style={{width: 25}} /> */}
          {/* </a> */}
        </div>

        {/* <OngoingBilanRow
          bilan={bilans[index]}
          {...{ t, i18n }}
          waitingTime={_.isEmpty(_waitingTime) ? null : _waitingTime[0]}
        /> */}
      </div>
    );
  };

  onRowsRendered(params) {
    const { startIndex, stopIndex } = params;
    const { startIndexListItemRendred, stopIndexListItemRendred } = this.state;
    if (
      startIndex != startIndexListItemRendred ||
      stopIndex != stopIndexListItemRendred
    ) {
      this.setState(
        Object.assign({}, this.state, {
          startIndexListItemRendred: startIndex,
          stopIndexListItemRendred: stopIndex,
        }),
        () => {}
      );
    }
  }

  componentDidMount() {
    const { historyBilansRequest } = this.props;

    historyBilansRequest(1, 100);
  }

  componentWillUnmount() {}

  render() {
    const { t, i18n } = this.props;
    const { limit, totalDocs = 0, bilans } = this.props.bilans;

    return (
      <Container fluid className="h-100 d-flex flex-column">
        <Row className="mt-4 px-2 d-flex justify-content-between">
          <Col>
            <h1>Interventions en cours</h1>
          </Col>
          <Col>
            <Link
              to={"/private/ongoing-bilans"}
              target="_blank"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <img src={chromatographieIcon} alt="" />
            </Link>
          </Col>
        </Row>

        <Row className="mt-4 px-2">
          <Col sm="4">
            <Form className="ongoing-ride-search w-40">
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="N° réservation, patient, date, destination, prestataire"
                  // ajouter la variable search
                  onChange={(e) => {
                    const { value } = e.target;
                    this.setState(
                      Object.assign({}, this.state, {
                        searchInput: value,
                      })
                    );
                  }}
                  value={this.state.searchInput}
                />
                <div className="search-icon">
                  <img src={searchInputIcon} />
                </div>
              </InputGroup>
            </Form>
          </Col>
          <Col className="sm-4"></Col>
          <Col className="sm-4 d-flex justify-content-end align-items-center">
            <Calendar
              className="container-input-calendar"
              multiple={true}
              value={this.state.searchDates}
              weekStartDayIndex={1}
              disableDayPicker={false}
              showOtherDays={false}
              calendarPosition="Left Top"
              render={<InputIcon />}
              locale={datepickerLocal}
              shadow={false}
              onChange={(selectedDates) => {
                this.setState({ searchDates: selectedDates });
              }}
            />
          </Col>
        </Row>

        <Row className="flex-grow-1 mt-5">
          <Col>
            {totalDocs == 0 && bilans.length == 0 && (
              <div className="no-data">
                <img src={noDataIcon} />
                <p className="text-no-data">
                  {/* {t("onGoingBilansPage.message_when_no_data", {
                    lng: i18n.language,
                  })} */}
                  Vous n'avez pas encore d'urgence
                </p>
              </div>
            )}
            <InfiniteLoader
              isRowLoaded={({ index }) => !!bilans[index]}
              loadMoreRows={this.loadMore}
              rowCount={totalDocs}
              threshold={limit / 2}
            >
              {({ onRowsRendered, registerChild }) => (
                <AutoSizer>
                  {({ width, height }) => {
                    if (width != this.state.width)
                      this.setState(Object.assign({}, this.state, { width }));
                    return (
                      <List
                        height={
                          totalDocs === 0 ? 0 : height === 0 ? 500 : height
                        }
                        rowHeight={200}
                        width={this.state.width}
                        ref={registerChild}
                        rowCount={totalDocs}
                        rowRenderer={this.rowRenderer}
                        onRowsRendered={(param) => {
                          onRowsRendered(param);
                          this.onRowsRendered(param);
                        }}
                        data={bilans}
                      />
                    );
                  }}
                </AutoSizer>
              )}
            </InfiniteLoader>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bilans: state.ongoingBilans.history.asMutable({ deep: true }),
    // history: state.ongoingBilans.history.asMutable({ deep: true }),
    action: state.ongoingBilans.action.asMutable({ deep: true }),
    // waitingTime: state.ongoingBilans.waitingTime.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    historyBilansRequest: (...args) =>
      dispatch(OngoingBilansActions.historyBilansRequest(...args)),
    getPresignedUrl: (...args) =>
      dispatch(MinioActions.getPresignedUrl(...args)),
  };
};

const onGoingBilansRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(OngoingBilan);
export default withTranslation(["Private", "Common"], { wait: true })(
  onGoingBilansRedux
);
