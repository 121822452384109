import { put, takeLatest } from "redux-saga/effects";
import DriverLocationActions, {
  DriverLocationTypes,
} from "reducers/ride/driver-location";
import _ from "lodash";
import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* serviceGetRideDriverLocationAsyncRequest({ rideId }) {
  const query = `
  query{
    serviceGetRideDriverCurrentLocation(
    rideId:"${rideId}"
    ){
      rideId 
      longitude 
      latitude
    }
  }
  `;
  try {
    const { serviceGetRideDriverCurrentLocation } =
      yield graphQLClient().request(query);
    yield put(
      DriverLocationActions.serviceGetRideDriverLocationRequestSuccess(
        serviceGetRideDriverCurrentLocation
      )
    );
  } catch (error) {
    /*const { message = "" } = error.response.errors[0]
    yield errorHandler(message,DriverLocationActions.serviceGetRideDriverLocationRequestFailure)
    if (_.isFunction(callback)) callback()
    */
  }
}

function* serviceGetRidesWaitingTimeAsyncRequest({ ridesId }) {
  let _ridesId = _.reduce(ridesId, (acc, e) => [...acc, `"${e}"`], []);

  const query = `
  query{ServiceGetRideWaitingTime(
      ridesId:[${_ridesId.join(",")}]
      ){
          rideId, 
          duration, 
          distance
        }
    }
  `;

  try {
    const { ServiceGetRideWaitingTime } = yield graphQLClient().request(query);

    yield put(
      DriverLocationActions.serviceGetRidesWaitingTimeRequestSuccess(
        ServiceGetRideWaitingTime
      )
    );
  } catch (error) {
    /*const { message = "" } = error.response.errors[0]
    yield errorHandler(message,DriverLocationActions.serviceGetRidesWaitingTimeRequestFailure)
    if (_.isFunction(callback)) callback()
    */
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(
    DriverLocationTypes.SERVICE_GET_RIDE_DRIVER_LOCATION_REQUEST,
    serviceGetRideDriverLocationAsyncRequest
  ),
  takeLatest(
    DriverLocationTypes.SERVICE_GET_RIDES_WAITING_TIME_REQUEST,
    serviceGetRidesWaitingTimeAsyncRequest
  ),
];
