import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  serieResetState: null,
  createNewSerieRequest: [
    "patient",
    "course",
    "serie",
    "documents",
    "doctorId",
    "callback",
  ],
  createNewSerieRequestSuccess: null,
  createNewSerieRequestFailure: ["error"],
});

export const SerieTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

/// GENERAL
export const serieResetState = (state, action) => {
  return INITIAL_STATE;
};

export const createNewSerieRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const createNewSerieRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const createNewSerieRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SERIE_RESET_STATE]: serieResetState,
  [Types.CREATE_NEW_SERIE_REQUEST]: createNewSerieRequest,
  [Types.CREATE_NEW_SERIE_REQUEST_SUCCESS]: createNewSerieRequestSuccess,
  [Types.CREATE_NEW_SERIE_REQUEST_FAILURE]: createNewSerieRequestFailure,
});
