import React, { Component } from "react";
import FloatingInput from "components/Floating-Input-Field";
import { Button } from "react-bootstrap";
import FloatingInputField from "components/Floating-Input-Field";

class ResetView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordConfirm: "",
      error: "",
    };

    this.onPropertyChanged = this.onPropertyChanged.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onPropertyChanged = (properiety) => {
    this.setState(Object.assign({}, this.state, { ...properiety }));
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onSubmit();
    }
  };

  onSubmit() {
    const { onPressResetPasswordButton, t, i18n } = this.props;
    const { password, passwordConfirm } = this.state;
    if (password == passwordConfirm) {
      onPressResetPasswordButton(password);
    } else {
      this.setState({
        error: t("ForgotPasswordPage.reset.error_message", {
          lng: i18n.language,
        }),
      });
    }
  }

  render() {
    const { t, i18n } = this.props;
    const { password, passwordConfirm, error } = this.state;

    return (
      <div className="d-flex flex-column gap-4" style={{ maxWidth: "700px" }}>
        <div className="text-center px-8">
          <h1>
            {t("ForgotPasswordPage.reset.head_title", { lng: i18n.language })}
          </h1>
          <p className="text-danger">{error}</p>
        </div>
        <FloatingInputField
          type="password"
          label={t("ForgotPasswordPage.reset.password_label", {
            lng: i18n.language,
          })}
          autoComplete="off"
          onChange={(e) => this.onPropertyChanged({ password: e.target.value })}
          onKeyPress={this.handleKeyPress}
          value={password}
        />
        <FloatingInputField
          type="password"
          label={t("ForgotPasswordPage.reset.password_confirm_label", {
            lng: i18n.language,
          })}
          autoComplete="off"
          onChange={(e) =>
            this.onPropertyChanged({ passwordConfirm: e.target.value })
          }
          onKeyPress={this.handleKeyPress}
          value={passwordConfirm}
        />
        <div className="w-100 d-flex justify-content-end">
          <Button
            variant="login"
            type="submit"
            onClick={this.onSubmit}
            disabled={password === ""}
          >
            {t("ForgotPasswordPage.reset.button_label", { lng: i18n.language })}
          </Button>
        </div>
      </div>
    );
  }
}

export default ResetView;
