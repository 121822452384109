import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
//import { List, AutoSizer, InfiniteLoader } from 'react-virtualized'
import InfiniteScroll from "react-infinite-scroll-component";
import RideHistoryRow from "components/Ride-History-Item";
import { connect } from "react-redux";
import RideHistoryActions from "reducers/ride/ride-history";
import { withTranslation } from "react-i18next";
import Calendar from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import datepickerLocal from "globals/local-dates";
import searchInputIcon from "assets/img/search-input-icon.svg";
import noDataIcon from "assets/img/no_data.svg";

class RideHistory extends Component {
  constructor() {
    super();
    this.loadMore = this.loadMore.bind(this);
    this.refresh = this.refresh.bind(this);
    this.state = {
      width: 0,
      searchInput: "",
      searchDates: [],
    };
  }

  componentDidMount() {
    const { rideHistoryRequest } = this.props;
    rideHistoryRequest(1, 10);
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchDates, searchInput } = this.state;
    if (
      prevState.searchDates !== searchDates ||
      prevState.searchInput !== searchInput
    ) {
      this.props.rideHistoryRequest(1, 10, {
        text: searchInput,
        dates: searchDates.length !== 0 ? `"${searchDates.join('","')}"` : "",
      });
    }
  }

  loadMore() {
    const { rideHistoryRequest } = this.props;
    const { page, limit, totalDocs, rides } = this.props.rides;
    if (rides.length < totalDocs)
      rideHistoryRequest(page + 1, limit, {
        text: this.state.searchInput,
        dates: this.state.searchDates,
      });
  }

  isRowLoaded = ({ index }) => {
    const { rides } = this.props.rides;
    return !!rides[index];
  };

  rowRenderer = ({ index, key, style }) => {
    const { t, i18n } = this.props;
    const { top, position, height, left } = style;
    const { rides } = this.props.rides;
    if (!this.isRowLoaded({ index })) {
      return null;
      /*(<div key={key}
                style={{
                    top: top,
                    position, left,
                    height: height, width:
                        this.state.width - 25
                }} className="d-flex">
                chargement ....
            </div>); */
    }

    return (
      <div key={key} style={{ marginBottom: "8px" }} className="d-flex">
        <RideHistoryRow ride={rides[index]} {...{ t, i18n }} />
      </div>
    );
  };

  refresh() {
    const { rideHistoryRequest } = this.props;
    rideHistoryRequest(1, 10);
  }
  render() {
    const { t, i18n } = this.props;
    const { limit, totalDocs = 0, rides, hasNextPage } = this.props.rides;

    //const rowCount = totalDocs //== 0 ? limit * 2 : totalDocs

    return (
      <Container fluid className="h-100 d-flex flex-column">
        <Row className="mt-4 px-2">
          <Col>
            <h1>
              {t("rideHistoryPage.rides_history_headline_title", {
                lng: i18n.language,
              })}
            </h1>
          </Col>
        </Row>

        <Row className="mt-4 px-2">
          <Col sm="6" md="4">
            <Form className="ride-history-search">
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="N° réservation, patient, date, destination, prestataire"
                  onChange={(e) => {
                    const { value } = e.target;
                    this.setState({ searchInput: value });
                  }}
                />
                <div className="search-icon">
                  <img src={searchInputIcon} />
                </div>
              </InputGroup>
            </Form>
          </Col>
          <Col className="sm-8 d-flex justify-content-end align-items-center">
            <Calendar
              className="container-input-calendar"
              multiple={true}
              value={this.state.searchDates}
              weekStartDayIndex={1}
              disableDayPicker={false}
              showOtherDays={false}
              calendarPosition="Left Top"
              render={<InputIcon />}
              locale={datepickerLocal}
              shadow={false}
              onChange={(selectedDates) => {
                this.setState({ searchDates: selectedDates });
              }}
            />
          </Col>
        </Row>

        <Row className="flex-grow-1 mt-5">
          <Col>
            {totalDocs == 0 && (
              <div className="no-data">
                <img src={noDataIcon} />
                <p className="text-no-data">
                  {t("rideHistoryPage.message_when_no_data")}
                </p>
              </div>
            )}

            {totalDocs > 0 && (
              <InfiniteScroll
                dataLength={totalDocs} //This is important field to render the next data
                next={this.loadMore}
                // below props only if you need pull down functionality
                refreshFunction={this.refresh}
                hasMore={hasNextPage}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    {/* <b>Vous avez tout vu</b> */}
                  </p>
                }
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={
                  <h3 style={{ textAlign: "center" }}>
                    &#8595; Dérouler pour rafraîchir
                  </h3>
                }
                releaseToRefreshContent={
                  <h3 style={{ textAlign: "center" }}>
                    &#8593; Relâcher pour rafraîchir
                  </h3>
                }
              >
                {rides.map((ride, index) => (
                  <div
                    key={index}
                    style={{ marginBottom: "8px" }}
                    className="d-flex"
                  >
                    <RideHistoryRow ride={rides[index]} {...{ t, i18n }} />
                  </div>
                ))}
              </InfiniteScroll>
            )}

            {/*
            }
            <InfiniteLoader
              isRowLoaded={({ index }) => !!rides[index]}
              loadMoreRows={this.loadMore}
              rowCount={totalDocs}
              threshold={limit / 2}
            >
              {({ onRowsRendered, registerChild }) => (
                <AutoSizer>
                  {({ width, height }) => {
                    if (width != this.state.width)
                      this.setState(Object.assign({}, this.state, { width }));

                    return (
                      <List
                        height={totalDocs === 0 ? 0 : (height === 0 ? 500 : height)}
                        rowHeight={160}
                        width={this.state.width < 1000 ? 1000 : this.state.width }

                        ref={registerChild}
                        rowCount={totalDocs}
                        rowRenderer={this.rowRenderer}
                        onRowsRendered={onRowsRendered}
                      />
                    );
                  }}
                </AutoSizer>
              )}
            </InfiniteLoader>
            {
              */}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rides: state.ridesHistory.rides.asMutable({ deep: true }),
    action: state.ridesHistory.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    rideHistoryRequest: (...args) =>
      dispatch(RideHistoryActions.rideHistoryRequest(...args)),
  };
};

const rideHistoryRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(RideHistory);
export default withTranslation(["Private", "Common"], { wait: true })(
  rideHistoryRedux
);
