import { put, takeLatest } from "redux-saga/effects";
import WhiteListActions, {
  WhiteListTypes,
} from "reducers/transporter/white-list";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* getWhiteListAsyncRequest() {
  const query = `
    query{
        ServiceTransportersWhiteList
          {_id siret name web email}
        }
    `;

  try {
    const data = yield graphQLClient().request(query);
    const transporters = data.ServiceTransportersWhiteList;

    yield put(WhiteListActions.getWhiteListRequestSuccess(transporters));
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    yield put(WhiteListActions.getWhiteListRequestFailure(errors[0].message));
  }
}

function* removeTransporterFromWhiteListAsyncRequest({ transporterId }) {
  const query = `
    mutation{
        ServiceRemoveTransporterFromTheWhiteList(tansporterId: "${transporterId}")
      }
    `;

  try {
    yield graphQLClient().request(query);
    yield put(
      WhiteListActions.removeTransporterFromWhiteListRequestSuccess(
        transporterId
      )
    );
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    yield put(
      WhiteListActions.removeTransporterFromWhiteListRequestFailure(
        errors[0].message
      )
    );
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(WhiteListTypes.GET_WHITE_LIST_REQUEST, getWhiteListAsyncRequest),
  takeLatest(
    WhiteListTypes.REMOVE_TRANSPORTER_FROM_WHITE_LIST_REQUEST,
    removeTransporterFromWhiteListAsyncRequest
  ),
];
