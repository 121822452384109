import { getBackendUrl } from "functions";
import { GraphQLClient } from "graphql-request";
import { Store } from "../store";

const state = Store.getState();
const account = state.account.asMutable({ deep: true });
const { token } = account;

const BACK_END = getBackendUrl() + process.env.REACT_APP_BACK_END_API_PATH;

const graphQLClient = new GraphQLClient(BACK_END, {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    dataType: "json",
    authorization: `Bearer ${token}`,
  },
});

export { graphQLClient };
