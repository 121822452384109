import { put, takeLatest } from "redux-saga/effects";
import etablishmentListActions, {
  etablishmentTypes,
} from "reducers/etablishments/etablishment";
import errorHandler from "sagas/error-handler";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* etablishmentListAsyncRequest({ page, limit, filtered }) {
  let _filter = _.reduce(
    filtered,
    (acc, { id, value }) => {
      return [...acc, `{id : "${id}", value : "${value}"}`];
    },
    []
  );
  const query = `
      {
        ServiceGetEtablishments(page:${page},limit:${limit} ,filtered:[${_filter.join(
    ","
  )}])

        {
          page limit totalPages totalDocs  
          etablishments{name service address{address  latitude longitude}
          }
        
        }
         }          
      
  `;

  try {
    const data = yield graphQLClient().request(query);
    const list = data.ServiceGetEtablishments;
    yield put(etablishmentListActions.etablishmentListRequestSuccess(list));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      etablishmentListActions.etablishmentListRequestFailure
    );
  }
}

function* deleteEtablishmentAsyncRequest({ etablishment }) {
  const { name, service, address } = etablishment;
  const query = `
     mutation { 

        ServiceDeleteEtablishment(
          name:"${name}",
          serviceCompany:"${service}",
          address:"${address.address}"
         
          
      
        )
      
      }
      
     `;

  try {
    yield graphQLClient().request(query);
    yield put(etablishmentListActions.deleteEtablishmentRequestSuccess());
    yield put(etablishmentListActions.etablishmentListRequest(1, 10));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      etablishmentListActions.deleteEtablishmentRequestFailure
    );
  }
}

function* createEtablishmentAsyncRequest({ etablishment }) {
  const { name, service, address } = etablishment;
  const query = `
     mutation { 
        ServiceCreateEtablishment(
         etablishment:{ 
            name:"${name}",
          service:"${service}",
          address:{ 
          
            latitude:${address.latitude},
            longitude:${address.longitude},
            address:"${address.address}"
          
              }
        
        
        
        }
        
        )
          { 
          
          name service address{address latitude longitude}
          }
        
        }
        
      
     `;

  try {
    yield graphQLClient().request(query);
    yield put(etablishmentListActions.createNewEtablishmentRequestSuccess());
    yield put(etablishmentListActions.etablishmentListRequest(1, 10));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      etablishmentListActions.createNewEtablishmentRequestFailure
    );
  }
}
/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(
    etablishmentTypes.ETABLISHMENT_LIST_REQUEST,
    etablishmentListAsyncRequest
  ),
  takeLatest(
    etablishmentTypes.DELETE_ETABLISHMENT_REQUEST,
    deleteEtablishmentAsyncRequest
  ),
  takeLatest(
    etablishmentTypes.CREATE_NEW_ETABLISHMENT_REQUEST,
    createEtablishmentAsyncRequest
  ),
];
