import { Subscription } from "@apollo/client/react/components";
import gql from "graphql-tag";

const SERVICE_BILAN_SAMU_UPDATED_SUBSCRIPTION = gql`
  subscription {
    ServiceBilanSamuUpdatedSubscription
  }
`;

const BilanUpdatedNotification = ({ onGoingBilanUpdated }) => (
  <Subscription
    subscription={SERVICE_BILAN_SAMU_UPDATED_SUBSCRIPTION}
    onSubscriptionData={({ subscriptionData }) => {
      const data = subscriptionData.data.ServiceBilanSamuUpdatedSubscription;
      onGoingBilanUpdated(data);
    }}
  >
    {({ data = {}, loading, error }) => null}
  </Subscription>
);

export default BilanUpdatedNotification;
