import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  changePickupRequest: null,
  // getProfileAndSettingsRequest: null,
  // getProfileAndSettingsSuccess: ["settings"],
  // getProfileAndSettingsFailure: ["error"],
});

export const SettingsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  pickupFromService: false,
  // settings: {
  //   doctors: [],
  //   secretaries: [],
  // },
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const changePickupRequest = (state, action) => {
  return Immutable.setIn(
    state,
    ["pickupFromService"],
    !state.pickupFromService
  );
};

// export const getProfileAndSettingsRequest = (state, action) => {
//   return Immutable.setIn(state, ["action"], { loading: true, error: "" });

// };

// export const getProfileAndSettingsSuccess = (state, action) => {
//   const nextState = Immutable.setIn(state, ["action", "loading"], false);
//   return Immutable.setIn(nextState, ["settings"], action.settings);
// };

// export const getProfileAndSettingsFailure = (state, action) => {
//   return Immutable.setIn(state, ["action"], { loading: false, error: action.error });
// };

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_PICKUP_REQUEST]: changePickupRequest,

  // [Types.GET_PROFILE_AND_SETTINGS_REQUEST]: getProfileAndSettingsRequest,
  // [Types.GET_PROFILE_AND_SETTINGS_SUCCESS]: getProfileAndSettingsSuccess,
  // [Types.GET_PROFILE_AND_SETTINGS_FAILURE]: getProfileAndSettingsFailure,
});
