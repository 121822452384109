import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  rideResetState: null,
  changeRideStatusRequest: ["rideId"],
  changeRideStatusSuccess: null,
  changeRideStatusFailure: ["error"],
});

export const TestTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

/// GENERAL
export const rideResetState = (state, action) => {
  return INITIAL_STATE;
};

export const changeRideStatusRequest = (state, action) => {
  return Immutable.setIn(state, ["action"], { loading: true, error: "" });
};

export const changeRideStatusSuccess = (state, action) => {
  return Immutable.setIn(state, ["action"], { loading: false, error: "" });
};

export const changeRideStatusFailure = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.RIDE_RESET_STATE]: rideResetState,
  [Types.CHANGE_RIDE_STATUS_REQUEST]: changeRideStatusRequest,
  [Types.CHANGE_RIDE_STATUS_SUCCESS]: changeRideStatusSuccess,
  [Types.CHANGE_RIDE_STATUS_FAILURE]: changeRideStatusFailure,
});
