import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  rideResetState: null,
  createNewRideRequest: [
    "patient",
    "course",
    "documents",
    "doctorId",
    "callback",
  ],
  createNewRideRequestSuccess: null,
  createNewRideRequestFailure: ["error"],
});

export const RideTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

/// GENERAL
export const rideResetState = (state, action) => {
  return INITIAL_STATE;
};

export const createNewRideRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const createNewRideRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const createNewRideRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.RIDE_RESET_STATE]: rideResetState,
  [Types.CREATE_NEW_RIDE_REQUEST]: createNewRideRequest,
  [Types.CREATE_NEW_RIDE_REQUEST_SUCCESS]: createNewRideRequestSuccess,
  [Types.CREATE_NEW_RIDE_REQUEST_FAILURE]: createNewRideRequestFailure,
});
