import React, { Component } from "react";
import approveChatIcon from "assets/img/approve_chat.svg";
import { NavLink } from "react-router-dom";

class SuccessView extends Component {
  render() {
    const { t, i18n, isRequest } = this.props;

    return (
      <div className="d-flex gap-4" style={{ maxWidth: "700px", gap: "10px" }}>
        <img src={approveChatIcon} />
        <div>
          <h1>
            {t("ForgotPasswordPage.success.head_title", { lng: i18n.language })}
          </h1>
          <p>
            {t(
              `ForgotPasswordPage.success.${
                isRequest ? "description_request" : "description_reset"
              }`,
              { lng: i18n.language }
            )}
          </p>

          {isRequest && (
            <p className="text-secondary">
              <i>
                {t("ForgotPasswordPage.success.disclaimer", {
                  lng: i18n.language,
                })}
              </i>
            </p>
          )}

          <div className="w-100 d-flex justify-content-end mt-2">
            <NavLink to={"/login"} className="nav-link">
              {t("ForgotPasswordPage.request.login_link", {
                lng: i18n.language,
              })}
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default SuccessView;
