import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/sass/style.scss";
import Setup from "./boot/setup";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

ReactDOM.render(
  // <React.Fragment>
  <Setup />,
  // </React.Fragment>
  document.getElementById("root")
);
