import React, { Component } from "react";
import waveBg from "assets/img/waves-bg.svg";
import approveChatIcon from "assets/img/approve_chat.svg";
import { NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";

class SuccessRegister extends Component {
  render() {
    return (
      <Container
        fluid
        className="vh-100 d-flex flex-column justify-content-center align-items-center login-page"
      >
        <div
          className="d-flex gap-4"
          style={{ maxWidth: "700px", gap: "10px" }}
        >
          <img src={approveChatIcon} />
          <div>
            <h1>Votre demande a bien été prise en compte !</h1>
            <p>
              Vous pouvez commencer dès maintenant en vous connectant.
              {/* Veuillez revenir à la page de connexion */}
            </p>

            <div className="w-100 d-flex justify-content-end mt-2 p-4">
              <NavLink to={"/login"} className="nav-link">
                Se connecter
              </NavLink>
            </div>
          </div>
        </div>
        {/* <div className="footer">
          <img src={waveBg} />
        </div> */}
      </Container>
    );
  }
}

export default SuccessRegister;
