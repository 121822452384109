import { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import FloatingInputField from "components/Floating-Input-Field";
// import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
// import logo from "assets/img/resaam-logo.svg";
import waveBg from "assets/img/waves-bg.svg";
// import GooglePlacesAutocomplete, {
//   getLatLng,
//   geocodeByPlaceId,
// } from "react-google-places-autocomplete";
import AccountActions from "reducers/account";
import { NavLink } from "react-router-dom";
import MyButton from "components/MyButton";
import { USER_ROLE } from "globals/constants";

class RegisterUrpsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userType: "",
      firstname: "",
      lastname: "",
      // address: { address: "", latitude: null, longitude: null },
      email: "",
      password: "",
    };

    this.onPressRegisterButton = this.onPressRegisterButton.bind(this);
    this.onPropertyChanged = this.onPropertyChanged.bind(this);
  }

  onPropertyChanged = (properiety) => {
    this.setState(Object.assign({}, this.state, { ...properiety }));
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onPressRegisterButton();
    }
  };

  onPressRegisterButton() {
    const { registerRequest, history } = this.props;
    const { firstname, lastname, userType, email, password } = this.state;
    registerRequest(firstname, lastname, userType, email, password, () => {
      history.push("/register-urps-success");
    });
  }

  render() {
    const { action } = this.props.account;
    const { error = "", loading = false } = action;
    const { userType, firstname, lastname, email, password } = this.state;
    const disabled =
      !userType || !firstname || !lastname || !email || !password;

    return (
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "100px",
            "& svg circle": {
              stroke: "#0096AC",
            },
          }),
        }}
      >
        <Container
          fluid
          className="vh-100 vw-100 d-flex flex-column justify-content-center login-page"
        >
          <Row className="justify-content-center pb-4">
            <Col xs lg={5} sm={8} xl={4} className="pb-4">
              <Card>
                <Card.Body>
                  <div className="px-8">
                    {/* <img className="logo" src={logo} /> */}
                    <h1>Inscription</h1>

                    <div className="d-flex mt-2 mb-4 gap-1">
                      <div>Déjà un compte ?</div>
                      <NavLink to={"/login"}>Connectez-vous maintenant</NavLink>
                    </div>
                    {/* <p>Veuillez remplir tous les champs.</p> */}
                  </div>
                  {error === "" ? null : (
                    <div className="error py-4">
                      <p>{error}</p>
                    </div>
                  )}
                  <Form>
                    <Row className="pb-4">
                      <Col
                        style={{ backgroundColor: "#F0F0F0" }}
                        className="rounded d-flex justify-content-between p-1 col-8"
                      >
                        <MyButton
                          isActive={userType === USER_ROLE.MEDECIN}
                          onClick={() =>
                            this.onPropertyChanged({
                              userType: USER_ROLE.MEDECIN,
                            })
                          }
                        >
                          Médecin
                        </MyButton>

                        <MyButton
                          isActive={userType === USER_ROLE.SECRETARY}
                          onClick={() =>
                            this.onPropertyChanged({
                              userType: USER_ROLE.SECRETARY,
                            })
                          }
                        >
                          Secrétaire
                        </MyButton>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6} className="pb-4">
                        <FloatingInputField
                          type="text"
                          label={"Nom"}
                          onChange={(e) =>
                            this.onPropertyChanged({ lastname: e.target.value })
                          }
                          onKeyPress={this.handleKeyPress}
                          value={lastname}
                        />
                      </Col>
                      <Col sm={6} className="pb-4">
                        <FloatingInputField
                          type="text"
                          label={"Prénom"}
                          onChange={(e) =>
                            this.onPropertyChanged({
                              firstname: e.target.value,
                            })
                          }
                          onKeyPress={this.handleKeyPress}
                          value={firstname}
                        />
                      </Col>
                    </Row>
                    {/* <Row className="pb-4">
                      <Col>
                        <Form.Group
                          className={`address-autocomplete-floating ${address.address && "valid"
                            }`}
                        >
                          <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_GMAPS_KEY}
                            selectProps={{
                              inputValue: address["address"] || "",
                              onInputChange: (text) =>
                                this.setState({ address: { address: text } }),
                              onChange: ({ label, value }) => {
                                const { place_id, description } = value;
                                geocodeByPlaceId(place_id)
                                  .then((results) => getLatLng(results[0]))
                                  .then(({ lat, lng }) => {
                                    this.setState({
                                      address: {
                                        address: label,
                                        latitude: lat,
                                        longitude: lng,
                                      },
                                    });
                                  });
                              },
                              blurInputOnSelect: true,
                              components: {
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              },
                              noOptionsMessage: () => null,
                              placeholder: () => null,
                              className: "address-autocomplete-container",
                              classNamePrefix: "address-autocomplete",
                              styles: {
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  border: "none",
                                }),
                              },
                            }}
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["fr"],
                              },
                            }}
                          />
                          <Form.Label>Lieu d'exercice</Form.Label>
                        </Form.Group>
                      </Col>
                    </Row> */}
                    <Row className="pb-4">
                      <Col>
                        <FloatingInputField
                          type="email"
                          label={"Email"}
                          onChange={(e) =>
                            this.onPropertyChanged({ email: e.target.value })
                          }
                          onKeyPress={this.handleKeyPress}
                          value={email}
                        />
                      </Col>
                    </Row>
                    <Row className="pb-4">
                      <Col>
                        <FloatingInputField
                          type="password"
                          label={"Mot de passe"}
                          autoComplete="off"
                          onChange={(e) =>
                            this.onPropertyChanged({ password: e.target.value })
                          }
                          onKeyPress={this.handleKeyPress}
                          value={password}
                        />
                      </Col>
                    </Row>

                    <div className="row">
                      <Button
                        variant="login"
                        type="submit"
                        className="col-6"
                        disabled={disabled}
                        onClick={this.onPressRegisterButton}
                      >
                        &nbsp; S'inscrire &nbsp;
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <div className="footer">
            <img src={waveBg} />
          </div>
        </Container>
      </LoadingOverlay>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    registerRequest: (...args) =>
      dispatch(AccountActions.registerRequest(...args)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUrpsPage);
