import { put, takeLatest } from "redux-saga/effects";
import ServiceFeedbackActions, {
  ServiceFeedbackTypes,
} from "reducers/ride/send-feedback";
import _ from "lodash";
import errorHandler from "sagas/error-handler";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};
function* serviceFeedBackAsyncRequest({
  fullname,
  email,
  subject,
  message,
  callback,
}) {
  const query = `

    mutation{
      ServiceCreateFeedback(
      fullname: "${fullname}",
      email: "${email}",
      subject : "${subject}",
      message : "${message}"
    )}
    
  `;

  try {
    yield graphQLClient().request(query);

    yield put(ServiceFeedbackActions.serviceFeedbackRequestSuccess());

    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      ServiceFeedbackActions.serviceFeedbackRequestFailure
    );
    if (_.isFunction(callback)) callback();
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(
    ServiceFeedbackTypes.SERVICE_FEEDBACK_REQUEST,
    serviceFeedBackAsyncRequest
  ),
];
