import React, { Component } from "react";
import { connect } from "react-redux";
import AccountActions from "reducers/account";
import RequestView from "./Request";
import SuccessView from "./Success";
import ResetView from "./Reset";
import resaamIcon from "assets/img/resaam-logo.svg";
import { Container } from "react-bootstrap";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(this.props.location.search);
    const token = params.get("token");

    this.onPressForgotPasswordButton =
      this.onPressForgotPasswordButton.bind(this);
    this.onPressResetPasswordButton =
      this.onPressResetPasswordButton.bind(this);

    this.state = {
      isSuccessRequestOrReset: false,
      isRequest: false,
      tokenResetPassword: token,
    };
  }

  onPressForgotPasswordButton(email) {
    this.props.forgotPasswordRequest(email, () => {
      this.setState(
        Object.assign({}, this.state, {
          isSuccessRequestOrReset: true,
          isRequest: true,
        })
      );
    });
  }

  onPressResetPasswordButton(password) {
    this.props.resetPasswordRequest(
      this.state.tokenResetPassword,
      password,
      () => {
        this.setState(
          Object.assign({}, this.state, {
            isSuccessRequestOrReset: true,
          })
        );
      }
    );
  }

  render() {
    const { t, i18n } = this.props;
    const { isSuccessRequestOrReset, tokenResetPassword, isRequest } =
      this.state;

    return (
      <Container
        fluid
        className="vh-100 d-flex flex-column justify-content-center align-items-center login-page"
      >
        <div
          style={{
            position: "absolute",
            top: 10,
            left: 10,
          }}
        >
          <img className="logo resaam" width="180px" src={resaamIcon} />
        </div>

        <div className="align-items-center justify-content-center">
          {!isSuccessRequestOrReset && !tokenResetPassword && (
            <RequestView
              {...{ t, i18n }}
              onPressForgotPasswordButton={this.onPressForgotPasswordButton}
            />
          )}

          {!isSuccessRequestOrReset && tokenResetPassword && (
            <ResetView
              {...{ t, i18n }}
              onPressResetPasswordButton={this.onPressResetPasswordButton}
            />
          )}

          {isSuccessRequestOrReset && (
            <SuccessView {...{ t, i18n }} isRequest={isRequest} />
          )}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPasswordRequest: (...args) =>
      dispatch(AccountActions.forgotPasswordRequest(...args)),
    resetPasswordRequest: (...args) =>
      dispatch(AccountActions.resetPasswordRequest(...args)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
