import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  etablishmentListResetState: null,
  etablishmentListRequest: ["page", "limit", "filtered"],
  etablishmentListRequestSuccess: ["list"],
  etablishmentListRequestFailure: ["error"],

  deleteEtablishmentRequest: ["etablishment"],
  deleteEtablishmentRequestSuccess: null,
  deleteEtablishmentRequestFailure: ["error"],

  createNewEtablishmentRequest: ["etablishment", "callback"],
  createNewEtablishmentRequestSuccess: null,
  createNewEtablishmentRequestFailure: ["error"],
});

export const etablishmentTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  list: {
    page: 1,
    limit: 5,
    totalPages: 0,
    etablishments: [],
  },
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

/// GENERAL
export const etablishmentListResetState = (state, action) => {
  return INITIAL_STATE;
};

export const etablishmentListRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const etablishmentListRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const etablishmentListRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["list"], action.list);
};

export const deleteEtablishmentRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const deleteEtablishmentRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const deleteEtablishmentRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const createNewEtablishmentRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const createNewEtablishmentRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const createNewEtablishmentRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.ETABLISHMENT_LIST_RESET_STATE]: etablishmentListResetState,
  [Types.ETABLISHMENT_LIST_REQUEST]: etablishmentListRequest,
  [Types.ETABLISHMENT_LIST_REQUEST_SUCCESS]: etablishmentListRequestSuccess,
  [Types.ETABLISHMENT_LIST_REQUEST_FAILURE]: etablishmentListRequestFailure,

  [Types.DELETE_ETABLISHMENT_REQUEST]: deleteEtablishmentRequest,
  [Types.DELETE_ETABLISHMENT_REQUEST_SUCCESS]: deleteEtablishmentRequestSuccess,
  [Types.DELETE_ETABLISHMENT_REQUEST_FAILURE]: deleteEtablishmentRequestFailure,

  [Types.CREATE_NEW_ETABLISHMENT_REQUEST]: createNewEtablishmentRequest,
  [Types.CREATE_NEW_ETABLISHMENT_REQUEST_SUCCESS]:
    createNewEtablishmentRequestSuccess,
  [Types.CREATE_NEW_ETABLISHMENT_REQUEST_FAILURE]:
    createNewEtablishmentRequestFailure,
});
