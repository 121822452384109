import { Component } from "react";
import _ from "lodash";
import moment from "moment";
import trashIcon from "assets/img/trash.svg";

function NotSpecified() {
  return <div style={{ fontSize: 12, fontStyle: "italic" }}>Non spécifié</div>;
}

const getStartOrEndFormated = (date) => {
  const day = moment(new Date());
  day.set({
    hour: date / 60,
    minute: date % 60,
  });
  return day.format("HH[h]mm");
};

class SerieCard extends Component {
  constructor(props) {
    super(props);

    this.onCancelSerieAction = this.onCancelSerieAction.bind(this);
    this.state = {
      showMore: false,
    };
  }

  onCancelSerieAction() {
    const { onCancelSerie, serie } = this.props;
    if (_.isFunction(onCancelSerie)) onCancelSerie(serie);
  }

  render() {
    const { t, i18n, serie = {} } = this.props;
    const {
      patient = { firstname: "", lastname: "", nss: "" },
      createdAt = "",
      status = "",
      pickup = { address: "Paris" },
      dropoff = { address: "Marseille" },
      start = 900,
      end = 960,
      dates = [],
      nbDates,
      datetime,
      returnDatetime,
      transporter,
      serieType,
      subject,
      taxi,
    } = serie;

    const showStartAndEnd = start !== null;
    const startFormated = getStartOrEndFormated(start);
    const endFormated = getStartOrEndFormated(end);

    const patientLname = _.capitalize(patient.lastname);
    const patientFname = _.capitalize(patient.firstname);

    const numberOfRides = dates.length > 1 ? dates.length : nbDates * 2;
    let transporterInfo = " ";
    let make = "";
    let model = "";
    let plate = "";
    let color = "";
    let phone = "";
    let currentStatus = status;

    if (status != "incoming") {
      let obj = null;
      if (serieType === "taxiSerie") {
        transporterInfo = `${_.get(taxi, "driver.lastname", "")} ${_.get(
          taxi,
          "driver.firstname",
          ""
        )}`;
        obj = taxi;
      } else {
        obj = transporter;
        transporterInfo = _.get(transporter, "company.name", "");
      }

      make = _.get(obj, "vehicle.make", "");
      model = _.get(obj, "vehicle.model", "");
      plate = _.get(obj, "vehicle.plate", "");
      color = _.get(obj, "vehicle.color", "");
      phone = _.get(obj, "vehicle.phone", "");
      if (status == "pending" && _.isEmpty(make)) {
        currentStatus = "accepted";
      }
    }
    transporterInfo = _.capitalize(transporterInfo);
    // if (serieType === "taxiSerie") {
    //   transporterInfo = `${_.get(taxi, "driver.lastname", "")} ${_.get(taxi, "driver.firstname", "")}`;
    // } else {
    //   transporterInfo = _.get(transporter, "company.name", "");
    // }
    // transporterInfo = _.capitalize(transporterInfo);

    return (
      <>
        <div
          className="d-flex d-none d-lg-flex"
          style={{
            border: "1px solid #999C9F",
            borderRadius: 4,
            padding: "5px 30px",
            // width: "986px",
            // height: "120px",
          }}
        >
          {/* <div className="ongoing-serie-row d-flex flex-row"> */}

          {/* COL 1 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100px",
              marginRight: "40px",
            }}
          >
            <div style={{ fontSize: 16, fontWeight: 900 }}>{12345}</div>
            <div style={{ fontSize: 12, fontWeight: 500, marginTop: "16px" }}>
              {moment(datetime).format("DD MMM HH:mm")}
            </div>
          </div>

          {/* COL 2 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "140px",
              borderRight: "1px solid #999C9F",
              marginRight: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ fontSize: "12px", fontWeight: 500, color: "#999C9F" }}
              >
                Prise en charge
              </div>
              {pickup.address ? (
                <span
                  style={{ fontSize: "12px", fontWeight: 500 }}
                  className="text-truncate-2"
                >
                  {pickup.address || ""}
                </span>
              ) : (
                <NotSpecified />
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ fontSize: "12px", fontWeight: 500, color: "#999C9F" }}
              >
                Dépose
              </div>
              {dropoff.address ? (
                <span
                  style={{ fontSize: "12px", fontWeight: 500 }}
                  className="text-truncate-2"
                >
                  {dropoff.address || ""}
                </span>
              ) : (
                <NotSpecified />
              )}
            </div>
          </div>

          {/* COL 3 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "264px",
              borderRight: "1px solid #999C9F",
              marginRight: "8px",
            }}
          >
            <div
              style={{ fontSize: "16px", fontWeight: 900 }}
            >{`${patientFname} ${patientLname}`}</div>
            <div
              style={{ fontSize: "12px", fontWeight: 500, color: "#999C9F" }}
            >
              Motif
            </div>
            {subject ? (
              <div style={{ fontSize: "12px", fontWeight: 900 }}>{subject}</div>
            ) : (
              <NotSpecified />
            )}
            <div
              style={{ fontSize: "12px", fontWeight: 500, color: "#999C9F" }}
            >
              Numéro
            </div>
            {patient?.phone ? (
              <div style={{ fontSize: "12px", fontWeight: 900 }}>
                {patient?.phone}
              </div>
            ) : (
              <NotSpecified />
            )}
          </div>

          {/* COL4 */}
          <div
            style={{
              width: "132px",
              display: "flex",
              flexDirection: "column",
              gap: "6px",
            }}
          >
            <div
              style={{
                color: "#999C9F",
                fontSize: "12px",
                fontWeight: 500,
                marginBottom: "2px",
              }}
            >
              Véhicule
            </div>
            {["incoming", "unavailable"].includes(status) && <NotSpecified />}
            {status == "pending" && _.isEmpty(make) && (
              <div style={{ fontSize: "12px", fontWeight: 900 }}>
                En attente d'attribution
              </div>
            )}
            {["onway", "waiting", "onboard", "pending"].includes(
              currentStatus
            ) && (
              <>
                <div style={{ fontSize: "12px", fontWeight: 900 }}>
                  {transporterInfo}
                </div>
                <div style={{ fontSize: "12px", fontWeight: 700 }}>{plate}</div>
                <div style={{ fontSize: "12px", fontWeight: 500 }}>{phone}</div>
              </>
            )}
          </div>

          {/* COL6 */}
          <div style={{ display: "flex", width: "181px", marginLeft: "40px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "100%",
                alignItems: "start",
              }}
            >
              <span className={`status ${currentStatus} text-center`}>
                {t(`Common:Ride.Status.${currentStatus}`, {
                  lng: i18n.language,
                })}
              </span>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <div className="badge-serie">Série x{numberOfRides}</div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <img
                  src={trashIcon}
                  alt="remove ride"
                  style={{ width: "32px", cursor: "pointer" }}
                  onClick={this.onCancelSerieAction}
                />
              </div>
            </div>
          </div>

          {/* <div className="cell mt-8">
            <h2 className={`name`}>{`${patientLname}`}</h2>
            <h2 className={`name`}>{`${patientFname}`}</h2>

            <span className="ssn">{patient.nss}</span>
          </div>

          <div className="d-flex flex-column py-1 justify-content-evenly">
            {showStartAndEnd ? (
              <>
                <h4 className="name">{startFormated}</h4>
                <h4 className="name">{endFormated}</h4>
              </>
            ) : (
              <p className="name">{t(`Private:onGoingSeriesPage.not_completed`, { lng: i18n.language })}</p>
            )}
          </div>

          <div className="x-small">
            <span className="datetime">{`${moment(returnDatetime ? returnDatetime : createdAt).format(
              "ll"
            )}`}</span>
            <span className="datetime">{`${moment(returnDatetime ? returnDatetime : createdAt).format(
              "LT"
            )}`}</span>
          </div>

          <div className="cell d-flex flex-row">
            <img src={pickupDropoff} style={{ marginRight: 5 }} />
            <div className="d-flex flex-column py-1 justify-content-between">
              <span className="company-address text-truncate">
                {pickup.address || ""}
              </span>
              <span className="company-address text-truncate">
                {dropoff.address || ""}
              </span>
            </div>
          </div>

          <div className="small d-flex flex-column justify-content-between text-center">
            <span className="d-block transporter-name pl-4 pb-2">
              {transporterInfo}{" "}
            </span>
            <span className={`d-block  status ${status} text-center`}>
              {t(`Common:Ride.Status.${status}`, { lng: i18n.language })}
            </span>
          </div>

          
          <div
            className="x-small d-flex flex-column justify-content-end align-items-end"
            style={{ height: "100%", paddingTop: 20, paddingBottom: 20 }}
          >
            Edit Button 
            <ImageButton size="sm" round src={editRideIcon} /> 
            Cancel Button 
            <ImageButton
              size="sm"
              round
              src={cancelRideIcon}
              onClick={this.onCancelSerieAction}
            />
          </div> */}
        </div>

        {/* SMARTPHONE */}
        <div
          className="d-flex d-lg-none w-100"
          style={{
            flexDirection: "column",
            border: "1px solid #999C9F",
            borderRadius: 4,
            padding: "5px 15px",
          }}
          onClick={() => this.setState({ showMore: !this.state.showMore })}
        >
          <div className="d-flex">
            {/* COL 1 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "10px",
              }}
            >
              <div style={{ fontSize: 16, fontWeight: 900 }}>{12345}</div>
              <div style={{ fontSize: 12, fontWeight: 500, marginTop: "16px" }}>
                {moment(datetime).format("DD MMM HH:mm")}
              </div>
            </div>

            {/* PATIENT */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "132px",
                borderRight: "1px solid #999C9F",
                marginRight: "8px",
              }}
            >
              <div
                style={{ fontSize: "14px", fontWeight: 900 }}
              >{`${patientFname} ${patientLname}`}</div>
              <div
                style={{ fontSize: "11px", fontWeight: 500, color: "#999C9F" }}
              >
                Motif
              </div>
              {subject ? (
                <div style={{ fontSize: "12px", fontWeight: 900 }}>
                  {subject}
                </div>
              ) : (
                <NotSpecified />
              )}
              <div
                style={{ fontSize: "11px", fontWeight: 500, color: "#999C9F" }}
              >
                Numéro
              </div>
              {patient?.phone ? (
                <div style={{ fontSize: "12px", fontWeight: 900 }}>
                  {patient?.phone}
                </div>
              ) : (
                <NotSpecified />
              )}
            </div>

            {/* STATUS */}
            <div style={{ display: "flex", marginLeft: "5px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  width: "100%",
                  alignItems: "start",
                }}
              >
                <span
                  className={`status-serie ${currentStatus} text-center small`}
                >
                  <div className={`status-point status-${status} small`} />
                  {t(`Common:Ride.Status.${currentStatus}`, {
                    lng: i18n.language,
                  })}
                </span>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <div className="badge-serie">Série x{numberOfRides}</div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <img
                    src={trashIcon}
                    alt="remove ride"
                    style={{ width: "32px", cursor: "pointer" }}
                    onClick={this.onCancelSerieAction}
                  />
                </div>
              </div>
            </div>
          </div>

          {this.state.showMore && (
            <div className="d-flex mt-2">
              {/* COL 2 */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "140px",
                  borderRight: "1px solid #999C9F",
                  marginRight: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "#999C9F",
                    }}
                  >
                    Prise en charge
                  </div>
                  {pickup.address ? (
                    <span
                      style={{ fontSize: "12px", fontWeight: 500 }}
                      className="text-truncate-2"
                    >
                      {pickup.address || ""}
                    </span>
                  ) : (
                    <NotSpecified />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "#999C9F",
                    }}
                  >
                    Dépose
                  </div>
                  {dropoff.address ? (
                    <span
                      style={{ fontSize: "12px", fontWeight: 500 }}
                      className="text-truncate-2"
                    >
                      {dropoff.address || ""}
                    </span>
                  ) : (
                    <NotSpecified />
                  )}
                </div>
              </div>

              {/* COL4 */}
              <div
                style={{
                  width: "132px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                <div
                  style={{
                    color: "#999C9F",
                    fontSize: "12px",
                    fontWeight: 500,
                    marginBottom: "2px",
                  }}
                >
                  Véhicule
                </div>
                {["incoming", "unavailable"].includes(status) && (
                  <NotSpecified />
                )}
                {status == "pending" && _.isEmpty(make) && (
                  <div style={{ fontSize: "12px", fontWeight: 900 }}>
                    En attente d'attribution
                  </div>
                )}
                {["onway", "waiting", "onboard", "pending"].includes(
                  currentStatus
                ) && (
                  <>
                    <div style={{ fontSize: "12px", fontWeight: 900 }}>
                      {transporterInfo}
                    </div>
                    <div style={{ fontSize: "12px", fontWeight: 700 }}>
                      {plate}
                    </div>
                    <div style={{ fontSize: "12px", fontWeight: 500 }}>
                      {phone}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default SerieCard;
