import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import FloatingInput from "components/Floating-Input-Field";
import { Button } from "react-bootstrap";

class RequestView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: process.env.NODE_ENV === "development" ? "admin@service.com" : "",
    };

    this.onSubmitRequest = this.onSubmitRequest.bind(this);
    this.onPropertyChanged = this.onPropertyChanged.bind(this);
  }

  // componentWillUnmount() {
  //   this.setState({ email: "" });
  // }

  onPropertyChanged = (properiety) => {
    this.setState(Object.assign({}, this.state, { ...properiety }));
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onSubmitRequest();
    }
  };

  onSubmitRequest() {
    const { onPressForgotPasswordButton } = this.props;
    onPressForgotPasswordButton(this.state.email);
  }

  render() {
    const { t, i18n } = this.props;
    const { email } = this.state;

    return (
      <div className="d-flex flex-column gap-4" style={{ maxWidth: "700px" }}>
        <div className="text-center px-8">
          <h1>
            {t("ForgotPasswordPage.request.head_title", { lng: i18n.language })}
          </h1>
          <p>
            {t("ForgotPasswordPage.request.description", {
              lng: i18n.language,
            })}
          </p>
        </div>
        <FloatingInput
          inputType="email"
          label={t("ForgotPasswordPage.request.email_label", {
            lng: i18n.language,
          })}
          onChange={(e) => this.onPropertyChanged({ email: e.target.value })}
          onKeyPress={this.handleKeyPress}
          value={email}
        />

        <div className="d-flex justify-content-between w-100 mt-2">
          <NavLink to={"/login"} className="nav-link">
            {t("ForgotPasswordPage.request.login_link", { lng: i18n.language })}
          </NavLink>

          <Button variant="login" type="submit" onClick={this.onSubmitRequest}>
            {t("ForgotPasswordPage.request.button_label", {
              lng: i18n.language,
            })}
          </Button>
        </div>
      </div>
    );
  }
}

export default RequestView;
