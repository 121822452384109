import { put, takeLatest } from "redux-saga/effects";
import OngoingSeriesActions, {
  OngoingSerieTypes,
} from "reducers/serie/ongoing-series";
import errorHandler from "sagas/error-handler";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* onGoingSeriesAsyncRequest({ page, limit, filter = false }) {
  let filterQuery = null;
  if (filter) {
    filterQuery = `
      {
        dates: [${filter.dates}],
        text: "${filter.text}"
      }
    `;
  }
  const query = `
    query {
      ServiceOngoingSeries(page:${page}, limit:${limit}, filter:${filterQuery}) {
        page
        limit
        totalPages 
        totalDocs
        series {
          _id
          status
          vehicleCategory
          vehicleOptions
          dates
          start
          end
          nbDates
          returnDatetime
          createdAt
          pickup {
            address
          }
          dropoff {
            address
          }
          transporter{ company { name } }
          taxi{driver {firstname, lastname} vehicle{plate make model color}}
          patient {
            firstname
            lastname
            phone
            email
            nss
            status
          }
          service {
            company {
              name
            }
          }
        }
      }
    }
  `;
  try {
    const data = yield graphQLClient().request(query);
    const series = data.ServiceOngoingSeries;

    yield put(OngoingSeriesActions.onGoingSeriesRequestSuccess(series));
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(
      message,
      OngoingSeriesActions.onGoingSeriesRequestFailure
    );
  }
}

//______________________ Company Service __________________
function* cancelSerieAsyncRequest({ serieId, callback }) {
  //
  const query = `
    mutation { 
      ServiceCancelSerie(serieId:"${serieId}") {
        _id
        status
        vehicleCategory
        vehicleOptions
        dates
        start
        end
        nbDates
        returnDatetime
        createdAt
        pickup {
          address
        }
        dropoff {
          address
        }
        patient {
          firstname
          lastname
          phone
          email
          nss
          status
        }
        service {
          company {
            name
          }
        }
      }
    }
   `;

  try {
    const data = yield graphQLClient().request(query);
    yield put(
      OngoingSeriesActions.cancelSerieRequestSuccess(data.ServiceCancelSerie)
    );
    yield put(OngoingSeriesActions.onGoingSeriesRequest(1, 10));
    if (_.isFunction(callback)) callback({});
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    if (_.isFunction(callback)) callback({ error: message });

    yield errorHandler(message, OngoingSeriesActions.cancelSerieRequestFailure);
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(
    OngoingSerieTypes.ON_GOING_SERIES_REQUEST,
    onGoingSeriesAsyncRequest
  ),
  takeLatest(OngoingSerieTypes.CANCEL_SERIE_REQUEST, cancelSerieAsyncRequest),
];
