import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";

import FloatingInputField from "components/Floating-Input-Field";
import logo from "assets/img/resaam-logo.svg";
import waveBg from "assets/img/waves-bg.svg";
import AccountActions from "reducers/account";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { getParamsOpenIdFromUrlAndLocalStorage } from "functions";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

function LoginOpenIdPage(props) {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);
  const { t } = useTranslation("Public");
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading } = useSelector((state) => state.account.actionOpenId);

  useEffect(() => {
    const params = getParamsOpenIdFromUrlAndLocalStorage(location?.search);
    if (params) {
      const { code, nonce } = params;
      dispatch(
        AccountActions.signinOpenIdRequest(
          "psc",
          code,
          nonce,
          (err, userRegister) => {
            if (err) setError(err);
            else if (userRegister) setUser(userRegister);
          }
        )
      );
    }
    // if (!code || !state) window.location.href = "/login";
    // if(state !== localState) return;
  }, []);

  const onPressRegisterButton = (e) => {
    e.preventDefault();
    if (_.isEmpty(user)) return setError("user_not_found");
    if (_.isEmpty(email)) return setError("email_empty");
    // regex email
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))
      return setError("email_invalid");
    // regex phone start with 0 and 10 numbers
    if (!/^[0][0-9]{9}$/.test(phone)) return setError("phone_invalid");

    dispatch(
      AccountActions.registerOpenIdRequest({ ...user, email, phone }, (err) => {
        if (err) setError(err);
      })
    );
  };

  if (loading && _.isEmpty(email))
    return (
      <div className="login-page">
        <div>Chargement</div>
      </div>
    );

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        spinner: (base) => ({
          ...base,
          width: "100px",
          "& svg circle": {
            stroke: "#0096AC",
          },
        }),
      }}
    >
      <Container
        fluid
        className="vh-100 vw-100 d-flex flex-column justify-content-center login-page"
      >
        <Row className="justify-content-center pb-4">
          <Col xs lg={5} sm={8} xl={4} className="pb-4">
            <Card>
              <Card.Body>
                <div className="text-center px-8">
                  <img className="logo" src={logo} />
                  <h1>Inscription</h1>

                  <p>
                    Veuillez renseigner votre email pour finaliser votre
                    inscription
                  </p>
                </div>
                <div className="error py-1">
                  {!error ? null : <p>{t(`LoginOpenId.errors.${error}`)}</p>}
                </div>
                <Form onSubmit={onPressRegisterButton}>
                  <Row className="pb-4">
                    <Col>
                      <FloatingInputField
                        type="email"
                        label={"Email"}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </Col>
                  </Row>
                  <Row className="pb-4">
                    <Col>
                      <FloatingInputField
                        type="text"
                        label={"Téléphone"}
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                      />
                    </Col>
                  </Row>

                  <NavLink to={"/login"} className="nav-link">
                    Revenir à la page de connexion
                  </NavLink>

                  <div className="row flex-row-reverse mt-1">
                    <Button
                      variant="login"
                      type="submit"
                      className="col-6"
                      onClick={onPressRegisterButton}
                    >
                      &nbsp; S'inscrire &nbsp;
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <div className="footer">
          <img src={waveBg} />
        </div>
      </Container>
    </LoadingOverlay>
  );
}

export default LoginOpenIdPage;
