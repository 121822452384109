import React, { Component } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
//import { List, AutoSizer, InfiniteLoader } from "react-virtualized";
import InfiniteScroll from "react-infinite-scroll-component";

import OngoingRideRow from "components/Ongoing-Ride-Row";
import { connect } from "react-redux";
import OngoingRidesActions from "reducers/ride/ongoing-rides";
import TestRideActions from "reducers/ride/test";
import RideWaitingTimeActions from "reducers/ride/driver-location";
import MinioActions from "reducers/minio";
import { withTranslation } from "react-i18next";
import CancelRideConfirmationDialog from "./Cancel-Ride-Confirmation-Dialog";
import _ from "lodash";
import searchInputIcon from "assets/img/search-input-icon.svg";
import noDataIcon from "assets/img/no_data.svg";
import DriverLocationModalDialog from "./driver-location";
import Calendar from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import datepickerLocal from "globals/local-dates";
import ManageFilesModal from "components/Modal/ManageFiles";

const FETCH_RIDES_TIME = 10_000;

class OngoingRide extends Component {
  constructor() {
    super();
    this.loadMore = this.loadMore.bind(this);
    this.onCancelCancelation = this.onCancelCancelation.bind(this);
    this.refresh = this.refresh.bind(this);

    this.state = {
      onConfirmCancelation: null,
      showManageFilesModal: false,
      width: 0,
      displayCancelationConfirmDialog: false,
      startIndexListItemRendred: 0,
      stopIndexListItemRendred: 0,
      showDriverLocation: false,
      selectedRideToShowDriverLocation: 0,
      searchInput: "",
      searchDates: [],
    };
  }

  onCancelCancelation() {
    this.setState(
      Object.assign({}, this.state, {
        displayCancelationConfirmDialog: false,
        onConfirmCancelation: null,
        showManageFilesModal: false,
      })
    );
  }

  loadMore() {
    const { onGoingRidesRequest } = this.props;
    const { page, limit, totalDocs, rides } = this.props.rides;
    if (rides.length < totalDocs)
      onGoingRidesRequest(page + 1, limit, {
        text: this.state.searchInput,
        dates: this.state.searchDates,
      });
  }

  isRowLoaded = ({ index }) => {
    const { rides } = this.props.rides;
    return !!rides[index];
  };

  rowRenderer = ({ index, key, style }) => {
    const { t, i18n, setPatientStatusRequest, coords } = this.props;

    const { top, position, height, left } = style;
    const { rides } = this.props.rides;
    const r = rides[index];

    if (!this.isRowLoaded({ index })) {
      return null;
      /*(<div key={key}
                style={{
                    top: top,
                    position, left,
                    height: height, width:
                        this.state.width - 25
                }} className="d-flex">
                chargement ....
            </div>); */
    }

    return (
      <div
        key={key}
        className="d-flex"
        style={{
          marginBottom: "8px",
        }}
      >
        <OngoingRideRow
          onShowMap={({ _id }) => {
            this.setState(
              Object.assign({}, this.state, {
                showDriverLocation: true,
                selectedRideToShowDriverLocation: _id,
              })
            );
            this.getCurrentDriverLocationForSelectedRide(_id);
          }}
          ride={rides[index]}
          {...{ t, i18n }}
          {...{ setPatientStatusRequest }}
          onCancelRide={(ride) => {
            const callBack = () => {
              const { cancelRideRequest } = this.props;

              cancelRideRequest(ride._id, () => {
                this.setState(
                  Object.assign({}, this.state, {
                    displayCancelationConfirmDialog: false,
                    onConfirmCancelation: null,
                  })
                );
              });
            };

            this.setState(
              Object.assign({}, this.state, {
                displayCancelationConfirmDialog: true,
                onConfirmCancelation: callBack,
              })
            );
          }}
        />
      </div>
    );
  };

  onRowsRendered(params) {
    const { startIndex, stopIndex } = params;
    const { startIndexListItemRendred, stopIndexListItemRendred } = this.state;
    if (
      startIndex != startIndexListItemRendred ||
      stopIndex != stopIndexListItemRendred
    ) {
      this.setState(
        Object.assign({}, this.state, {
          startIndexListItemRendred: startIndex,
          stopIndexListItemRendred: stopIndex,
        })
      );
    }
  }

  componentDidMount() {
    const { onGoingRidesRequest } = this.props;

    onGoingRidesRequest(1, 100);

    this.rideIntervalId = setInterval(() => {
      const { searchInput, searchDates } = this.state;

      onGoingRidesRequest(1, 100, {
        text: searchInput,
        dates: searchDates.length !== 0 ? `"${searchDates.join('","')}"` : "",
      });
    }, [FETCH_RIDES_TIME]);

    this.driverLocationIntervalId = setInterval(() => {
      const { showDriverLocation, selectedRideToShowDriverLocation } =
        this.state;

      if (!showDriverLocation) return;

      this.getCurrentDriverLocationForSelectedRide(
        selectedRideToShowDriverLocation
      );
    }, 30 * 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchDates, searchInput } = this.state;
    if (
      prevState.searchDates !== searchDates ||
      prevState.searchInput !== searchInput
    ) {
      this.props.onGoingRidesRequest(1, 10, {
        text: searchInput,
        dates: searchDates.length !== 0 ? `"${searchDates.join('","')}"` : "",
      });
    }
  }

  getCurrentDriverLocationForSelectedRide(rideId) {
    const { serviceGetRideDriverLocationRequest } = this.props;
    serviceGetRideDriverLocationRequest(rideId);
  }

  componentWillUnmount() {
    clearInterval(this.rideIntervalId);
    clearInterval(this.driverLocationIntervalId);
  }

  refresh() {}
  render() {
    const { t, i18n, coords, setPatientStatusRequest } = this.props;
    const { limit, totalDocs = 0, rides } = this.props.rides;
    const _coords = _.filter(
      coords,
      (el) => el.rideId == this.state.selectedRideToShowDriverLocation
    );

    const selectedRide = _.find(
      rides,
      (el) => el._id == this.state.selectedRideToShowDriverLocation
    );

    return (
      <Container fluid className="h-100 d-flex flex-column">
        <Row className="mt-4 px-2 d-flex justify-content-between">
          <Col>
            <h1>
              {t("onGoingRidesPage.on_going_rides_headline_title", {
                lng: i18n.language,
              })}
            </h1>
          </Col>
        </Row>

        <Row className="mt-4 px-2">
          <Col sm="4">
            <Form className="ongoing-ride-search w-40">
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="N° réservation, patient, date, destination, prestataire"
                  // ajouter la variable search
                  onChange={(e) => {
                    const { value } = e.target;
                    this.setState(
                      Object.assign({}, this.state, {
                        searchInput: value,
                      })
                    );
                  }}
                  value={this.state.searchInput}
                />
                <div className="search-icon">
                  <img src={searchInputIcon} />
                </div>
              </InputGroup>
            </Form>
          </Col>
          <Col className="sm-4"></Col>
          <Col className="sm-4 d-flex justify-content-end align-items-center">
            <Calendar
              className="container-input-calendar"
              multiple={true}
              value={this.state.searchDates}
              weekStartDayIndex={1}
              disableDayPicker={false}
              showOtherDays={false}
              calendarPosition="Left Top"
              render={<InputIcon />}
              locale={datepickerLocal}
              shadow={false}
              onChange={(selectedDates) => {
                this.setState({ searchDates: selectedDates });
              }}
            />
          </Col>
        </Row>

        <Row className="flex-grow-1">
          <Col>
            {totalDocs == 0 && (
              <div className="no-data">
                <img src={noDataIcon} />
                <p className="text-no-data">
                  {t("onGoingRidesPage.message_when_no_data", {
                    lng: i18n.language,
                  })}
                </p>
              </div>
            )}

            {totalDocs > 0 && (
              <InfiniteScroll
                dataLength={totalDocs} //This is important field to render the next data
                next={this.loadMore}
                // below props only if you need pull down functionality
                refreshFunction={this.refresh}
                hasMore={false}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    {/* <b>Vous avez tout vu</b> */}
                  </p>
                }
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={
                  <h3 style={{ textAlign: "center" }}>
                    &#8595; Dérouler pour rafraîchir
                  </h3>
                }
                releaseToRefreshContent={
                  <h3 style={{ textAlign: "center" }}>
                    &#8593; Relâcher pour rafraîchir
                  </h3>
                }
              >
                {rides.map((ride, index) => (
                  <div
                    key={index}
                    className="d-flex"
                    style={{
                      marginBottom: "8px",
                    }}
                  >
                    <OngoingRideRow
                      onShowMap={({ _id }) => {
                        this.setState(
                          Object.assign({}, this.state, {
                            showDriverLocation: true,
                            selectedRideToShowDriverLocation: _id,
                          })
                        );
                        this.getCurrentDriverLocationForSelectedRide(_id);
                      }}
                      onShowManageFiles={(showManageFilesModal) =>
                        this.setState(
                          Object.assign({}, this.state, {
                            showManageFilesModal,
                          })
                        )
                      }
                      ride={ride}
                      {...{ t, i18n }}
                      {...{ setPatientStatusRequest }}
                      onCancelRide={(ride) => {
                        const callBack = () => {
                          const { cancelRideRequest } = this.props;

                          cancelRideRequest(ride._id, () => {
                            this.setState(
                              Object.assign({}, this.state, {
                                displayCancelationConfirmDialog: false,
                                onConfirmCancelation: null,
                              })
                            );
                          });
                        };

                        this.setState(
                          Object.assign({}, this.state, {
                            displayCancelationConfirmDialog: true,
                            onConfirmCancelation: callBack,
                          })
                        );
                      }}
                      onRefreshDispatchRide={
                        this.props.onRefreshDispatchRideRequest
                      }
                      changeRideStatusRequest={
                        this.props.changeRideStatusRequest
                      }
                    />
                  </div>
                ))}
              </InfiniteScroll>
            )}
          </Col>
        </Row>

        <CancelRideConfirmationDialog
          show={this.state.displayCancelationConfirmDialog}
          {...{ t, i18n }}
          onConfirm={this.state.onConfirmCancelation}
          onCancel={this.onCancelCancelation}
        />
        <DriverLocationModalDialog
          show={this.state.showDriverLocation}
          coordinates={_.isEmpty(_coords) ? {} : _coords[0]}
          ride={selectedRide}
          onHide={() => {
            this.setState(
              Object.assign({}, this.state, { showDriverLocation: false })
            );
          }}
        />
        <ManageFilesModal
          // show={this.state.showManageFilesModal !== false}
          show={this.state.showManageFilesModal !== false}
          ride={this.state.showManageFilesModal}
          onCancel={this.onCancelCancelation}
          onAddFiles={this.props.onAddFilesToRideOngoingRequest}
          onDropFiles={this.props.onDropFilesToRideOngoingRequest}
          putPresignedUrl={this.props.putPresignedUrl}
          getPresignedUrl={this.props.getPresignedUrl}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rides: state.ongoingRides.rides.asMutable({ deep: true }),
    action: state.ongoingRides.action.asMutable({ deep: true }),
    coords: state.driverLocation.coords.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    serviceGetRideDriverLocationRequest: (...args) =>
      dispatch(
        RideWaitingTimeActions.serviceGetRideDriverLocationRequest(...args)
      ),
    onGoingRidesRequest: (...args) =>
      dispatch(OngoingRidesActions.onGoingRidesRequest(...args)),
    cancelRideRequest: (...args) =>
      dispatch(OngoingRidesActions.cancelRideRequest(...args)),
    setPatientStatusRequest: (...args) =>
      dispatch(OngoingRidesActions.setPatientStatusRequest(...args)),
    onRefreshDispatchRideRequest: (...args) =>
      dispatch(OngoingRidesActions.onRefreshDispatchRideRequest(...args)),
    onAddFilesToRideOngoingRequest: (...args) =>
      dispatch(OngoingRidesActions.onAddFilesToRideOngoingRequest(...args)),
    onDropFilesToRideOngoingRequest: (...args) =>
      dispatch(OngoingRidesActions.onDropFilesToRideOngoingRequest(...args)),
    putPresignedUrl: (...args) =>
      dispatch(MinioActions.putPresignedUrl(...args)),
    getPresignedUrl: (...args) =>
      dispatch(MinioActions.getPresignedUrl(...args)),
    changeRideStatusRequest: (...args) =>
      dispatch(TestRideActions.changeRideStatusRequest(...args)),
  };
};

const onGoingRidesRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(OngoingRide);
export default withTranslation(["Private", "Common"], { wait: true })(
  onGoingRidesRedux
);
