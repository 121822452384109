import { Component } from "react";
import { NavLink } from "react-router-dom";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import FloatingInputField from "components/Floating-Input-Field";
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import logo from "assets/img/resaam-logo.svg";
import waveBg from "assets/img/waves-bg.svg";
import pscImage from "assets/img/openid/psc.png";
import { authUrlProSanteConnect } from "functions";

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email:
        process.env.NODE_ENV === "development" ? "moulilalala@gmail.com" : "",
      password: process.env.NODE_ENV === "development" ? "admin" : "",
    };

    this.onPressLoginButton = this.onPressLoginButton.bind(this);
    this.onPropertyChanged = this.onPropertyChanged.bind(this);
  }

  onPropertyChanged = (properiety) => {
    this.setState(Object.assign({}, this.state, { ...properiety }));
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.onPressLoginButton();
    }
  };

  onPressLoginButton() {
    const { signinRequest } = this.props;
    const { email, password } = this.state;
    signinRequest(email, password);
  }

  connectProSanteConnect() {
    authUrlProSanteConnect();
  }

  render() {
    const { t, i18n } = this.props;

    const { action } = this.props.account;
    const { error = "", loading = false } = action;

    const { email, password } = this.state;

    return (
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "100px",
            "& svg circle": {
              stroke: "#0096AC",
            },
          }),
        }}
      >
        <Container
          fluid
          className="vh-100 vw-100 d-flex flex-column justify-content-center login-page"
        >
          <Row className="justify-content-center pb-4">
            <Col xs lg={5} sm={8} xl={4} className="pb-4">
              <Card>
                <Card.Body>
                  <div className="text-center px-8">
                    <img className="logo" src={logo} />
                    <h1>{t("LoginPage.head_title", { lng: i18n.language })}</h1>

                    <p>{t("LoginPage.head_text", { lng: i18n.language })}</p>
                  </div>
                  {error === "" ? null : (
                    <div className="error py-4">
                      <p>{error}</p>
                    </div>
                  )}
                  <Form>
                    <Row className="pb-4">
                      <Col>
                        <FloatingInputField
                          type="email"
                          label={t("LoginPage.email_label", {
                            lng: i18n.language,
                          })}
                          onChange={(e) =>
                            this.onPropertyChanged({ email: e.target.value })
                          }
                          onKeyPress={this.handleKeyPress}
                          value={email}
                        />
                      </Col>
                    </Row>
                    <Row className="pt-4">
                      <Col>
                        <FloatingInputField
                          type="password"
                          label={t("LoginPage.password_label", {
                            lng: i18n.language,
                          })}
                          autoComplete="off"
                          onChange={(e) =>
                            this.onPropertyChanged({ password: e.target.value })
                          }
                          onKeyPress={this.handleKeyPress}
                          value={password}
                        />
                      </Col>
                    </Row>

                    <Form.Group controlId="formBasicCheckbox" className="mt-4">
                      <Form.Check
                        type="checkbox"
                        label={t("LoginPage.Keep_signedin_checkbox_label", {
                          lng: i18n.language,
                        })}
                      />
                    </Form.Group>

                    <div className="row mt-4">
                      <Button variant="link"></Button>
                      <NavLink to={"/forgot"} className="col-6">
                        {t("LoginPage.forgot_password_button", {
                          lng: i18n.language,
                        })}
                      </NavLink>
                      <Button
                        variant="login"
                        type="submit"
                        className="col-6"
                        onClick={this.onPressLoginButton}
                      >
                        &nbsp;
                        {t("LoginPage.login_button", { lng: i18n.language })}
                        &nbsp;
                      </Button>
                    </div>
                  </Form>

                  <h4 className="text-divider">
                    <span>OU</span>
                  </h4>

                  <div className="d-flex justify-content-center">
                    <img
                      className="psc-image"
                      src={pscImage}
                      alt="pro sante connect"
                      onClick={this.connectProSanteConnect}
                    />
                  </div>

                  <div className="d-flex justify-content-center mt-2 gap-1">
                    <div>Pas encore de compte ?</div>
                    <NavLink to={"/register"}>
                      Créer un compte dès maintenant
                    </NavLink>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <div className="footer">
            <img src={waveBg} />
          </div>
        </Container>
      </LoadingOverlay>
    );
  }
}

export default withTranslation(["Public", "Common"], { wait: true })(LoginPage);
