import { Component } from "react";
import { Form, InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FloatingInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordHidden: true,
    };
  }

  render() {
    const { label = "Address...", type = "text", value = "" } = this.props;
    return type === "password" ? (
      <Form.Group className="floating-input-field">
        <InputGroup>
          <FormControl
            placeholder="password"
            className="password"
            aria-describedby="password-addon"
            {...this.props}
            type={this.state.passwordHidden ? "password" : "text"}
          />
          <InputGroup.Text
            value={value}
            id="password-addon"
            onClick={() =>
              this.setState(
                Object.assign({}, this.state, {
                  passwordHidden: !this.state.passwordHidden,
                })
              )
            }
          >
            <FontAwesomeIcon
              icon={this.state.passwordHidden ? "eye-slash" : "eye"}
              size="lg"
            />
          </InputGroup.Text>

          <Form.Label>{label}</Form.Label>
        </InputGroup>
      </Form.Group>
    ) : (
      <Form.Group className="floating-input-field">
        <Form.Control type="text" placeholder=" " {...this.props} />
        <Form.Label>{label}</Form.Label>
      </Form.Group>
    );
  }
}

export default FloatingInputField;
