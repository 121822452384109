import { Component } from "react";
import { withTranslation } from "react-i18next";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import _ from "lodash";

import routes from "routes";
import { Store } from "store";

import OngoingrideActions from "reducers/ride/ongoing-rides";
import OngoingSerieActions from "reducers/serie/ongoing-series";
import RideWaitingTimeActions from "reducers/ride/driver-location";
import OngoingBilanActions from "reducers/bilan";

import SideBar from "views/private/Sidebar";
import RideStatusNotification from "./RideStatusNotification";
import SerieStatusNotification from "./SerieStatusNotification";
import BilanUpdatedNotification from "./BilanUpdatedNotification";

import LogoutConfirmationDialog from "components/Logout-confirmation-Dialog";
import gear from "assets/img/gear.svg";
import { version } from "../../../package.json";
import { USER_ROLE } from "globals/constants";

class Private extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogoutConfirmationDialog: false,
    };

    this.logout = this.logout.bind(this);
    this.onConfirmLogout = this.onConfirmLogout.bind(this);
    this.onCancelLogout = this.onCancelLogout.bind(this);
  }

  getRoutes(routes) {
    return routes.map((prop, key) => {
      if (prop.layout === "/private") {
        if (prop.redirect) {
          return (
            <Redirect
              from={prop.path}
              to={prop.layout + prop.pathTo}
              key={key}
            />
          );
        } else {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  }

  onGoingRideStatusChanged(ride) {
    Store.dispatch(OngoingrideActions.onGoingRideStatusChanged(ride));

    const { status, _id } = ride;
    if ((status == "onway"))
      Store.dispatch(
        RideWaitingTimeActions.serviceGetRidesWaitingTimeRequest([_id])
      );
  }

  onGoingSerieStatusChanged(serie) {
    Store.dispatch(OngoingSerieActions.onGoingSerieStatusChanged(serie));
  }
  onGoingSerieStatusChanged(bilan) {
    Store.dispatch(OngoingBilanActions.onGoingBilanUpdated(bilan));
  }

  logout() {
    this.setState(
      Object.assign({}, this.state, { showLogoutConfirmationDialog: true })
    );
  }

  onConfirmLogout() {
    const { signoutRequest } = this.props;

    signoutRequest(() => {
      this.setState(
        Object.assign({}, this.state, { showLogoutConfirmationDialog: false })
      );
    });
  }

  onCancelLogout() {
    this.setState(
      Object.assign({}, this.state, { showLogoutConfirmationDialog: false })
    );
  }

  render() {
    const { t, i18n, account } = this.props;
    const { firstname = "", lastname = "", userType = "" } = account.user;

    if (window.location.pathname == "/private/ongoing-bilans") {
      return (
        <div className="">
          <div className="">
            <Switch>{this.getRoutes(routes)}</Switch>
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex flex-column flex-lg-row wrapper">
        <BilanUpdatedNotification
          onGoingBilanUpdated={this.onGoingBilanUpdated}
        />

        <RideStatusNotification
          onGoingRideStatusChanged={this.onGoingRideStatusChanged}
        />

        <SerieStatusNotification
          onGoingSerieStatusChanged={this.onGoingSerieStatusChanged}
        />

        <SideBar {...this.props} />

        <div className="main-panel flex-fill">
          <Switch>{this.getRoutes(routes)}</Switch>
        </div>

        <div className="profile-private">
          <div>
            {USER_ROLE.MEDECIN === userType && "Dr. "} {_.capitalize(lastname)}{" "}
            {_.capitalize(firstname)}
          </div>

          <div className="setings d-none d-lg-block">
            <Menu menuButton={<img src={gear} className="menu-deroulant" />}>
              <MenuItem>
                <NavLink to={"/private/settings"}>Paramètres</NavLink>
              </MenuItem>
              <MenuItem className="logout" onClick={this.logout}>
                {t("navLinks.log_out_menu_item_label", { lng: i18n.language })}
              </MenuItem>
            </Menu>
          </div>
        </div>

        <div className="setings settings-mobile">
          <Menu menuButton={<img src={gear} className="menu-deroulant" />}>
            <MenuItem>
              <NavLink to={"/private/settings"}>Paramètres</NavLink>
            </MenuItem>
            <MenuItem className="logout" onClick={this.logout}>
              {t("navLinks.log_out_menu_item_label", { lng: i18n.language })}
            </MenuItem>
          </Menu>
        </div>

        <LogoutConfirmationDialog
          className="side-bar-dialog"
          {...{ t, i18n }}
          show={this.state.showLogoutConfirmationDialog}
          onConfirm={this.onConfirmLogout}
          onCancel={this.onCancelLogout}
        />
      </div>
    );
  }
}

export default withTranslation(["Private", "Common"], { wait: true })(Private);
