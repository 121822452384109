import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Switch, Route, Redirect } from "react-router-dom";

// dinamically create pages routes
import routes from "routes.js";

class Public extends Component {
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/public") {
        if (prop.redirect)
          return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
        else {
          const View = prop.component;
          return (
            <Route
              path={prop.path}
              component={() => <View {...this.props} />}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <div className="d-flex d-row wrapper">
        <div className="full-page flex-fill">
          <div className="content">
            <Switch>{this.getRoutes(routes)}</Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(["Public"], { wait: true })(Public);
