import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  signinRequest: ["email", "password"],
  signinRequestSuccess: ["user"],
  signinRequestFailure: ["error"],

  signinOpenIdRequest: ["plateform", "code", "nonce", "callback"],
  signinOpenIdRequestSuccess: ["user"],
  signinOpenIdRequestFailure: ["error"],

  signoutOpenIdRequest: ["plateform", "callback"],
  signoutOpenIdRequestSuccess: ["user"],
  signoutOpenIdRequestFailure: ["error"],

  signoutRequest: ["callback"],

  preRegisterOpenId: ["user"],

  registerRequest: [
    "firstname",
    "lastname",
    "userType",
    "email",
    "password",
    "callback",
  ],
  registerRequestSuccess: [],
  registerRequestFailure: ["error"],

  registerOpenIdRequest: ["user", "callback"],
  registerOpenIdRequestSuccess: ["user"],
  registerOpenIdRequestFailure: ["error"],

  forgotPasswordRequest: ["email", "callback"],
  forgotPasswordSuccess: [],
  forgotPasswordFailure: ["error"],

  resetPasswordRequest: ["token", "password", "callback"],
  resetPasswordSuccess: ["callback"],
  resetPasswordFailure: ["error"],
});

export const AccountTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  user: {},
  token: null,
  actionOpenId: {
    loading: false,
    error: "",
  },
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const signinRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const signinRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  nextSate = Immutable.setIn(nextSate, ["token"], action.user.token);
  return Immutable.setIn(nextSate, ["user"], action.user);
};

export const signinRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const signinOpenIdRequest = (state, action) => {
  // let nextSate = Immutable.setIn(state, ["actionOpenId", "loading"], true);
  return Immutable.setIn(state, ["actionOpenId", "error"], "");
};

export const signinOpenIdRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["actionOpenId", "loading"], false);
  nextSate = Immutable.setIn(nextSate, ["token"], action.user.token);
  return Immutable.setIn(nextSate, ["user"], action.user);
};

export const signinOpenIdRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["actionOpenId", "loading"], false);
  return Immutable.setIn(nextSate, ["actionOpenId", "error"], action.error);
};

export const signoutOpenIdRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["actionOpenId", "loading"], true);
  return Immutable.setIn(state, ["actionOpenId", "error"], "");
};

export const signoutOpenIdRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["actionOpenId", "loading"], false);
  return Immutable.setIn(nextSate, ["user"], action.user);
};

export const signoutOpenIdRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["actionOpenId", "loading"], false);
  return Immutable.setIn(nextSate, ["actionOpenId", "error"], action.error);
};

export const signoutRequest = (state, action) => {
  return INITIAL_STATE;
};

export const preRegisterOpenId = (state, action) => {
  return Immutable.setIn(state, ["user"], action.user);
};

export const registerOpenIdRequest = (state, action) => {
  // let nextSate = Immutable.setIn(state, ["actionOpenId", "loading"], true);
  return Immutable.setIn(state, ["actionOpenId", "error"], "");
};

export const registerOpenIdRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["actionOpenId", "loading"], false);
  nextSate = Immutable.setIn(nextSate, ["token"], action.user.token);
  return Immutable.setIn(nextSate, ["user"], action.user);
};

export const registerOpenIdRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["actionOpenId", "loading"], false);
  return Immutable.setIn(nextSate, ["actionOpenId", "error"], action.error);
};

export const registerRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const registerRequestSuccess = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const registerRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

// FORGOT REQUEST
export const forgotPasswordRequest = (state, action) => state;

export const forgotPasswordSuccess = (state, action) => state;

export const forgotPasswordFailure = (state, action) => state;

// RESET
export const resetPasswordRequest = (state, action) => state;

export const resetPasswordSuccess = (state, action) => state;

export const resetPasswordFailure = (state, action) => state;

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGNIN_REQUEST]: signinRequest,
  [Types.SIGNIN_REQUEST_SUCCESS]: signinRequestSuccess,
  [Types.SIGNIN_REQUEST_FAILURE]: signinRequestFailure,

  [Types.SIGNIN_OPEN_ID_REQUEST]: signinOpenIdRequest,
  [Types.SIGNIN_OPEN_ID_REQUEST_SUCCESS]: signinOpenIdRequestSuccess,
  [Types.SIGNIN_OPEN_ID_REQUEST_FAILURE]: signinOpenIdRequestFailure,

  [Types.SIGNOUT_OPEN_ID_REQUEST]: signoutOpenIdRequest,
  [Types.SIGNOUT_OPEN_ID_REQUEST_SUCCESS]: signoutOpenIdRequestSuccess,
  [Types.SIGNOUT_OPEN_ID_REQUEST_FAILURE]: signoutOpenIdRequestFailure,

  [Types.PRE_REGISTER_OPEN_ID]: preRegisterOpenId,

  [Types.SIGNOUT_REQUEST]: signoutRequest,

  [Types.REGISTER_REQUEST]: registerRequest,
  [Types.REGISTER_REQUEST_SUCCESS]: registerRequestSuccess,
  [Types.REGISTER_REQUEST_FAILURE]: registerRequestFailure,

  [Types.REGISTER_OPEN_ID_REQUEST]: registerOpenIdRequest,
  [Types.REGISTER_OPEN_ID_REQUEST_SUCCESS]: registerOpenIdRequestSuccess,
  [Types.REGISTER_OPEN_ID_REQUEST_FAILURE]: registerOpenIdRequestFailure,

  [Types.FORGOT_PASSWORD_REQUEST]: forgotPasswordRequest,
  [Types.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
  [Types.FORGOT_PASSWORD_FAILURE]: forgotPasswordFailure,

  [Types.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,
});
