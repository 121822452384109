import { put, takeLatest } from "redux-saga/effects";
import AccountActions, { AccountTypes } from "reducers/account";
import ServiceActions from "reducers/company/get-service";
import OngoingRideActions from "reducers/ride/ongoing-rides";
import HistoryRideActions from "reducers/ride/ride-history";
import NewRideActions from "reducers/ride/new-ride";
import BlackListActions from "reducers/transporter/black-list";
import WhiteListActions from "reducers/transporter/white-list";

import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* resetAllState() {
  yield put(OngoingRideActions.onGoingRidesResetState());
  yield put(HistoryRideActions.rideHistoryResetState());
  yield put(NewRideActions.rideResetState());
  yield put(BlackListActions.blackListResetState());
  yield put(WhiteListActions.whiteListResetState());
}

const RESPONSE_QUERY_LOGIN = `
  firstname
  lastname
  email
  token
  userType
  openId { psc }
  company {
    name
    email
    phones
    address { address }
  }
`;

function* signinAsyncRequest({ email, password }) {
  const query = `
    query { 
      ServiceUserLogin(email:"${email}", password:"${password}") {
        ${RESPONSE_QUERY_LOGIN}
      }
    }
    `;

  try {
    const { ServiceUserLogin } = yield graphQLClient().request(query);
    yield resetAllState();
    yield put(AccountActions.signinRequestSuccess(ServiceUserLogin));
    yield put(ServiceActions.getServiceCompanyRequest());
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    yield put(AccountActions.signinRequestFailure(errors[0].message));
  }
}

function* signinOpenIdAsyncRequest({ plateform, code, nonce, callback }) {
  const query = `
    query {
      ServiceUserLoginOpenId(plateform:"${plateform}", code:"${code}", nonce:"${nonce}") {
        ${RESPONSE_QUERY_LOGIN}
        registration {
          name
          firstname
          lastname
          openId
          plateform
          address {
            address
            latitude
            longitude
          }
        }
      }
    }
  `;

  try {
    const { ServiceUserLoginOpenId } = yield graphQLClient().request(query);
    yield resetAllState();
    if (ServiceUserLoginOpenId.registration) {
      if (_.isFunction(callback))
        callback(null, ServiceUserLoginOpenId.registration);
      // yield put(AccountActions.preRegisterOpenId(ServiceUserLoginOpenId.registration));
    } else {
      yield put(
        AccountActions.signinOpenIdRequestSuccess(ServiceUserLoginOpenId)
      );
      yield put(ServiceActions.getServiceCompanyRequest());
    }
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;

    if (_.isFunction(callback)) callback(errors[0].message);
    // yield put(AccountActions.signinOpenIdRequestFailure(errors[0].message));
  }
}

function* signoutOpenIdAsyncRequest({ plateform, callback }) {
  const query = `
    query {
      ServiceUserSignoutOpenId(plateform:"${plateform}") {
        ${RESPONSE_QUERY_LOGIN}
      }
    }
  `;

  try {
    const { ServiceUserSignoutOpenId } = yield graphQLClient().request(query);
    yield resetAllState();
    yield put(
      AccountActions.signoutOpenIdRequestSuccess(ServiceUserSignoutOpenId)
    );
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    if (_.isFunction(callback)) callback(errors[0].message);
    yield put(AccountActions.signoutOpenIdRequestFailure(errors[0].message));
  }
}

function* signoutAsyncRequest({ callback }) {
  yield resetAllState();
  if (_.isFunction(callback)) callback();
}

function* forgotPasswordAsyncRequest({ email, callback }) {
  const query = `
    mutation {
      ServiceRequestResetPassword(email:"${email}")
    }
  `;
  try {
    if (_.isFunction(callback)) callback();
    yield graphQLClient().request(query);
    yield put(AccountActions.forgotPasswordSuccess());
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    yield put(AccountActions.forgotPasswordFailure(errors[0].message));
  }
}

function* resetPasswordAsyncRequest({ token, password, callback }) {
  const query = `
    mutation {
      ServiceResetPassword(token:"${token}", password:"${password}")
    }
  `;
  try {
    if (_.isFunction(callback)) callback();
    yield graphQLClient().request(query);
    yield put(AccountActions.resetPasswordSuccess(callback));
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    yield put(AccountActions.resetPasswordFailure(errors[0].message));
  }
}

function* registerAsyncRequest({
  firstname,
  lastname,
  userType,
  email,
  password,
  callback,
}) {
  const query = `
    mutation { 
      ServiceUserRegister(
        firstname: "${firstname}",
        lastname: "${lastname}",
        userType: "${userType}",
        email:"${email}",
        password:"${password}"
      )
    }
  `;

  try {
    const { ServiceUserRegister } = yield graphQLClient().request(query);
    yield resetAllState();
    yield put(AccountActions.registerRequestSuccess());
    if (_.isFunction(callback)) callback();
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    yield put(AccountActions.registerRequestFailure(errors[0].message));
  }
}

function* registerOpenIdAsyncRequest({ user, callback }) {
  const query = `
    mutation { 
      ServiceUserRegisterOpenId(
        name: "${user?.name}",
        firstname: "${user?.firstname}",
        lastname: "${user?.lastname}",
        address: {
          address: "${user?.address?.address}",
          longitude: ${user?.address?.longitude},
          latitude: ${user?.address?.latitude}
        },
        email:"${user?.email}",
        phone:"${user?.phone}",
        openId:"${user?.openId}",
        plateform:"${user?.plateform}"
      ) {
        ${RESPONSE_QUERY_LOGIN}
      }
    }
  `;

  try {
    const { ServiceUserRegisterOpenId } = yield graphQLClient().request(query);
    yield resetAllState();
    yield put(
      AccountActions.registerOpenIdRequestSuccess(ServiceUserRegisterOpenId)
    );
    yield put(ServiceActions.getServiceCompanyRequest());
    // if (_.isFunction(callback)) callback();
  } catch ({ response }) {
    const { errors = [{ message: "" }] } = response;
    if (_.isFunction(callback)) callback(errors[0].message);
    // yield put(AccountActions.registerOpenIdRequestFailure(errors[0].message));
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(AccountTypes.SIGNIN_REQUEST, signinAsyncRequest),
  takeLatest(AccountTypes.SIGNIN_OPEN_ID_REQUEST, signinOpenIdAsyncRequest),
  takeLatest(AccountTypes.SIGNOUT_OPEN_ID_REQUEST, signoutOpenIdAsyncRequest),
  takeLatest(AccountTypes.SIGNOUT_REQUEST, signoutAsyncRequest),
  takeLatest(AccountTypes.FORGOT_PASSWORD_REQUEST, forgotPasswordAsyncRequest),
  takeLatest(AccountTypes.RESET_PASSWORD_REQUEST, resetPasswordAsyncRequest),
  takeLatest(AccountTypes.REGISTER_REQUEST, registerAsyncRequest),
  takeLatest(AccountTypes.REGISTER_OPEN_ID_REQUEST, registerOpenIdAsyncRequest),
];
