import { Component } from "react";
import _ from "lodash";
import checkedIcon from "assets/img/patient-status-checked.svg";
import uncheckedIcon from "assets/img/patient-status-unchecked.svg";

class PatientStepButtonButton extends Component {
  constructor(props) {
    super(props);

    this.onClickButton = this.onClickButton.bind(this);

    const { steps, value } = this.props;

    const index = _.indexOf(_.map(steps, "key"), value) + 1;

    this.state = {
      nextStep: index,
    };
  }

  onClickButton(index) {
    const { steps, valueDidChnged } = this.props;

    this.setState(Object.assign({}, this.state, { nextStep: index + 1 }));
    if (_.isFunction(valueDidChnged)) valueDidChnged(steps[index]);
  }

  renderSteps() {
    const { steps } = this.props;

    return _.reduce(
      steps,
      (acc, el, index) => {
        return [
          ...acc,
          <div
            value={index}
            key={el.key}
            onClick={() => this.onClickButton(index)}
            style={{ display: "flex", cursor: "pointer" }}
          >
            <img
              src={index < this.state.nextStep ? checkedIcon : uncheckedIcon}
              alt="patient status"
            />
            <div
              style={{
                marginLeft: "4px",
                fontSize: "12px",
                fontWeight: "500",
                color: index < this.state.nextStep - 1 ? "#999C9F" : "black",
              }}
            >
              {el.value}
            </div>
          </div>,
        ];
        // return [
        //   ...acc,
        //   <li
        //     className="d-flex flex-fill justify-content-center flex-column"
        //     key={index}
        //     value={index}
        //     onClick={() => {
        //       this.onClickButton(index);
        //     }}
        //   >
        //     <div className="d-flex justify-content-center align-items-center flex-row">
        //       <div
        //         className={`d-flex flex-fill step-line ${
        //           index <= this.state.nextStep ? "active" : ""
        //         }`}
        //       ></div>
        //       {index < this.state.nextStep ? (
        //         <svg
        //           width="20"
        //           height="20"
        //           viewBox="0 0 20 20"
        //           fill="none"
        //           xmlns="http://www.w3.org/2000/svg"
        //         >
        //           {/* <circle cx="10" cy="10" r="10" fill="#0096AC" />
        //           <circle cx="10" cy="10" r="4" fill="white" /> */}
        //         </svg>
        //       ) : (
        //         <svg
        //           width="20"
        //           height="20"
        //           viewBox="0 0 20 20"
        //           fill="none"
        //           xmlns="http://www.w3.org/2000/svg"
        //         >
        //           {/* <circle
        //             cx="10"
        //             cy="10"
        //             r="9.5"
        //             fill="#EBF5F8"
        //             // className={index == this.state.nextStep ? "next-step" : ""}
        //           /> */}
        //           {/* <circle
        //             cx="10"
        //             cy="10"
        //             r="3.5"
        //             fill="white"
        //             // className={index == this.state.nextStep ? "next-step" : ""}
        //           /> */}
        //         </svg>
        //       )}

        //       {/* <div
        //         className={`d-flex flex-fill step-line ${
        //           index < this.state.nextStep ? "active" : ""
        //         }`}
        //       ></div> */}
        //     </div>
        //     <span
        //       className={`text-center ${
        //         index < this.state.nextStep ? "active" : ""
        //       } ${index == this.state.nextStep ? "next-step" : ""}`}
        //     >
        //       {el.value}
        //     </span>
        //   </li>,
        // ];
      },
      []
    );
  }
  render() {
    const { src = null, title = null, round = false } = this.props;
    return (
      <div className="patient-steps-btn">
        <ol className="d-flex flex-column">{this.renderSteps()}</ol>
      </div>
    );
  }
}

export default PatientStepButtonButton;
