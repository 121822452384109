import { combineReducers } from "redux";

const AppReducers = combineReducers({
  account: require("./account").reducer,
  newRide: require("./ride/new-ride").reducer,
  newSerie: require("./serie/new-serie").reducer,
  service: require("./company/get-service").reducer,
  ongoingRides: require("./ride/ongoing-rides").reducer,
  ridesHistory: require("./ride/ride-history").reducer,
  serviceFeedback: require("./ride/send-feedback").reducer,
  blackList: require("./transporter/black-list").reducer,
  whiteList: require("./transporter/white-list").reducer,
  patientsList: require("./patient/patient").reducer,
  etablishmentList: require("./etablishments/etablishment").reducer,
  driverLocation: require("./ride/driver-location").reducer,
  ongoingSeries: require("./serie/ongoing-series").reducer,
  ongoingBilans: require("./bilan").reducer,
  settings: require("./settings").reducer,
  minio: require("./minio").reducer,
  testRide: require("./ride/test").reducer,
});

export default AppReducers;
