import { Subscription } from "@apollo/client/react/components";
import gql from "graphql-tag";

const SERVICE_SERIE_STATUS_SUBSCRIPTION = gql`
  subscription {
    ServiceSerieStatusSubscription {
      _id
      status
    }
  }
`;

const SerieStatusNotification = ({ onGoingSerieStatusChanged }) => (
  <Subscription
    subscription={SERVICE_SERIE_STATUS_SUBSCRIPTION}
    onSubscriptionData={({ subscriptionData }) => {
      const serviceSerieStatusSubscription =
        subscriptionData.data.ServiceSerieStatusSubscription;
      onGoingSerieStatusChanged(serviceSerieStatusSubscription);
    }}
  >
    {({ data = {}, loading, error }) => null}
  </Subscription>
);

export default SerieStatusNotification;
