import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  onGoingSeriesResetState: null,

  onGoingSeriesRequest: ["page", "limit", "filter"],
  onGoingSeriesRequestSuccess: ["series"],
  onGoingSeriesRequestFailure: ["error"],

  onGoingSerieStatusChanged: ["serie"],

  cancelSerieRequest: ["serieId", "callback"],
  cancelSerieRequestSuccess: ["serie"],
  cancelSerieRequestFailure: ["error"],
});

export const OngoingSerieTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  series: {
    page: 1,
    limit: 10,
    totalPages: 0,
    series: [],
  },
  action: {
    loading: false,
    error: "",
  },
});

/// GENERAL
export const onGoingSeriesResetState = (state, action) => {
  return INITIAL_STATE;
};

export const onGoingSerieStatusChanged = (state, action) => {
  let series = state.series.series.asMutable({ deep: true });
  let _series = _.map(series, (s) => {
    if (action.serie._id === s._id) {
      return { ...s, ...action.serie };
    }
    return s;
  });
  return Immutable.setIn(state, ["series", "series"], _series);
};

export const onGoingSeriesRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const onGoingSeriesRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const onGoingSeriesRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  if (action.series.page == 1)
    //first page
    return Immutable.setIn(nextSate, ["series"], action.series);
  else {
    //apend next pages
    let prevList = state.series.series.asMutable({ deep: true });

    const { series, ...rest } = action.series;

    return Immutable.setIn(nextSate, ["series"], {
      ...rest,
      series: [...prevList, ...series],
    });
  }
};

export const cancelSerieRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const cancelSerieRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const cancelSerieRequestSuccess = (state, action) => {
  const { serie } = action;
  let series = state.series.series.asMutable({ deep: true });

  let _series = _.map(series, (r) => {
    if (serie._id === r._id) {
      return serie;
    } else {
      return r;
    }
  });

  return Immutable.setIn(state, ["series", "series"], _series);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.ON_GOING_SERIES_RESET_STATE]: onGoingSeriesResetState,

  [Types.ON_GOING_SERIES_REQUEST]: onGoingSeriesRequest,
  [Types.ON_GOING_SERIES_REQUEST_SUCCESS]: onGoingSeriesRequestSuccess,
  [Types.ON_GOING_SERIES_REQUEST_FAILURE]: onGoingSeriesRequestFailure,

  [Types.ON_GOING_SERIE_STATUS_CHANGED]: onGoingSerieStatusChanged,

  [Types.CANCEL_SERIE_REQUEST]: cancelSerieRequest,
  [Types.CANCEL_SERIE_REQUEST_SUCCESS]: cancelSerieRequestSuccess,
  [Types.CANCEL_SERIE_REQUEST_FAILURE]: cancelSerieRequestFailure,
});
