import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  whiteListResetState: null,
  getWhiteListRequest: null,
  getWhiteListRequestSuccess: ["transporters"],
  getWhiteListRequestFailure: ["error"],

  removeTransporterFromWhiteListRequest: ["transporterId"],
  removeTransporterFromWhiteListRequestSuccess: ["transporterId"],
  removeTransporterFromWhiteListRequestFailure: ["error"],
});

export const WhiteListTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  transporters: [],
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const whiteListResetState = (state, action) => {
  return INITIAL_STATE;
};

export const getWhiteListRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  nextSate = Immutable.setIn(nextSate, ["transporters"], []);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const getWhiteListRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["transporters"], action.transporters);
};

export const getWhiteListRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

//
export const removeTransporterFromWhiteListRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const removeTransporterFromWhiteListRequestSuccess = (state, action) => {
  const transporters = state.transporters.asMutable({ deep: true });

  let _transporters = _.remove(
    transporters,
    (transporter) => transporter._id !== action.transporterId
  );

  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["transporters"], _transporters);
};

export const removeTransporterFromWhiteListRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.WHITE_LIST_RESET_STATE]: whiteListResetState,

  [Types.GET_WHITE_LIST_REQUEST]: getWhiteListRequest,
  [Types.GET_WHITE_LIST_REQUEST_SUCCESS]: getWhiteListRequestSuccess,
  [Types.GET_WHITE_LIST_REQUEST_FAILURE]: getWhiteListRequestFailure,

  [Types.REMOVE_TRANSPORTER_FROM_WHITE_LIST_REQUEST]:
    removeTransporterFromWhiteListRequest,
  [Types.REMOVE_TRANSPORTER_FROM_WHITE_LIST_REQUEST_SUCCESS]:
    removeTransporterFromWhiteListRequestSuccess,
  [Types.REMOVE_TRANSPORTER_FROM_WHITE_LIST_REQUEST_FAILURE]:
    removeTransporterFromWhiteListRequestFailure,
});
