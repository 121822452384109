import { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import _ from "lodash";

class CancelRideConfirmationDialog extends Component {
  constructor(props) {
    super(props);

    this.onConfirmAction = this.onConfirmAction.bind(this);
    this.onCancelAction = this.onCancelAction.bind(this);
  }

  onCancelAction() {
    const { onCancel } = this.props;
    if (_.isFunction(onCancel)) onCancel();
  }

  onConfirmAction() {
    const { onConfirm } = this.props;
    if (_.isFunction(onConfirm)) onConfirm();
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <Modal {...this.props} centered>
        <Modal.Body className="text-center">
          <h2>
            {t("Common:Dialog.CancelRideConfirmation.header_label", {
              lng: i18n.language,
            })}
          </h2>
          <span>
            {t("Common:Dialog.CancelRideConfirmation.confirmation_message", {
              lng: i18n.language,
            })}
          </span>
        </Modal.Body>
        <Modal.Footer className="justify-content-around">
          <Button variant="secondary" onClick={this.onCancelAction}>
            {t("Common:Dialog.CancelRideConfirmation.cancel_button", {
              lng: i18n.language,
            })}
          </Button>
          <Button variant="primary" onClick={this.onConfirmAction}>
            {t("Common:Dialog.CancelRideConfirmation.confirm_button", {
              lng: i18n.language,
            })}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CancelRideConfirmationDialog;
