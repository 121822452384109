import { Component } from "react";
import _ from "lodash";
// import moment from "moment";
// import ImageButton from "components/Image-Button";
import AmbulanceIcon from "assets/img/urgence-ambulance.png";
import Call15Icon from "assets/img/call-15.png";

// function formatWaitingTime(waitingTime, status) {
//   return ["onway", "onboard"].includes(status) &&
//     !_.isEmpty(waitingTime) &&
//     _.isInteger(waitingTime.duration_in_traffic)
//     ? moment.utc(waitingTime.duration_in_traffic * 1000).format("HH:mm")
//     : "--:--";
// }

function getTextProps(isDanger = false) {
  return {
    fontFamily: "Lato",
    fontSize: "54px",
    fontWeight: 900,
    color: isDanger ? "red" : "black",
  };
}

function getDangerForOneSurvey(survey) {
  const isOk = (property) => {
    return survey.hasOwnProperty(property) && survey[property] !== null;
  };
  let messages = [];

  if (isOk("eyeOpener") && isOk("verbalResponse") && isOk("motorResponse")) {
    const glasgowTotal =
      survey.eyeOpener + survey.verbalResponse + survey.motorResponse;
    if (glasgowTotal < 14) messages.push("GCS" + glasgowTotal);
  }

  if (isOk("saturation") && survey?.saturation < 95)
    messages.push("SAT" + survey?.saturation);
  if (isOk("respiratoryFrequency")) {
    if (survey?.respiratoryFrequency < 12)
      messages.push("FR" + survey?.respiratoryFrequency);
    if (survey?.respiratoryFrequency > 20)
      messages.push("FR" + survey?.respiratoryFrequency);
  }
  if (isOk("circulatoryFrequency")) {
    if (survey?.circulatoryFrequency < 50)
      messages.push("FC" + survey?.circulatoryFrequency);
    if (survey?.circulatoryFrequency > 110)
      messages.push("FC" + survey?.circulatoryFrequency);
  }
  if (
    (isOk("bloodPressureLeft") && survey?.bloodPressureLeft < 100) ||
    (isOk("bloodPressureRight") && survey?.bloodPressureRight < 100)
  ) {
    const msg =
      survey?.bloodPressureLeft < 100
        ? survey?.bloodPressureLeft
        : survey?.bloodPressureRight;
    messages.push("PA" + msg);
  }
  if (
    (isOk("bloodPressureLeft") && survey?.bloodPressureLeft > 170) ||
    (isOk("bloodPressureRight") && survey?.bloodPressureRight > 170)
  ) {
    const msg =
      survey?.bloodPressureLeft > 170
        ? survey?.bloodPressureLeft
        : survey?.bloodPressureRight;
    messages.push("PA" + msg);
  }
  return messages;
}

export function getDangerMessage(bilan) {
  const isOk = (property) => {
    return bilan.hasOwnProperty(property) && bilan[property] !== null;
  };
  let messages = [];
  if (!_.isEmpty(bilan.survey)) {
    let lastElement = bilan.survey[bilan.survey.length - 1];
    messages = [...messages, ...getDangerForOneSurvey(lastElement)];
  } else {
    messages = [
      ...messages,
      ...getDangerForOneSurvey({
        ...bilan?.ventilatory,
        ...bilan?.circulatory,
        ...bilan?.neurological,
      }),
    ];
  }
  if (isOk("ventilatory")) {
    if (
      bilan?.ventilatory.hasOwnProperty("respiratoryArrest") &&
      bilan?.ventilatory.respiratoryArrest
    )
      messages.push("AR !");
  }
  if (
    isOk("circulatory") &&
    bilan?.circulatory.hasOwnProperty("circulatoryArrest") &&
    bilan?.circulatory.circulatoryArrest
  )
    messages.push("AC !");

  if (isOk("various")) {
    if (bilan?.various?.hasOwnProperty("evn") && bilan.various?.evn > 5)
      messages.push("EVN" + bilan.various?.evn);
    if (
      bilan?.various.hasOwnProperty("temperature") &&
      bilan.various.temperature != null
    ) {
      if (bilan.various?.temperature < 35.5)
        messages.push("T" + bilan.various?.temperature);
      if (bilan.various?.temperature > 38)
        messages.push("T" + bilan.various?.temperature);
    }
  }

  return messages.join(" ");
}

function getFormatedWaitingTime(seconds = null) {
  if (seconds === null) return "--:--";
  if (3600 < seconds) {
    // return like this 01h30
    const hours = parseInt(seconds / 3600);
    const minutes = parseInt((seconds % 3600) / 60);
    const hoursStr = hours < 10 ? "0" + hours : hours;
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;
    return hoursStr + "h" + minutesStr;
  }
  const minutes = parseInt(seconds / 60);
  return minutes + " min";
}

function getPatientInfoFormated(patient) {
  let genderPatientLetter = "X";
  if (patient.gender === "female") {
    genderPatientLetter = "F";
  } else if (patient.gender === "male") {
    genderPatientLetter = "H";
  }
  return genderPatientLetter + "-" + patient.age;
}

class BilanCard extends Component {
  render() {
    const { /* t, i18n, */ bilan = {} } = this.props;
    const { ride = { observation: "" }, status = "" } = bilan;
    const title = getDangerMessage(bilan);
    const isDanger = title !== "";
    let duration_in_traffic = null;
    if (bilan.ride.id && bilan.ride.id.hasOwnProperty("waitingTime")) {
      if (bilan.ride.id.waitingTime.hasOwnProperty("duration_in_traffic")) {
        duration_in_traffic = bilan.ride.id.waitingTime.duration_in_traffic;
      }
    }
    const timeOrCompletedText =
      status === "completed"
        ? "ARV"
        : getFormatedWaitingTime(duration_in_traffic);

    return (
      <div
        className={`border border-5 border-${
          isDanger ? "danger" : "primary"
        } rounded mt-2 d-flex flex-row flex-grow-1 align-items-center py-2 px-4`}
      >
        {/* IMAGE */}
        <div className="d-flex align-items-end">
          <img src={AmbulanceIcon} alt="Ambulance" />
          <img src={Call15Icon} alt="call" />
        </div>

        <div
          style={{ ...getTextProps(isDanger), width: "15%", marginLeft: 50 }}
        >
          {getPatientInfoFormated(bilan.administrative)}
        </div>

        <div style={{ ...getTextProps(isDanger) }}>
          {ride.observation.toUpperCase()} {isDanger ? "- " + title : null}
        </div>

        <div
          className="flex-grow-1"
          style={{ ...getTextProps(isDanger), textAlign: "end" }}
        >
          {timeOrCompletedText}
        </div>
      </div>
    );
  }
}

export default BilanCard;
