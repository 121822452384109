import { all } from "redux-saga/effects";
import AccountSagas from "./account";
import serviceSaga from "./company/get-service";
import newRideSaga from "./ride/new-ride";
import onGoingRideSaga from "./ride/ongoing-rides";
import rideHistorySaga from "./ride/ride-history";
import serviceSendFeedback from "./ride/send-feedback";
import blackListSagas from "./transporter/black-list";
import whiteListSagas from "./transporter/white-list";
import patientSaga from "./patient/patient";
import etablishment from "./etablishment/etablishment";
import driverLocation from "./ride/driver-location";
import newSerieSaga from "./serie/new-serie";
import ongoingSeries from "./serie/ongoing-series";
import BilanSagas from "./bilan";
import MinioSaga from "./minio";
import TestRideSaga from "./ride/test.js";
// import SettingsSaga from "./settings";

export default function* root() {
  yield all([
    ...AccountSagas,
    ...serviceSaga,
    ...newRideSaga,
    ...newSerieSaga,
    ...onGoingRideSaga,
    ...ongoingSeries,
    ...rideHistorySaga,
    ...serviceSendFeedback,
    ...blackListSagas,
    ...whiteListSagas,
    ...patientSaga,
    ...etablishment,
    ...driverLocation,
    ...BilanSagas,
    ...MinioSaga,
    ...TestRideSaga,
    // ...SettingsSaga,
  ]);
}
