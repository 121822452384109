import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  serviceGetRideDriverLocationRequest: ["rideId"],
  serviceGetRideDriverLocationRequestSuccess: ["coords"],
  serviceGetRideDriverLocationRequestFailure: ["error"],

  serviceGetRidesWaitingTimeRequest: ["ridesId"],
  serviceGetRidesWaitingTimeRequestSuccess: ["waitingTime"],
  serviceGetRidesWaitingTimeRequestFailure: ["error"],
});

export const DriverLocationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  waitingTime: [],
  coords: [],
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const serviceGetRidesWaitingTimeRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const serviceGetRidesWaitingTimeRequestSuccess = (state, action) => {
  const { waitingTime } = state.asMutable({ deep: true });

  //avoid reloading the map
  if (_.isEqual(waitingTime, action.waitingTime)) return state;

  const s = _.reduce(
    waitingTime,
    (acc, el) => {
      const oldExist = _.filter(
        action.waitingTime,
        (e) => el.rideId == e.rideId
      );
      return _.isEmpty(oldExist) ? [...acc, el] : acc;
    },
    []
  );

  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(
    nextSate,
    ["waitingTime"],
    [...s, ...action.waitingTime]
  );
};

export const serviceGetRidesWaitingTimeRequestFailure = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

export const serviceGetRideDriverLocationRequestSuccess = (state, action) => {
  const { coords } = state.asMutable({ deep: true });

  //avoid reloading the map
  if (_.isEqual(coords[0], action.coords)) return state;

  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  if (_.isNull(action.coords.longitude) || _.isNull(action.coords.longitude))
    return nextSate;

  const s = _.reduce(
    coords,
    (acc, el) => {
      return el.rideId == action.coords.rideId ? acc : [...acc, el];
    },
    []
  );

  return Immutable.setIn(nextSate, ["coords"], [...s, action.coords]);
};

export const serviceGetRideDriverLocationRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const serviceGetRideDriverLocationRequestFailure = (state, action) => {
  return Immutable.setIn(state, ["action", "loading"], false);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SERVICE_GET_RIDE_DRIVER_LOCATION_REQUEST]:
    serviceGetRideDriverLocationRequest,
  [Types.SERVICE_GET_RIDE_DRIVER_LOCATION_REQUEST_SUCCESS]:
    serviceGetRideDriverLocationRequestSuccess,
  [Types.SERVICE_GET_RIDE_DRIVER_LOCATION_REQUEST_FAILURE]:
    serviceGetRideDriverLocationRequestFailure,

  [Types.SERVICE_GET_RIDES_WAITING_TIME_REQUEST]:
    serviceGetRidesWaitingTimeRequest,
  [Types.SERVICE_GET_RIDES_WAITING_TIME_REQUEST_SUCCESS]:
    serviceGetRidesWaitingTimeRequestSuccess,
  [Types.SERVICE_GET_RIDES_WAITING_TIME_REQUEST_FAILURE]:
    serviceGetRidesWaitingTimeRequestFailure,
});
