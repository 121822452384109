import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import _ from "lodash";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  blackListResetState: null,
  getBlackListRequest: null,
  getBlackListRequestSuccess: ["transporters"],
  getBlackListRequestFailure: ["error"],

  removeTransporterFromBlackListRequest: ["transporterId"],
  removeTransporterFromBlackListRequestSuccess: ["transporterId"],
  removeTransporterFromBlackListRequestFailure: ["error"],
});

export const BlackListTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  transporters: [],
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const blackListResetState = (state, action) => {
  return INITIAL_STATE;
};

export const getBlackListRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  nextSate = Immutable.setIn(nextSate, ["transporters"], []);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const getBlackListRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["transporters"], action.transporters);
};

export const getBlackListRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

//
export const removeTransporterFromBlackListRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};

export const removeTransporterFromBlackListRequestSuccess = (state, action) => {
  const transporters = state.transporters.asMutable({ deep: true });

  let _transporters = _.remove(
    transporters,
    (transporter) => transporter._id !== action.transporterId
  );

  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["transporters"], _transporters);
};

export const removeTransporterFromBlackListRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.BLACK_LIST_RESET_STATE]: blackListResetState,

  [Types.GET_BLACK_LIST_REQUEST]: getBlackListRequest,
  [Types.GET_BLACK_LIST_REQUEST_SUCCESS]: getBlackListRequestSuccess,
  [Types.GET_BLACK_LIST_REQUEST_FAILURE]: getBlackListRequestFailure,

  [Types.REMOVE_TRANSPORTER_FROM_BLACK_LIST_REQUEST]:
    removeTransporterFromBlackListRequest,
  [Types.REMOVE_TRANSPORTER_FROM_BLACK_LIST_REQUEST_SUCCESS]:
    removeTransporterFromBlackListRequestSuccess,
  [Types.REMOVE_TRANSPORTER_FROM_BLACK_LIST_REQUEST_FAILURE]:
    removeTransporterFromBlackListRequestFailure,
});
