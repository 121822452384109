import { Component, createRef } from "react";
import { Form } from "react-bootstrap";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import _ from "lodash";

class InputAddressField extends Component {
  constructor(props) {
    super(props);

    this.GooglePlacesRef = createRef();
    this.state = {
      valid: false,
      invalid: false,
    };

    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  // componentDidUpdate(prevProps) {
  //   const { formikProps, formikPropsPath } = this.props;
  //   const { values } = formikProps;
  //   const prevAddress = _.get(prevProps.formikProps.values, `${formikPropsPath}.address`, "");
  //   const address = _.get(values, `${formikPropsPath}.address`, "");
  // console.log(this.GooglePlacesRef.current)

  // this.GooglePlacesRef.current.clearValue()
  //   if (this.state.valid && prevAddress !== address && _.isEmpty(address)) {
  //     console.log("reset")
  //     this.GooglePlacesRef.current.changeValue('')
  //     // this.onInputChange("", { action: "input-change" })
  //   }
  // }

  onInputChange(value, { action }) {
    if (action != "input-change") return;

    const { formikProps, formikPropsPath, onAddressChanged } = this.props;
    const { setFieldValue } = formikProps;

    this.setState(
      Object.assign({}, this.state, { valid: false, invalid: true })
    );

    if (_.isFunction(onAddressChanged)) onAddressChanged(value);

    setFieldValue(`${formikPropsPath}.address`, value, true);
    setFieldValue(`${formikPropsPath}.latitude`, "", true);
    setFieldValue(`${formikPropsPath}.longitude`, "", true);
  }

  onChangeAddress({ label, value }) {
    const { onAddressChanged, formikPropsPath, formikProps } = this.props;
    const { setFieldValue } = formikProps;

    const { place_id, description } = value;

    geocodeByPlaceId(place_id)
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        this.setState(
          Object.assign({}, this.state, { valid: true, invalid: false })
        );

        setFieldValue(`${formikPropsPath}.address`, label, true);
        setFieldValue(`${formikPropsPath}.latitude`, lat, true);
        setFieldValue(`${formikPropsPath}.longitude`, lng, true);

        if (_.isFunction(onAddressChanged)) onAddressChanged(label);
      });
  }

  render() {
    const {
      label = "Address...",
      formikProps,
      formikPropsPath,
      className,
      withStyle = false,
    } = this.props;

    const inputValue = _.get(
      formikProps.values,
      `${formikPropsPath}.address`,
      ""
    );
    const latValue = _.get(
      formikProps.values,
      `${formikPropsPath}.latitude`,
      ""
    );
    const lngValue = _.get(
      formikProps.values,
      `${formikPropsPath}.longitude`,
      ""
    );

    const isvalid =
      (_.isNumber(lngValue) & _.isNumber(lngValue) & !_.isEmpty(inputValue)) |
      this.state.valid;

    if (withStyle) {
      return (
        <Form.Group
          className={`address-autocomplete-floating ${isvalid ? "valid" : ""} ${
            this.state.invalid ? "invalid" : ""
          } ${className}`}
        >
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_GMAPS_KEY}
            selectProps={{
              inputValue,
              onInputChange: this.onInputChange,
              onChange: this.onChangeAddress,
              // blurInputOnSelect: true,
              components: {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              },
              noOptionsMessage: () => null,
              placeholder: () => null,
              className: "address-autocomplete-container",
              classNamePrefix: "address-autocomplete",
              styles: {
                valueContainer: (provided, state) => ({
                  ...provided,
                }),
                control: (provided, state) => ({
                  ...provided,
                  border: "none",
                }),
              },
            }}
            autocompletionRequest={{
              componentRestrictions: {
                country: ["fr"],
              },
            }}
          />
          <Form.Label>{label}</Form.Label>
        </Form.Group>
      );
    }

    return (
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GMAPS_KEY}
        ref={this.GooglePlacesRef}
        selectProps={{
          inputValue,
          onInputChange: this.onInputChange,
          onChange: this.onChangeAddress,
          // blurInputOnSelect: true,
          components: {
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          },
          // noOptionsMessage: () => null,
          placeholder: label,
          styles: {
            valueContainer: (provided, state) => ({
              ...provided,
            }),
            control: (provided, state) => ({
              ...provided,
            }),
          },
        }}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["fr"],
          },
        }}
      />
    );
  }
}

export default InputAddressField;
