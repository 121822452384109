// LIST OF GLOBAL VARIABLES

export const LOCAL_STORAGE_KEYS = {
  PSC_STATE: "psc_state",
  PSC_NONCE: "psc_nonce",
};

export const OPEN_ID_PLATFORMS = {
  PSC: "psc",
};

export const USER_ROLE = {
  SERVICE: "serviceUser",
  MEDECIN: "doctorUser",
  SECRETARY: "secretaryUser",
};
