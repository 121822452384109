import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { List, AutoSizer, InfiniteLoader } from "react-virtualized";
import OngoingSerieRow from "components/Cards/SerieCard";
import { connect } from "react-redux";
import OngoingSeriesActions from "reducers/serie/ongoing-series";
import { withTranslation } from "react-i18next";
import CancelConfirmationDialog from "components/Cancel-Confirmation-Dialog";
import _ from "lodash";
import searchInputIcon from "assets/img/search-input-icon.svg";
import noDataIcon from "assets/img/no_data.svg";
import SerieCard from "components/Cards/SerieCard";
import Calendar from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import datepickerLocal from "globals/local-dates";
import InfiniteScroll from "react-infinite-scroll-component";

class OngoingSerie extends Component {
  constructor() {
    super();
    this.loadMore = this.loadMore.bind(this);
    this.onCancelCancelation = this.onCancelCancelation.bind(this);
    this.refresh = this.refresh.bind(this);

    this.state = {
      onConfirmCancelation: null,
      width: 0,
      displayCancelationConfirmDialog: false,
      startIndexListItemRendred: 0,
      stopIndexListItemRendred: 0,
      showDriverLocation: false,
      selectedSerieToShowDriverLocation: 0,
      searchInput: "",
      searchDates: [],
    };
  }

  refresh() {}

  onCancelCancelation() {
    this.setState(
      Object.assign({}, this.state, {
        displayCancelationConfirmDialog: false,
        onConfirmCancelation: null,
      })
    );
  }

  loadMore() {
    const { onGoingSeriesRequest } = this.props;
    const { page, limit, totalDocs, series } = this.props.series;
    if (series.length < totalDocs)
      onGoingSeriesRequest(page + 1, limit, {
        text: this.state.searchInput,
        dates: this.state.searchDates,
      });
  }

  isRowLoaded = ({ index }) => {
    const { series } = this.props.series;
    return !!series[index];
  };

  rowRenderer = ({ index, key, style }) => {
    const { t, i18n } = this.props;

    const { top, position, height, left } = style;
    const { series } = this.props.series;
    const r = series[index];

    if (!this.isRowLoaded({ index })) {
      return null;
      /*(<div key={key}
                style={{
                    top: top,
                    position, left,
                    height: height, width:
                        this.state.width - 25
                }} className="d-flex">
                chargement ....
            </div>); */
    }

    return (
      <div key={key} style={{ marginBottom: "8px" }} className="d-flex">
        <OngoingSerieRow
          serie={series[index]}
          {...{ t, i18n }}
          onCancelSerie={(serie) => {
            const callBack = () => {
              const { cancelSerieRequest } = this.props;

              cancelSerieRequest(serie._id, () => {
                this.setState(
                  Object.assign({}, this.state, {
                    displayCancelationConfirmDialog: false,
                    onConfirmCancelation: null,
                  })
                );
              });
            };

            this.setState(
              Object.assign({}, this.state, {
                displayCancelationConfirmDialog: true,
                onConfirmCancelation: callBack,
              })
            );
          }}
        />
      </div>
    );
  };

  onRowsRendered(params) {
    const { startIndex, stopIndex } = params;
    const { startIndexListItemRendred, stopIndexListItemRendred } = this.state;
    if (
      startIndex != startIndexListItemRendred ||
      stopIndex != stopIndexListItemRendred
    ) {
      this.setState(
        Object.assign({}, this.state, {
          startIndexListItemRendred: startIndex,
          stopIndexListItemRendred: stopIndex,
        }),
        () => {}
      );
    }
  }

  componentDidMount() {
    const { onGoingSeriesRequest, series } = this.props;

    onGoingSeriesRequest(1, 10);
  }

  componentDidUpdate(prevProps, prevState) {
    const { onGoingSeriesRequest } = this.props;
    const { searchDates, searchInput } = this.state;
    if (
      prevState.searchDates !== searchDates ||
      prevState.searchInput !== searchInput
    ) {
      onGoingSeriesRequest(1, 10, {
        text: searchInput,
        dates: searchDates.length !== 0 ? `"${searchDates.join('","')}"` : "",
      });
    }
  }

  componentWillUnmount() {}

  render() {
    const { t, i18n } = this.props;
    const { limit, totalDocs = 0, series } = this.props.series;

    return (
      <Container fluid className="h-100 d-flex flex-column">
        <Row className="mt-4 px-2">
          <Col>
            <h1>
              {t("onGoingSeriesPage.on_going_series_headline_title", {
                lng: i18n.language,
              })}
            </h1>
          </Col>
        </Row>

        <Row className="mt-4 px-2">
          <Col sm="4">
            <Form className="ongoing-ride-search w-40">
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="N° réservation, patient, date, destination, prestataire"
                  // ajouter la variable search
                  onChange={(e) => {
                    const { value } = e.target;
                    this.setState(
                      Object.assign({}, this.state, {
                        searchInput: value,
                      })
                    );
                  }}
                  value={this.state.searchInput}
                />
                <div className="search-icon">
                  <img src={searchInputIcon} />
                </div>
              </InputGroup>
            </Form>
          </Col>
          <Col className="sm-4"></Col>
          <Col className="sm-4 d-flex justify-content-end align-items-center">
            <Calendar
              className="container-input-calendar"
              multiple={true}
              value={this.state.searchDates}
              weekStartDayIndex={1}
              disableDayPicker={false}
              showOtherDays={false}
              calendarPosition="Left Top"
              render={<InputIcon />}
              locale={datepickerLocal}
              shadow={false}
              onChange={(selectedDates) => {
                this.setState({ searchDates: selectedDates });
              }}
            />
          </Col>
        </Row>

        <Row className="flex-grow-1 mt-5">
          <Col>
            {totalDocs == 0 && series.length == 0 && (
              <div className="no-data">
                <img src={noDataIcon} />
                <p className="text-no-data">
                  {t("onGoingSeriesPage.message_when_no_data", {
                    lng: i18n.language,
                  })}
                </p>
              </div>
            )}

            {totalDocs > 0 && (
              <InfiniteScroll
                dataLength={totalDocs} //This is important field to render the next data
                next={this.loadMore}
                // below props only if you need pull down functionality
                refreshFunction={this.refresh}
                hasMore={false}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    {/* <b>Vous avez tout vu</b> */}
                  </p>
                }
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={
                  <h3 style={{ textAlign: "center" }}>
                    &#8595; Dérouler pour rafraîchir
                  </h3>
                }
                releaseToRefreshContent={
                  <h3 style={{ textAlign: "center" }}>
                    &#8593; Relâcher pour rafraîchir
                  </h3>
                }
              >
                {series.map((serie, index) => (
                  <div
                    key={index}
                    className="d-flex"
                    style={{
                      marginBottom: "8px",
                    }}
                  >
                    <OngoingSerieRow
                      serie={serie}
                      {...{ t, i18n }}
                      onCancelSerie={(serie) => {
                        const callBack = () => {
                          const { cancelSerieRequest } = this.props;

                          cancelSerieRequest(serie._id, () => {
                            this.setState(
                              Object.assign({}, this.state, {
                                displayCancelationConfirmDialog: false,
                                onConfirmCancelation: null,
                              })
                            );
                          });
                        };

                        this.setState(
                          Object.assign({}, this.state, {
                            displayCancelationConfirmDialog: true,
                            onConfirmCancelation: callBack,
                          })
                        );
                      }}
                    />
                  </div>
                ))}
              </InfiniteScroll>
            )}
            {/* <InfiniteLoader
              isRowLoaded={({ index }) => !!series[index]}
              loadMoreRows={this.loadMore}
              rowCount={totalDocs}
              threshold={limit / 2}
            >
              {({ onRowsRendered, registerChild }) => (
                <AutoSizer>
                  {({ width, height }) => {
                    if (width != this.state.width)
                      this.setState(Object.assign({}, this.state, { width }));
                    return (
                      <List
                        height={totalDocs === 0 ? 0 : (height === 0 ? 500 : height)}
                        rowHeight={200}
                        width={this.state.width < 1000 ? 1000 : this.state.width }

                        ref={registerChild}
                        rowCount={totalDocs}
                        rowRenderer={this.rowRenderer}
                        onRowsRendered={(param) => {
                          onRowsRendered(param);
                          this.onRowsRendered(param);
                        }}
                        data={series}
                      />
                    );
                  }}
                </AutoSizer>
              )}
            </InfiniteLoader> */}
          </Col>
        </Row>

        <CancelConfirmationDialog
          show={this.state.displayCancelationConfirmDialog}
          {...{ t, i18n }}
          onConfirm={this.state.onConfirmCancelation}
          onCancel={this.onCancelCancelation}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    series: state.ongoingSeries.series.asMutable({ deep: true }),
    action: state.ongoingSeries.action.asMutable({ deep: true }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGoingSeriesRequest: (...args) =>
      dispatch(OngoingSeriesActions.onGoingSeriesRequest(...args)),
    cancelSerieRequest: (...args) =>
      dispatch(OngoingSeriesActions.cancelSerieRequest(...args)),
  };
};

const onGoingSeriesRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(OngoingSerie);
export default withTranslation(["Private", "Common"], { wait: true })(
  onGoingSeriesRedux
);
