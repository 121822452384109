import { Component } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faFileDownload,
  faGripLines,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import upload from "globals/upload";
import { downloadFilesAsBlob, formatSizeFile } from "functions";
import CloseIcon from "assets/img/close";

class ManageFilesModal extends Component {
  constructor(props) {
    super(props);

    this.BUCKET = "rides/files";

    this.onDeleteFile = this.onDeleteFile.bind(this);
    this.onToggleSelectFile = this.onToggleSelectFile.bind(this);
    this.onChangeInputFile = this.onChangeInputFile.bind(this);
    this.callbackUploadFile = this.callbackUploadFile.bind(this);
    this.callbackDropFile = this.callbackDropFile.bind(this);
    this.downloadFiles = this.downloadFiles.bind(this);

    this.state = {
      files: [],
      selectedFiles: [],
      deletedFiles: [],
      isDeleteModalOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { ride, show } = this.props;
    if (prevProps.ride !== ride) {
      this.setState({ files: ride?.files });
    }
    if (prevProps.show !== show && !show) {
      this.setState({ selectedFiles: [], files: [] });
    }
  }

  callbackUploadFile = async (error, filesInfo) => {
    if (error) {
      //handle error
      return;
    }
    if (!_.isEmpty(filesInfo)) {
      // generate path to get the presigned urls list from server
      const paths = [];
      for (const e of filesInfo) {
        paths.push(e.path);
      }
      // get presigned urls list and upload files to minio server
      this.props.putPresignedUrl(paths, (urls) => {
        for (let i = 0; i < urls.length; i++) {
          upload({
            url: urls[i],
            file: filesInfo[i].originalFile,
          });
        }
      });
    }
  };

  callbackDropFile = async (error) => {
    if (error) {
      //handle error
      return;
    }
    // hide modal and show the deleted files
    const { selectedFiles } = this.state;
    this.setState({
      isDeleteModalOpen: false,
      deletedFiles: selectedFiles,
      selectedFiles: [],
    });
  };

  onDeleteFile() {
    const { ride, onDropFiles } = this.props;
    // delete files on ride info (backend)
    if (_.isFunction(onDropFiles))
      onDropFiles(ride?._id, this.state.selectedFiles, this.callbackDropFile);
  }

  downloadFiles() {
    const { ride, getPresignedUrl } = this.props;
    const { selectedFiles, files } = this.state;
    getPresignedUrl(selectedFiles, async (urls) => {
      downloadFilesAsBlob(urls, files, ride?.patient);
    });
  }

  onChangeInputFile(e) {
    const { ride, onAddFiles, isNewRide = false } = this.props;
    const { files } = e.target;

    let filesInfo = [];
    for (const file of files) {
      const { name, size } = file;
      const nameSplited = name.split(".");
      const extension = nameSplited.pop();
      const path = isNewRide
        ? `${this.BUCKET}`
        : `${this.BUCKET}/${ride?._id}/${name}`;
      filesInfo.push({
        name,
        size,
        extension,
        path,
        originalFile: file, // to upload file as blob
      });
    }
    // pas besoin vu que le backend renvoie les infos des fichiers et que le state est mis à jour.
    this.setState({ files: [...this.state.files, ...filesInfo] });

    // upload files to backend
    if (!isNewRide) onAddFiles(ride?._id, filesInfo, this.callbackUploadFile);
  }

  onToggleSelectFile(path) {
    const { selectedFiles, deletedFiles } = this.state;
    // path not in deletedFiles
    if (deletedFiles.includes(path)) return;

    const index = selectedFiles.findIndex((p) => p === path);
    if (index === -1) {
      this.setState({ selectedFiles: [...selectedFiles, path] });
    } else {
      this.setState({ selectedFiles: selectedFiles.filter((p) => p !== path) });
    }
    this.setState({ isDeleteModalOpen: false });
  }

  render() {
    const { t, i18n, ride, onCancel } = this.props;
    const { selectedFiles } = this.state;

    return (
      <Modal
        {...this.props}
        contentClassName="w-100"
        onHide={onCancel}
        centered
      >
        {/* <Modal.Header closeButton={true}>
        </Modal.Header> */}

        <Modal.Body className="text-center p-2">
          <div className="d-flex justify-content-end">
            <OverlayTrigger
              placement="bottom"
              overlay={(propsOverlay) => (
                <Tooltip {...propsOverlay}>Fermer</Tooltip>
              )}
            >
              <div onClick={onCancel} style={{ cursor: "pointer" }}>
                <CloseIcon />
              </div>
            </OverlayTrigger>
          </div>
          <h2 className="pb-3">
            {/* {t("Common:Dialog.CancelRideConfirmation.header_label", {
              lng: i18n.language,
            })} */}
            Gérer les fichiers du transport
          </h2>
          {/* <div className="d-flex">
            <div>{selectedFiles.length} {`élément${selectedFiles.length > 1 ? "s" : ""} séléctionné${selectedFiles.length > 1 ? "s":""}`}</div>
          </div> */}
          <div className="d-flex justify-content-between">
            <div
              className="d-flex align-items-center"
              style={{ opacity: this.state.isDeleteModalOpen ? 100 : 0 }}
            >
              <div>Valider la suppression ?</div>
              <div
                style={{ marginLeft: 5, color: "red", cursor: "pointer" }}
                onClick={this.onDeleteFile}
              >
                Oui
              </div>
            </div>
            <div className="d-flex gap-2">
              <OverlayTrigger
                placement="bottom"
                overlay={(propsOverlay) => (
                  <Tooltip {...propsOverlay}>
                    Supprimer les fichiers sélectionnés
                  </Tooltip>
                )}
              >
                <button
                  type="button"
                  className={`btn btn-danger ${
                    selectedFiles.length > 0 ? "" : "disabled"
                  }`}
                  onClick={() => this.setState({ isDeleteModalOpen: true })}
                >
                  <FontAwesomeIcon icon={faTrash} color={"white"} />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={(propsOverlay) => (
                  <Tooltip {...propsOverlay}>
                    Télécharger les fichiers sélectionnés
                  </Tooltip>
                )}
              >
                <button
                  type="button"
                  className={`btn btn-success ${
                    selectedFiles.length > 0 ? "" : "disabled"
                  }`}
                  onClick={this.downloadFiles}
                >
                  <FontAwesomeIcon icon={faFileDownload} color={"white"} />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={(propsOverlay) => (
                  <Tooltip {...propsOverlay}>Ajouter des fichiers</Tooltip>
                )}
              >
                <label className="custom-file-upload">
                  <input
                    type="file"
                    multiple
                    onChange={this.onChangeInputFile}
                  />
                  <div className="btn btn-primary">
                    <FontAwesomeIcon icon={faPlus} color={"white"} />
                  </div>
                </label>
              </OverlayTrigger>
            </div>
          </div>
          <div
            className="table-responsive"
            style={{ overflowY: "auto", maxHeight: "500px", marginTop: 5 }}
          >
            <table className="table table-hover table-striped">
              <thead>
                <tr>
                  <th scope="col col-span-1">#</th>
                  <th scope="col col-span-7" className="text-start">
                    Nom
                  </th>
                  <th scope="col col-span-3" className="text-end">
                    Taille
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.files.map((file) => {
                  const isDeletedFile = this.state.deletedFiles.includes(
                    file.path
                  );
                  return (
                    <tr
                      key={file.path}
                      onClick={() => this.onToggleSelectFile(file.path)}
                    >
                      <td>
                        {isDeletedFile ? (
                          <div style={{ fontSize: 12, color: "#dc3545" }}>
                            -
                          </div>
                        ) : (
                          <FontAwesomeIcon
                            icon={faCheck}
                            color={
                              selectedFiles.includes(file.path)
                                ? "#0096ac"
                                : "#C5C5C5"
                            }
                          />
                        )}
                      </td>
                      <th
                        className={`text-start text-${
                          isDeletedFile
                            ? "danger text-decoration-line-through"
                            : "dark"
                        }`}
                        scope="row"
                      >
                        {file.name}
                      </th>
                      <td
                        className={`text-end text-${
                          isDeletedFile
                            ? "danger text-decoration-line-through"
                            : "dark"
                        }`}
                      >
                        {formatSizeFile(file.size)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        {/* <Modal.Footer className="justify-content-around">
          <p>ride summary</p>
        </Modal.Footer> */}
      </Modal>
    );
  }
}

export default ManageFilesModal;
