import { put, takeLatest } from "redux-saga/effects";
import ServiceActions, { getServiceTypes } from "reducers/company/get-service";
import errorHandler from "sagas/error-handler";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* getServiceAsyncRequest() {
  const query = `
    query {
      ServiceCompanyDetails {
        name
        address{ latitude longitude address }
        contact{ firstname lastname email phones }
        doctors { id firstname lastname }
        secretaries { id firstname lastname email }
      }
    }
  `;

  try {
    const { ServiceCompanyDetails } = yield graphQLClient().request(query);
    yield put(
      ServiceActions.getServiceCompanyRequestSuccess(ServiceCompanyDetails)
    );
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, ServiceActions.getServiceCompanyRequestFailure);
  }
}

function* doctorAddSecretaryAsyncRequest({ email, callback }) {
  const query = `
    mutation {
      ServiceDoctorAddSecretary(email: "${email}") {
        id
        firstname
        lastname
        email
      }
    }
  `;
  try {
    const { ServiceDoctorAddSecretary } = yield graphQLClient().request(query);
    yield put(
      ServiceActions.doctorAddSecretarySuccess(ServiceDoctorAddSecretary)
    );
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, ServiceActions.doctorAddSecretaryFailure);
    if (_.isFunction(callback)) callback(message);
  }
}

function* doctorDeleteSecretaryAsyncRequest({ secretaryId, callback }) {
  const query = `
    mutation {
      ServiceDoctorRemoveSecretary(secretaryId: "${secretaryId}")
    }
  `;
  try {
    yield graphQLClient().request(query);
    yield put(ServiceActions.doctorDeleteSecretarySuccess(secretaryId));
    if (_.isFunction(callback)) callback();
  } catch (error) {
    const { message = "" } = error.response.errors[0];
    yield errorHandler(message, ServiceActions.doctorDeleteSecretaryFailure);
    if (_.isFunction(callback)) callback(message);
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(
    getServiceTypes.GET_SERVICE_COMPANY_REQUEST,
    getServiceAsyncRequest
  ),
  takeLatest(
    getServiceTypes.DOCTOR_ADD_SECRETARY_REQUEST,
    doctorAddSecretaryAsyncRequest
  ),
  takeLatest(
    getServiceTypes.DOCTOR_DELETE_SECRETARY_REQUEST,
    doctorDeleteSecretaryAsyncRequest
  ),
];
