import { Button } from "react-bootstrap";

export default function MyButton(props) {
  const style = {
    fontSize: "14px",
    fontWeight: 700,
    border: 0,
    color: props.isActive ? "white" : props.isVehicle ? "#132B44" : "#999C9F",
    backgroundColor: props.isActive ? "#132B44" : "transparent",
  };
  if (props.isVehicle) {
    style.border = "1px !important";
    style.borderColor = props.isVehicle
      ? "#132B44 !important"
      : "#999C9F !important";
  }
  return (
    <Button className="flex-grow-1" style={style} {...props}>
      {props.children}
    </Button>
  );
}
