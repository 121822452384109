import { Component } from "react";
import PatientStepButtonButton from "components/Patient-Steps-Button";
import { Button } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import clockIcon from "assets/img/clock.svg";
import trashIcon from "assets/img/trash.svg";
import timerIcon from "assets/img/timer.svg";
import refreshIcon from "assets/img/refresh.svg";
import manageFilesIcon from "assets/img/manage-files.svg";
import { getBackendUrl } from "functions";
import NotSpecified from "./NotSpecified";

function formatTimer(duration) {
  if (duration === null) return "00:00";
  let minutes = Math.floor(duration / 60);
  let seconds = duration - minutes * 60;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  return `${minutes}:${seconds}`;
}

class OngoingRideRow extends Component {
  constructor(props) {
    super(props);

    this.onPatientStepsChanged = this.onPatientStepsChanged.bind(this);
    this.onCancelRideAction = this.onCancelRideAction.bind(this);
    this.onRefreshDispatchRide = this.onRefreshDispatchRide.bind(this);
    this.onShowManageFiles = this.onShowManageFiles.bind(this);
    this.getTimer = this.getTimer.bind(this);

    this.state = {
      showMore: false,
      timerDispatch: null,
    };
  }

  componentDidMount() {
    this.timer = null;
    if (this.props.ride.status === "incoming") {
      this.timer = setInterval(this.getTimer, 1000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.ride.status === "incoming" &&
      prevProps.ride.nextDispatchingAt !== this.props.ride.nextDispatchingAt
    ) {
      this.timer = setInterval(this.getTimer, 1000);
    }
  }
  getTimer() {
    const { nextDispatchingAt } = this.props.ride;
    const time = moment(nextDispatchingAt).diff(moment(new Date()), "seconds");
    if (time < 0) {
      clearInterval(this.timer);
    } else {
      this.setState({ timerDispatch: time });
    }
  }

  onPatientStepsChanged({ key, value }) {
    const { setPatientStatusRequest, ride } = this.props;
    setPatientStatusRequest(ride._id, key, () => {
      //console.log("ok")
    });
  }

  onShowManageFiles() {
    const { onShowManageFiles, ride } = this.props;
    if (_.isFunction(onShowManageFiles)) onShowManageFiles(ride);
  }

  onCancelRideAction() {
    const { ride, onCancelRide } = this.props;

    if (_.isFunction(onCancelRide)) onCancelRide(ride);
  }

  onRefreshDispatchRide() {
    const { ride, onRefreshDispatchRide } = this.props;
    onRefreshDispatchRide(ride._id, () => null);
  }

  render() {
    //const status = ["Administrée", "Véhicule en chemin", "Véhicule en attente", "Patient à bord du véhicule", "Attribution en cours"]

    const { t, i18n, onShowMap, ride } = this.props;

    const {
      _id,
      rideNumber = "",
      taxi,
      transporter,
      rideType,
      patient = {},
      status = "",
      datetime = "",
      dropoff = {},
      pickup = {},
      subject = "",
      waitingTime = {},
      createdAt,
      nextDispatchingAt,
    } = this.props.ride || {};
    const pathToDownloadBilan =
      rideType == "samuTransporterRide"
        ? `${getBackendUrl()}/downloads/bilan/${_id}`
        : null;

    let transporterInfo = "";
    let transporterPhone = "";
    let make = "";
    let model = "";
    let plate = "";
    let color = "";
    let phone = "";
    let currentStatus = status;

    let timerDispatch = null;
    if (status === "incoming") {
      timerDispatch = moment(nextDispatchingAt).diff(
        moment(new Date()),
        "seconds"
      );
    }

    if (status != "incoming") {
      let obj = null;
      if (rideType === "taxiRide") {
        transporterInfo = `${_.get(taxi, "driver.lastname", "")} ${_.get(
          taxi,
          "driver.firstname",
          ""
        )}`;
        // transporterPhone = _.get(taxi, 'driver.phone', '')
        obj = taxi;
      } else {
        obj = transporter;
        transporterInfo = _.get(transporter, "company.name", "");
        transporterPhone = _.get(transporter, "company.phones", "");
        if (_.isArray(transporterPhone) && transporterPhone.length > 0)
          transporterPhone = transporterPhone[0];
      }

      make = _.get(obj, "vehicle.make", "");
      model = _.get(obj, "vehicle.model", "");
      plate = _.get(obj, "vehicle.plate", "");
      color = _.get(obj, "vehicle.color", "");
      phone = _.get(obj, "vehicle.phone", "");
      if (status == "pending" && _.isEmpty(make)) {
        currentStatus = "accepted";
      }
    }
    transporterInfo = _.capitalize(transporterInfo);

    const PATIENT_STATUS = [
      {
        key: "waiting",
        value: t(`Common:Patient.Status.waiting`, { lng: i18n.language }),
      },
      {
        key: "admission",
        value: t(`Common:Patient.Status.admission`, { lng: i18n.language }),
      },
      {
        key: "ready",
        value: t(`Common:Patient.Status.ready`, { lng: i18n.language }),
      },
    ];

    let patientLname = patient.lastname || "";
    let patientFname = patient.firstname || "";

    patientLname = _.capitalize(patientLname);
    patientFname = _.capitalize(patientFname);

    return (
      <>
        <div
          className="d-flex d-none d-lg-flex"
          style={{
            border: "1px solid #999C9F",
            borderRadius: 4,
            padding: "5px 30px",
            // width: "986px",
            // height: "120px",
          }}
        >
          {/* COL 1 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100px",
              marginRight: "40px",
            }}
          >
            <div style={{ fontSize: 16, fontWeight: 900 }}>{rideNumber}</div>
            <div style={{ fontSize: 12, fontWeight: 500 }}>
              {moment(datetime).format("DD MMM HH:mm")}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "74px",
              }}
            >
              <img src={clockIcon} style={{ marginRight: 10 }} />
              <div style={{ fontSize: 16, fontWeight: 900 }}>
                {["onway", "onboard"].includes(ride.status) &&
                !_.isEmpty(waitingTime) &&
                _.isInteger(waitingTime.duration_in_traffic)
                  ? moment
                      .utc(waitingTime.duration_in_traffic * 1000)
                      .format("HH:mm")
                  : "--:--"}
              </div>
            </div>
          </div>

          {/* COL 2 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "140px",
              borderRight: "1px solid #999C9F",
              marginRight: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ fontSize: "12px", fontWeight: 500, color: "#999C9F" }}
              >
                Prise en charge
              </div>
              {pickup.address ? (
                <span
                  style={{ fontSize: "12px", fontWeight: 500 }}
                  className="text-truncate-2"
                >
                  {pickup.address || ""}
                </span>
              ) : (
                <NotSpecified />
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between" /* marginTop: "4px" */,
              }}
            >
              <div
                style={{ fontSize: "12px", fontWeight: 500, color: "#999C9F" }}
              >
                Dépose
              </div>
              {dropoff.address ? (
                <span
                  style={{ fontSize: "12px", fontWeight: 500 }}
                  className="text-truncate-2"
                >
                  {dropoff.address || ""}
                </span>
              ) : (
                <NotSpecified />
              )}
            </div>
          </div>

          {/* COL 3 */}
          <div
            style={{ display: "flex", flexDirection: "column", width: "132px" }}
          >
            <div
              style={{ fontSize: "16px", fontWeight: 900 }}
            >{`${patientFname} ${patientLname}`}</div>
            <div
              style={{ fontSize: "12px", fontWeight: 500, color: "#999C9F" }}
            >
              Motif
            </div>
            {subject ? (
              <div style={{ fontSize: "12px", fontWeight: 900 }}>{subject}</div>
            ) : (
              <NotSpecified />
            )}
            <div
              style={{ fontSize: "12px", fontWeight: 500, color: "#999C9F" }}
            >
              Numéro
            </div>
            {patient?.phone ? (
              <div style={{ fontSize: "12px", fontWeight: 900 }}>
                {patient?.phone}
              </div>
            ) : (
              <NotSpecified />
            )}
          </div>

          {/* COL 4 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              width: "132px",
              borderRight: "1px solid #999C9F",
              marginRight: "8px",
            }}
          >
            <div
              style={{ fontSize: "12px", fontWeight: 500, color: "#999C9F" }}
            >
              Status du patient
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {/* <div>1</div>
              <div>2</div>
              <div>3 </div> */}
              <PatientStepButtonButton
                value={patient.status}
                steps={PATIENT_STATUS}
                valueDidChnged={this.onPatientStepsChanged}
              />
            </div>
          </div>

          {/* COL5 */}
          <div
            style={{
              width: "132px",
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              marginRight: "40px",
            }}
          >
            <div
              style={{
                color: "#999C9F",
                fontSize: "12px",
                fontWeight: 500,
                marginBottom: "2px",
              }}
            >
              Véhicule
            </div>
            {["incoming", "unavailable"].includes(status) && <NotSpecified />}
            {status == "pending" && _.isEmpty(make) && (
              <>
                <div style={{ fontSize: "12px", fontWeight: 900 }}>
                  {transporterInfo}
                </div>
                <div style={{ fontSize: "12px", fontWeight: 700 }}>
                  {transporterPhone}
                </div>
              </>
            )}
            {["onway", "waiting", "onboard", "arrived", "pending"].includes(
              currentStatus
            ) && (
              <>
                <div style={{ fontSize: "12px", fontWeight: 900 }}>
                  {transporterInfo}
                </div>
                <div style={{ fontSize: "12px", fontWeight: 700 }}>
                  {transporterPhone}
                </div>
                <div style={{ fontSize: "12px", fontWeight: 700 }}>{plate}</div>
                <div style={{ fontSize: "12px", fontWeight: 500 }}>{phone}</div>
              </>
            )}
          </div>

          {/* COL6 */}
          <div style={{ display: "flex", width: "181px", marginTop: "8px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "start",
              }}
            >
              <span className={`status ${currentStatus} text-center`}>
                <div className={`status-point status-${currentStatus}`} />
                {t(`Common:Ride.Status.${currentStatus}`, {
                  lng: i18n.language,
                })}
              </span>
              <div className=" waiting-time" style={{ marginTop: "5px" }}>
                {
                  <Button
                    disabled={[
                      "incoming",
                      "pending",
                      "completed",
                      "canceled",
                      "unavailable",
                    ].includes(status)}
                    // size=""
                    variant="outline-primary"
                    onClick={() => {
                      if (_.isFunction(onShowMap)) onShowMap(ride);
                    }}
                  >
                    {t("onGoingRidesPage.localisation_button", {
                      lng: i18n.language,
                    }).toUpperCase()}
                  </Button>
                }
              </div>
              {process.env.NODE_ENV !== "production" && status !== "completed" && (
                <div
                  style={{
                    color: "red",
                    cursor: "pointer",
                    border: "1px solid red",
                    borderRadius: "4px",
                    padding: "1px 5px",
                    marginTop: "2px",
                  }}
                  onClick={() => this.props.changeRideStatusRequest(_id)}
                >
                  Simulation
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div>
                  {currentStatus === "incoming" && (
                    <div className={"d-flex"}>
                      <img
                        src={timerIcon}
                        alt="timer dispatch ride"
                        style={{ width: "15px" }}
                      />
                      <div>{formatTimer(this.state.timerDispatch)}</div>
                    </div>
                  )}
                </div>

                <div style={{ display: "flex", gap: "5px" }}>
                  {currentStatus === "unavailable" && (
                    <img
                      src={refreshIcon}
                      alt="refresh ride"
                      style={{ width: "34px", cursor: "pointer" }}
                      onClick={this.onRefreshDispatchRide}
                      zIndex={2}
                    />
                  )}
                  <img
                    src={manageFilesIcon}
                    alt="manage files"
                    style={{ width: "32px", cursor: "pointer" }}
                    onClick={this.onShowManageFiles}
                  />
                  <img
                    src={trashIcon}
                    alt="remove ride"
                    style={{ width: "32px", cursor: "pointer" }}
                    onClick={this.onCancelRideAction}
                  />
                </div>
              </div>
            </div>
            {/* <a href={pathToDownloadBilan} target="_blank" style={{
              display: "flex", gap: 5, alignItems: "center",
              padding: "0 10px", opacity: pathToDownloadBilan ? 1 : 0.5,
              textDecoration: "none", color: "#0296AE",
              border: "1px solid #0296AE", borderRadius: "100px",
              fontSize: "12px", fontWeight: 700
              }}>
              <div style={{ padding: "4px 0" }}>
                <img src={downloadIcon} style={{ width: 15 }} />
              </div>
              <div style={{ padding: "3px 0" }}>
                FICHE BILAN
              </div>
            </a> */}
          </div>
        </div>

        {/* SMARTPHONE */}
        <div
          className="d-flex d-lg-none w-100"
          style={{
            flexDirection: "column",
            border: "1px solid #999C9F",
            borderRadius: 4,
            padding: "5px 30px",
          }}
          onClick={() => this.setState({ showMore: !this.state.showMore })}
        >
          <div className="d-flex justify-content-between">
            {/* COL 1 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginRight: "5px",
              }}
            >
              <div style={{ fontSize: 16, fontWeight: 900 }}>{rideNumber}</div>
              <div style={{ fontSize: 12, fontWeight: 500 }}>
                {moment(datetime).format("DD MMM HH:mm")}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // width: '74px'
                }}
              >
                <img src={clockIcon} style={{ marginRight: 10 }} />
                <div style={{ fontSize: 16, fontWeight: 900, width: "40px" }}>
                  {["onway", "onboard"].includes(ride.status) &&
                  !_.isEmpty(waitingTime) &&
                  _.isInteger(waitingTime.duration_in_traffic)
                    ? moment
                        .utc(waitingTime.duration_in_traffic * 1000)
                        .format("HH:mm")
                    : "--:--"}
                </div>
              </div>
            </div>

            {/* PATIENT */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "132px",
              }}
            >
              <div
                style={{ fontSize: "14px", fontWeight: 900 }}
              >{`${patientFname} ${patientLname}`}</div>
              <div
                style={{ fontSize: "11px", fontWeight: 500, color: "#999C9F" }}
              >
                Motif
              </div>
              {subject ? (
                <div style={{ fontSize: "12px", fontWeight: 900 }}>
                  {subject}
                </div>
              ) : (
                <NotSpecified />
              )}
              <div
                style={{ fontSize: "11px", fontWeight: 500, color: "#999C9F" }}
              >
                Numéro
              </div>
              {patient?.phone ? (
                <div style={{ fontSize: "12px", fontWeight: 900 }}>
                  {patient?.phone}
                </div>
              ) : (
                <NotSpecified />
              )}
            </div>

            {/* STATUS */}
            <div style={{ display: "flex", marginTop: "8px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "start",
                }}
              >
                <span className={`status ${currentStatus} text-center small`}>
                  <div
                    className={`status-point status-${currentStatus} small`}
                  />
                  {t(`Common:Ride.Status.${currentStatus}`, {
                    lng: i18n.language,
                  })}
                </span>
                {process.env.NODE_ENV !== "production" &&
                  status !== "completed" && (
                    <div
                      style={{
                        color: "red",
                        cursor: "pointer",
                        border: "1px solid red",
                        borderRadius: "4px",
                        padding: "1px 5px",
                        marginTop: "2px",
                      }}
                      onClick={() => this.props.changeRideStatusRequest(_id)}
                    >
                      Simulation
                    </div>
                  )}
                <div className=" waiting-time" style={{ marginTop: "5px" }}>
                  <Button
                    disabled={[
                      "incoming",
                      "pending",
                      "completed",
                      "canceled",
                      "unavailable",
                    ].includes(status)}
                    // size=""
                    variant="outline-primary"
                    onClick={() => {
                      if (_.isFunction(onShowMap)) onShowMap(ride);
                    }}
                  >
                    {t("onGoingRidesPage.localisation_button", {
                      lng: i18n.language,
                    }).toUpperCase()}
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div>
                    {currentStatus === "incoming" && (
                      <div className={"d-flex"}>
                        <img
                          src={timerIcon}
                          alt="timer dispatch ride"
                          style={{ width: "15px" }}
                        />
                        <div>{formatTimer(this.state.timerDispatch)}</div>
                      </div>
                    )}
                  </div>
                  <div style={{ display: "flex", gap: "5px" }}>
                    {currentStatus === "unavailable" && (
                      <img
                        src={refreshIcon}
                        alt="refresh ride"
                        style={{ width: "34px", cursor: "pointer" }}
                        onClick={this.onRefreshDispatchRide}
                      />
                    )}
                    <img
                      src={manageFilesIcon}
                      alt="manage files"
                      style={{ width: "32px", cursor: "pointer" }}
                      onClick={this.onShowManageFiles}
                    />
                    <img
                      src={trashIcon}
                      alt="remove ride"
                      style={{ width: "32px", cursor: "pointer" }}
                      onClick={this.onCancelRideAction}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* SHOW MORE */}
          {this.state.showMore && (
            <div>
              <div className="mt-2 d-flex">
                {/* ADDRESS */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "140px",
                    borderRight: "1px solid #999C9F",
                    marginRight: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#999C9F",
                      }}
                    >
                      Prise en charge
                    </div>
                    {pickup.address ? (
                      <span
                        style={{ fontSize: "12px", fontWeight: 500 }}
                        className="text-truncate-2"
                      >
                        {pickup.address || ""}
                      </span>
                    ) : (
                      <NotSpecified />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between" /* marginTop: "4px" */,
                    }}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#999C9F",
                      }}
                    >
                      Dépose
                    </div>
                    {dropoff.address ? (
                      <span
                        style={{ fontSize: "12px", fontWeight: 500 }}
                        className="text-truncate-2"
                      >
                        {dropoff.address || ""}
                      </span>
                    ) : (
                      <NotSpecified />
                    )}
                  </div>
                </div>

                {/* STATUS PATIENT */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    marginRight: "8px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "#999C9F",
                    }}
                  >
                    Status du patient
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <div>1</div>
                    <div>2</div>
                    <div>3 </div> */}
                    <PatientStepButtonButton
                      value={patient.status}
                      steps={PATIENT_STATUS}
                      valueDidChnged={this.onPatientStepsChanged}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-2 d-flex">
                {/* TRANSPORTER + VOITURE */}
                <div
                  style={{
                    width: "132px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                    marginRight: "40px",
                  }}
                >
                  <div
                    style={{
                      color: "#999C9F",
                      fontSize: "12px",
                      fontWeight: 500,
                      marginBottom: "2px",
                    }}
                  >
                    Véhicule
                  </div>
                  {["incoming", "unavailable"].includes(status) && (
                    <NotSpecified />
                  )}
                  {status == "pending" && _.isEmpty(make) && (
                    <div style={{ fontSize: "12px", fontWeight: 900 }}>
                      {transporterInfo}
                    </div>
                  )}
                  {[
                    "onway",
                    "waiting",
                    "onboard",
                    "accepted",
                    "pending",
                  ].includes(currentStatus) && (
                    <>
                      <div style={{ fontSize: "12px", fontWeight: 900 }}>
                        {transporterInfo}
                      </div>
                      <div style={{ fontSize: "12px", fontWeight: 700 }}>
                        {plate}
                      </div>
                      <div style={{ fontSize: "12px", fontWeight: 500 }}>
                        {phone}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default OngoingRideRow;
